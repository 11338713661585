import { CUSTOM_ELEMENTS_SCHEMA, NgModule, PLATFORM_ID } from '@angular/core';
import {
    BrowserModule,
    HammerGestureConfig,
    HAMMER_GESTURE_CONFIG
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    ExtraOptions,
    PreloadAllModules,
    RouterModule,
    RouteReuseStrategy
} from '@angular/router';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { FuseModule } from '@fuse/fuse.module';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { appConfig } from './core/config/app.config';
import { mockApiServices } from './mock-api';
import { LayoutModule } from './layout/layout.module';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routing';
import { environment } from 'environments/environment';
import { JMCoreModule } from '@jobzmall/core/core.module';
import { SegmentModule, SEGMENT_CONFIG } from 'ngx-segment-analytics';
import { isPlatformBrowser } from '@angular/common';
import {
    SocialLoginModule,
    SocialAuthServiceConfig,
    GoogleLoginProvider,
    FacebookLoginProvider
} from '@abacritt/angularx-social-login';
import { FilePondModule, registerPlugin } from 'ngx-filepond';

import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageEditor from '@pqina/filepond-plugin-image-editor';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import {
    setPlugins,
    plugin_crop,
    plugin_finetune,
    plugin_filter
} from '@pqina/pintura';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

import { CustomRouteReuseStrategy } from './core/reuse-strategy';
import { JMAuthComponentsModule } from '@jobzmall/auth/auth.module';
import { SharedAuthComponentsModule } from 'app/shared/auth/components/shared-auth-components.module';
import { MAT_DAYJS_DATE_ADAPTER_OPTIONS } from '@tabuckner/material-dayjs-adapter';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedComponentsModule } from './shared/components/shared-components.module';
import { HammerModule } from '@angular/platform-browser';
import { HammerConfig } from './hammer.config';
import { markedOptionsFactory } from '@jobzmall/markdown/markedOptionsFactory';

const isIframe = window !== window.parent && !window.opener;

const routerConfig: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    preloadingStrategy: PreloadAllModules,
    initialNavigation: isIframe ? 'disabled' : 'enabledBlocking'
};

export function segmentConfig(platformId: Object) {
    return {
        apiKey: environment.segmentWriteKey,
        debug: environment.segmentDebug,
        loadOnInitialization: isPlatformBrowser(platformId)
    };
}

registerPlugin(
    FilePondPluginFileValidateType,
    FilePondPluginFileValidateSize,
    FilePondPluginFileEncode,
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginImageCrop,
    FilePondPluginImageResize,
    FilePondPluginImageTransform,

    // Pintura
    FilePondPluginImageEditor,
    FilePondPluginFilePoster
);

setPlugins(plugin_crop, plugin_finetune, plugin_filter);

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        HammerModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        JMAuthComponentsModule,
        SharedAuthComponentsModule,
        SharedComponentsModule,
        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // JobzMall Core Module
        JMCoreModule.forRoot(),

        // Layout module of your application
        LayoutModule,

        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({
            markedOptions: {
                provide: MarkedOptions,
                useFactory: markedOptionsFactory
            }
        }),
        SocialLoginModule,
        FilePondModule,
        SegmentModule.forRoot(),
        BreadcrumbModule,
        ScrollToModule.forRoot()
        // ServiceWorkerModule.register('ngsw-worker.js', {
        //     enabled: environment.env !== 'local',
        //     // Register the ServiceWorker as soon as the application is stable
        //     // or after 30 seconds (whichever comes first).
        //     registrationStrategy: 'registerWhenStable:30000'
        // })
    ],
    providers: [
        { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
        { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(
                            environment.googleAppID,
                            {
                                oneTapEnabled: false,
                                scopes: 'email profile openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email'
                            }
                        )
                    },
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider(
                            environment.facebookAppID,
                            {
                                fields: 'id,email,first_name,last_name,link,name,picture.type(large)',
                                version: 'v3.3'
                            }
                        )
                    }
                ]
            } as SocialAuthServiceConfig
        },
        {
            provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS,
            useValue: { useUtc: false }
        },
        {
            provide: SEGMENT_CONFIG,
            useFactory: segmentConfig,
            deps: [PLATFORM_ID]
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
