import { inject } from '@angular/core';
import { UserState } from '@jobzmall/user/ngxs/state';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { coachServiceIconMap } from '@jobzmall/models';

// notification mapping

export const notificationText = {
    SystemNotification: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return notification.body;
    },
    MeetingScheduled: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return notification.is_host
            ? transloco.translate(
                  'PRIVATE.NOTIFICATIONS.MEETING.SCHEDULED.MESSAGE.HOST',
                  {
                      title: notification.meeting.title
                  }
              )
            : transloco.translate(
                  'PRIVATE.NOTIFICATIONS.MEETING.SCHEDULED.MESSAGE',
                  {
                      name: notification.meeting.host.name
                  }
              );
    },
    MeetingCancelled: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return notification.is_host
            ? transloco.translate(
                  'PRIVATE.NOTIFICATIONS.MEETING.CANCELLED.MESSAGE.HOST',
                  {
                      title: notification.meeting.title
                  }
              )
            : transloco.translate(
                  'PRIVATE.NOTIFICATIONS.MEETING.CANCELLED.MESSAGE',
                  {
                      name: notification.meeting.host.name
                  }
              );
    },
    CoachVerified: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.COACH_VERIFIED.MESSAGE'
        );
    },
    ReportAssigned: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.REPORT_ASSIGNED.MESSAGE'
        );
    },
    CoachOrderRefunded: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.COACH_ORDER_REFUNDED.MESSAGE',
            {
                coachName: notification.order.genius
                    ? 'JobzMall'
                    : notification.coach?.name,
                serviceName: notification.service.name
            }
        );
    },
    CoachOrderReceived: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.COACH_ORDER_RECEIVED.MESSAGE',
            {
                coachName: notification.order.genius
                    ? 'JobzMall'
                    : notification.coach?.name,
                serviceName: notification.service.name
            }
        );
    },
    CoachOrderInProgress: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.COACH_ORDER_IN_PROGRESS.MESSAGE',
            {
                coachName: notification.order.genius
                    ? 'JobzMall'
                    : notification.coach?.name,
                serviceName: notification.service.name
            }
        );
    },
    CoachOrderComplete: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.COACH_ORDER_COMPLETE.MESSAGE',
            {
                coachName: notification.order.genius
                    ? 'JobzMall'
                    : notification.coach?.name,
                serviceName: notification.service.name
            }
        );
    },
    CoachOrderDeclined: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.COACH_ORDER_DECLINED.MESSAGE',
            {
                coachName: notification.order.genius
                    ? 'JobzMall'
                    : notification.coach?.name,
                serviceName: notification.service.name
            }
        );
    },
    NewCoachOrder: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.NEW_COACH_ORDER.MESSAGE',
            {
                userName: notification.user.name,
                serviceName: notification.service.name
            }
        );
    },
    NewConnectionRequest: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.NEW_CONNECTION_REQUEST.MESSAGE',
            { userName: notification.user.name }
        );
    },
    ConnectionRequestAccepted: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.CONNECTION_REQUEST_ACCEPTED.MESSAGE',
            { userName: notification.user.name }
        );
    },
    ReferenceInvite: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.REFERENCE_INVITE.MESSAGE',
            { userName: notification.user.name }
        );
    },
    GeniusOrderReceived: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.GENIUS_ORDER_RECEIVED.MESSAGE',
            { productName: notification.order.product.name }
        );
    },
    ZVSubmissionSuccessful: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            notification.zv.premium
                ? 'PRIVATE.NOTIFICATIONS.ZV_SUBMISSION_SUCCESSFUL.PREMIUM.MESSAGE'
                : 'PRIVATE.NOTIFICATIONS.ZV_SUBMISSION_SUCCESSFUL.MESSAGE'
        );
    },
    ZVReady: (notification: any, transloco: TranslocoService, store: Store) => {
        return transloco.translate('PRIVATE.NOTIFICATIONS.ZV_READY.MESSAGE');
    },
    StarRequestApproved: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.STAR_REQUEST_APPROVED.MESSAGE'
        );
    },
    StarRequestReceived: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.STAR_REQUEST_RECEIVED.MESSAGE'
        );
    },
    ConversationUnreadCount: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        let count = notification.count;
        let userName = notification.conversation.participants.filter(
            (p: any) => p.user_id !== store.selectSnapshot(UserState.user)?.id
        )[0]?.user.name;
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.CONVERSATION_UNREAD_COUNT.MESSAGE',
            {
                count,
                userName
            }
        );
    },
    PostCommentAdded: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.POST_COMMENT_ADDED.MESSAGE',
            { commentedName: notification.comment.commented.name }
        );
    },
    PostReactionAdded: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.POST_REACTION_ADDED.MESSAGE',
            {
                userName: notification.user.name,
                storeName: notification.store?.name,
                reactionType: notification.reactionType
            }
        );
    },
    VideoAnswerCommentAdded: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.VIDEO_ANSWER_COMMENT_ADDED.MESSAGE',
            { commentedName: notification.comment.commented.name }
        );
    },
    VideoAnswerReactionAdded: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.VIDEO_ANSWER_REACTION_ADDED.MESSAGE',
            { userName: notification.user.name }
        );
    },
    CommentReplyAdded: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.COMMENT_REPLY_ADDED.MESSAGE',
            { commentedName: notification.comment.commented.name }
        );
    },
    NewApplicant: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        const params = notification.job
            ? {
                  jobTitle: notification.job.title,
                  storeName: notification.store.name
              }
            : { storeName: notification.store.name };
        return transloco.translate(
            notification.job
                ? 'PRIVATE.NOTIFICATIONS.NEW_APPLICANT.JOB.MESSAGE'
                : 'PRIVATE.NOTIFICATIONS.NEW_APPLICANT.DIRECT.MESSAGE',
            params
        );
    },
    SubscriptionCanceled: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.SUBSCRIPTION_CANCELED.MESSAGE',
            {
                cardLastFour: notification.store.card_last_four,
                planName: notification.subscription?.plan.name,
                storeName: notification.store.name
            }
        );
    },
    SubscriptionChargeFail: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.SUBSCRIPTION_CHARGE_FAIL.MESSAGE',
            {
                cardLastFour: notification.store.card_last_four,
                planName: notification.subscription?.plan.name,
                storeName: notification.store.name
            }
        );
    },
    SubscriptionChargeSuccess: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.SUBSCRIPTION_CHARGE_SUCCESS.MESSAGE',
            {
                cardLastFour: notification.store.card_last_four,
                planName: notification.subscription?.plan.name,
                storeName: notification.store.name
            }
        );
    },
    SubscriptionExpired: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.SUBSCRIPTION_EXPIRED.MESSAGE',
            {
                cardLastFour: notification.store.card_last_four,
                planName: notification.subscription?.plan.name,
                storeName: notification.store.name
            }
        );
    },
    StoreCreated: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.STORE_CREATED.MESSAGE',
            { storeName: notification.store.name }
        );
    },
    ApplicationArchived: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        const x = notification.application || notification;
        const params = x.job
            ? { jobTitle: x.job.title, storeName: x.store.name }
            : { storeName: x.store.name };

        return transloco.translate(
            x.job
                ? 'PRIVATE.NOTIFICATIONS.APPLICATION_ARCHIVED.JOB.MESSAGE'
                : 'PRIVATE.NOTIFICATIONS.APPLICATION_ARCHIVED.DIRECT.MESSAGE',
            params
        );
    },
    ApplicationViewed: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        const x = notification.application || notification;
        const params = x.job
            ? { jobTitle: x.job.title, storeName: x.store.name }
            : { storeName: x.store.name };
        return transloco.translate(
            x.job
                ? 'PRIVATE.NOTIFICATIONS.APPLICATION_VIEWED.JOB.MESSAGE'
                : 'PRIVATE.NOTIFICATIONS.APPLICATION_VIEWED.DIRECT.MESSAGE',
            params
        );
    },
    ApplicationWithdrawSuccessful: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        const x = notification.application || notification;
        const params = x.job
            ? { jobTitle: x.job.title, storeName: x.store.name }
            : { storeName: x.store.name };

        return transloco.translate(
            x.job
                ? 'PRIVATE.NOTIFICATIONS.APPLICATION_WITHDRAW_SUCCESSFUL.JOB.MESSAGE'
                : 'PRIVATE.NOTIFICATIONS.APPLICATION_WITHDRAW_SUCCESSFUL.DIRECT.MESSAGE',
            params
        );
    },
    ApplySuccessful: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        const x = notification.application || notification;
        const params = x.job
            ? { jobTitle: x.job.title, storeName: x.store.name }
            : { storeName: x.store.name };

        return transloco.translate(
            x.job
                ? 'PRIVATE.NOTIFICATIONS.APPLY_SUCCESSFUL.JOB.MESSAGE'
                : 'PRIVATE.NOTIFICATIONS.APPLY_SUCCESSFUL.DIRECT.MESSAGE',
            params
        );
    },
    ApplicationInterviewSchedule: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        const x = notification.application || notification;
        const params = x.job
            ? { jobTitle: x.job.title, storeName: x.store.name }
            : { storeName: x.store.name };
        return transloco.translate(
            x.job
                ? 'PRIVATE.NOTIFICATIONS.APPLY_SUCCESSFUL.JOB.MESSAGE'
                : 'PRIVATE.NOTIFICATIONS.APPLY_SUCCESSFUL.DIRECT.MESSAGE',
            params
        );
    },
    ApplicationOfferAccepted: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        const x = notification.application || notification;
        const params = x.job
            ? {
                  userName: x.user.name,
                  jobTitle: x.job.title,
                  storeName: x.store.name
              }
            : { storeName: x.store.name };
        return transloco.translate(
            x.job
                ? 'PRIVATE.NOTIFICATIONS.APPLICATION_OFFER_ACCEPTED.JOB.MESSAGE'
                : 'PRIVATE.NOTIFICATIONS.APPLICATION_OFFER_ACCEPTED.DIRECT.MESSAGE',
            params
        );
    },
    ApplicationOffer: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        const x = notification.application || notification;
        const params = x.job
            ? { jobTitle: x.job.title, storeName: x.store.name }
            : { storeName: x.store.name };
        return transloco.translate(
            x.job
                ? 'PRIVATE.NOTIFICATIONS.APPLICATION_OFFER.JOB.MESSAGE'
                : 'PRIVATE.NOTIFICATIONS.APPLICATION_OFFER.DIRECT.MESSAGE',
            params
        );
    },
    ApplicationOfferAcceptedConfirmation: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.APPLICATION_OFFER_ACCEPTED_CONFIRMATION.MESSAGE',
            { storeName: notification.store.name }
        );
    },
    TalentPoolApplySuccessful: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        const x = notification.application || notification;
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.TALENT_POOL_APPLY_SUCCESSFUL.MESSAGE',
            { talentPoolName: x.talent_pool.name }
        );
    },
    StoreCRApproved: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.STORE_CR_APPROVED.MESSAGE',
            { storeName: notification.store.name }
        );
    },
    StoreVerified: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.STORE_VERIFIED.MESSAGE',
            { storeName: notification.store.name }
        );
    },
    StoreCRReceived: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.STORE_CR_RECEIVED.MESSAGE',
            { storeName: notification.store.name }
        );
    },
    PasswordChanged: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.PASSWORD_CHANGED.MESSAGE'
        );
    },
    EmailChanged: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.EMAIL_CHANGED.MESSAGE'
        );
    },
    AccountConfirmed: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.ACCOUNT_CONFIRMED.MESSAGE'
        );
    },
    Welcome: (
        notification: any,
        transloco: TranslocoService,
        store?: Store
    ) => {
        return transloco.translate('PRIVATE.NOTIFICATIONS.WELCOME.MESSAGE', {
            firstName: notification.user.first_name
        });
    },
    ResumeCheckup: (
        notification: any,
        transloco: TranslocoService,
        store?: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.RESUME_CHECKUP.MESSAGE'
        );
    },
    PreInterviewApplicationInvite: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return transloco.translate(
            'PRIVATE.NOTIFICATIONS.PRE_INTERVIEW_INVITE.MESSAGE',
            {
                storeName: notification.store.name
            }
        );
    },
    MentionNotification: (
        notification: any,
        transloco: TranslocoService,
        store?: Store
    ) => {
        switch (notification.comment.commentable_type) {
            case 'application':
                return transloco.translate(
                    'PRIVATE.NOTIFICATIONS.MENTION.APPLICATION.MESSAGE',
                    {
                        name: notification.comment.commented.name,
                        storeName: notification.metadata.application.store.name
                    }
                );
            case 'discussion':
                return transloco.translate(
                    'PRIVATE.NOTIFICATIONS.MENTION.DISCUSSION.MESSAGE',
                    {
                        name: notification.comment.commented.name
                    }
                );
            default:
                return transloco.translate(
                    'PRIVATE.NOTIFICATIONS.MENTION.DEFAULT.MESSAGE',
                    {
                        name: notification.comment.commented.name
                    }
                );
                break;
        }
    }
};

export const notificationRouterState = {
    SystemNotification: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            url: notification.url
        };
    },
    MeetingScheduled: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            url: `/calendar/meeting/${notification.meeting.code}`
        };
    },
    MeetingCancelled: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            url: `/calendar/meeting/${notification.meeting.code}`
        };
    },
    VideoMeetingInvite: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            url: '/home'
        };
    },
    CoachOrderRefunded: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            url: `/genius/orders/details/${notification.order.code}`
        };
    },
    CoachVerified: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            url: `/coach/dashboard`
        };
    },
    ReportAssigned: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            url: `/reports/${notification.report.id}`
        };
    },
    CoachOrderReceived: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            url: `/genius/orders/details/${notification.order.code}`
        };
    },
    CoachOrderInProgress: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            url: `/genius/orders/details/${notification.order.code}`
        };
    },
    CoachOrderComplete: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            url: `/genius/orders/details/${notification.order.code}`
        };
    },
    CoachOrderDeclined: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            url: `/genius/orders/details/${notification.order.code}`
        };
    },
    NewCoachOrder: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            url: `/coach/dashboard/orders/${notification.order.code}`
        };
    },
    NewConnectionRequest: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            url: `/connections/requests`
        };
    },
    ConnectionRequestAccepted: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            url: `/@${notification.user.slug}`
        };
    },
    PostCommentAdded: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            url: `/post/${notification.post.slug}`
        };
    },
    PostReactionAdded: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            url: `/post/${notification.post.slug}`
        };
    },
    CommentReplyAdded: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            url: `${notification.buttonURL.replace(/^(?:\/\/|[^/]+)*\//, '')}`
        };
    },
    VideoAnswerCommentAdded: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            url: `/video/answer/${notification.answer.slug}`
        };
    },
    VideoAnswerReactionAdded: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            url: `/video/answer/${notification.answer.slug}`
        };
    },
    NewApplicant: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return { url: `/${notification.store.slug}/dashboard/candidates` };
    },
    StoreCRApproved: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return { url: `/${notification.store.slug}/dashboard` };
    },
    StoreCreated: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return { url: `/${notification.store.slug}/dashboard` };
    },
    StoreVerified: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return { url: `/${notification.store.slug}/dashboard` };
    },
    SubscriptionCanceled: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return { url: `/${notification.store.slug}/dashboard/settings` };
    },
    SubscriptionChargeFail: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return { url: `/${notification.store.slug}/dashboard/settings` };
    },
    SubscriptionChargeSuccess: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return { url: `/${notification.store.slug}/dashboard/settings` };
    },
    SubscriptionExpired: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return { url: `/${notification.store.slug}/dashboard/settings` };
    },
    ApplicationArchived: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return { url: '/jobs/applications' };
    },
    ApplicationViewed: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return { url: '/jobs/applications' };
    },
    ApplySuccessful: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return { url: '/jobs/applications' };
    },
    ApplicationWithdrawSuccessful: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            url: notification.url
        };
    },
    TalentPoolApplySuccessful: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return { url: '/explore' };
    },
    ReferenceInvite: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return { url: `/reference/${notification.hash}/complete` };
    },
    Welcome: (notification: any, transloco: TranslocoService, store: Store) => {
        return { url: '/@' + notification.user.slug };
    },
    AccountConfirmed: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return { url: '/home' };
    },
    GeniusOrderReceived: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return { url: '/genome/questionnaire/1' };
    },
    ApplicationOfferAccepted: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            url: `/${notification.application.store.slug}/dashboard/candidates/${notification.application.id}`
        };
    },
    ZVReady: (notification: any, transloco: TranslocoService, store: Store) => {
        return { url: `/zv/${notification.user.id}` };
    },
    ResumeCheckup: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return { url: `/my-resumes/details/${notification.resume.slug}` };
    },
    PreInterviewApplicationInvite: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            url: `/prescreening/${notification.code}`
        };
    },
    MentionNotification: (
        notification: any,
        transloco: TranslocoService,
        store?: Store
    ) => {
        let getMentionUrl = (notification) => {
            switch (notification.comment.commentable_type) {
                case 'comment':
                    return getMentionUrl(notification);
                case 'report':
                    return {
                        url: `/reports/${notification.metadata.report.id}`
                    };
                case 'report':
                    return {
                        url: `/reports/${notification.metadata.report.id}`
                    };
                case 'application':
                    return {
                        url: `/${notification.metadata.application.store.slug}/dashboard/candidates/app/${notification.comment.commentable_id}`
                    };
                case 'candidate':
                    return {
                        url: `/${notification.metadata.candidate.owner.slug}/dashboard/candidates/lead/${notification.metadata.candidate.user.id}`
                    };
                case 'discussion':
                    return {
                        url: `/community/discussion/${notification.metadata.discussion.slug}`
                    };
                case 'post':
                    return {
                        url: `/post/${notification.metadata.post.slug}`
                    };
                case 'videocollectionanswer':
                    return {
                        url: `/video/answer/${notification.metadata.videocollectionanswer.slug}`
                    };
                default:
                    return { url: undefined };
            }
        };
        return getMentionUrl(notification);
    }
};

export const notificationImage = {
    SystemNotification: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: 'JobzMall',
            image: {
                full_path: '/assets/icons/apple-touch-icon.png'
            }
        };
    },
    CoachOrderRefunded: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: 'JobzMall',
            image: {
                full_path: '/assets/icons/apple-touch-icon.png'
            }
        };
    },
    CoachVerified: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: 'JobzMall',
            image: {
                full_path: '/assets/icons/apple-touch-icon.png'
            }
        };
    },
    ReportAssigned: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: 'JobzMall',
            image: {
                full_path: '/assets/icons/apple-touch-icon.png'
            }
        };
    },
    ConversationUnreadCount: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: notification.conversation.participants.filter(
                (p: any) =>
                    p.user_id !== store.selectSnapshot(UserState.user)?.id
            )[0].user.name,
            image: notification.conversation.participants.filter(
                (p: any) =>
                    p.user_id !== store.selectSnapshot(UserState.user)?.id
            )[0].user.image
        };
    },
    MeetingScheduled: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: notification.meeting.host.name,
            image: notification.meeting.host.image
        };
    },
    MeetingCancelled: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: notification.meeting.host.name,
            image: notification.meeting.host.image
        };
    },
    VideoMeetingInvite: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: notification.meeting.host.name,
            image: notification.meeting.host.image
        };
    },
    PostCommentAdded: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: notification.comment.commented.name,
            image: notification.comment.commented.image
        };
    },
    CommentReplyAdded: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: notification.comment.commented.name,
            image: notification.comment.commented.image
        };
    },
    VideoAnswerCommentAdded: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: notification.comment.commented.name,
            image: notification.comment.commented.image
        };
    },

    CoachOrderReceived: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: notification.order.genius
                ? 'JobzMall'
                : notification.coach?.name,
            image: notification.order.genius
                ? {
                      full_path: '/assets/icons/apple-touch-icon.png'
                  }
                : notification.coach?.name
        };
    },
    CoachOrderInProgress: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: notification.order.genius
                ? 'JobzMall'
                : notification.coach?.name,
            image: notification.order.genius
                ? {
                      full_path: '/assets/icons/apple-touch-icon.png'
                  }
                : notification.coach?.name
        };
    },
    CoachOrderComplete: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: notification.order.genius
                ? 'JobzMall'
                : notification.coach?.name,
            image: notification.order.genius
                ? {
                      full_path: '/assets/icons/apple-touch-icon.png'
                  }
                : notification.coach?.name
        };
    },
    CoachOrderDeclined: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: notification.order.genius
                ? 'JobzMall'
                : notification.coach?.name,
            image: notification.order.genius
                ? {
                      full_path: '/assets/icons/apple-touch-icon.png'
                  }
                : notification.coach?.name
        };
    },
    NewCoachOrder: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: notification.user.name,
            image: notification.user.image
        };
    },
    PostReactionAdded: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: notification.user.name,
            image: notification.user.image
        };
    },
    NewConnectionRequest: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: notification.user.name,
            image: notification.user.image
        };
    },
    ConnectionRequestAccepted: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: notification.user.name,
            image: notification.user.image
        };
    },
    ReferenceInvite: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: notification.user.name,
            image: notification.user.image
        };
    },
    VideoAnswerReactionAdded: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: notification.user.name,
            image: notification.user.image
        };
    },
    NewApplicant: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: notification.store.name,
            image: notification.store.image
        };
    },
    SubscriptionCanceled: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: notification.store.name,
            image: notification.store.image
        };
    },
    SubscriptionChargeFail: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: notification.store.name,
            image: notification.store.image
        };
    },
    SubscriptionChargeSuccess: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: notification.store.name,
            image: notification.store.image
        };
    },
    SubscriptionExpired: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: notification.store.name,
            image: notification.store.image
        };
    },
    StoreCreated: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: notification.store.name,
            image: notification.store.image
        };
    },
    StoreVerified: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: notification.store.name,
            image: notification.store.image
        };
    },
    StoreCRApproved: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: notification.store.name,
            image: notification.store.image
        };
    },
    StoreCRReceived: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: notification.store.name,
            image: notification.store.image
        };
    },
    ApplicationArchived: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        const x = notification.application || notification;
        return {
            text: x.store.name,
            image: x.store.image
        };
    },
    ApplicationViewed: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        const x = notification.application || notification;
        return {
            text: x.store.name,
            image: x.store.image
        };
    },
    ApplicationWithdrawSuccessful: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        const x = notification.application || notification;
        return {
            text: x.store.name,
            image: x.store.image
        };
    },
    ApplySuccessful: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        const x = notification.application || notification;
        return {
            text: x.store.name,
            image: x.store.image
        };
    },
    PreInterviewApplicationInvite: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        const x = notification.application || notification;
        return {
            text: x.store.name,
            image: x.store.image
        };
    },
    ResumeCheckup: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => {
        return {
            text: 'JobzMall',
            image: {
                full_path: '/assets/icons/apple-touch-icon.png'
            }
        };
    },
    MentionNotification: (
        notification: any,
        transloco: TranslocoService,
        store?: Store
    ) => {
        return {
            text: notification.comment.commented.name,
            image: notification.comment.commented.image
        };
    }
};

export const notificationIcon = {
    ReferenceInvite: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fal fa-file-signature',
    ReportAssigned: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-user-check',
    MeetingScheduled: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-circle-video',
    MeetingCancelled: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-circle-video',
    VideoMeetingInvite: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-circle-video',
    CoachOrderRefunded: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-credit-card',
    CoachVerified: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-file-chart-column',
    CoachOrderReceived: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => coachServiceIconMap[notification.service.name],
    CoachOrderInProgress: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => coachServiceIconMap[notification.service.name],
    CoachOrderComplete: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => coachServiceIconMap[notification.service.name],
    CoachOrderDeclined: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => coachServiceIconMap[notification.service.name],
    NewCoachOrder: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => coachServiceIconMap[notification.service.name],
    NewConnectionRequest: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-user-plus',
    ConnectionRequestAccepted: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-user-check',
    StarRequestReceived: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-star',
    StarRequestApproved: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-star',
    VideoAnswerCommentAdded: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-comments-alt',
    PostCommentAdded: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-comments-alt',
    CommentReplyAdded: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-comments-alt',
    NewApplicant: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-smile-beam',
    ApplicationInterviewSchedule: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-smile-beam',
    ApplicationOfferAcceptedConfirmation: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-smile-beam',
    ApplicationOfferAccepted: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-smile-beam',
    ApplicationOffer: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-smile-beam',
    PostReactionAdded: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-smile-beam',
    VideoAnswerReactionAdded: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-smile-beam',
    SubscriptionCanceled: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-credit-card',
    SubscriptionChargeFail: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-credit-card',
    SubscriptionChargeSuccess: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-credit-card',
    SubscriptionExpired: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-credit-card',
    StoreCreated: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-store',
    StoreVerified: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-store',
    ApplicationArchived: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-thumbs-down',
    AccountConfirmed: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-thumbs-up',
    PasswordChanged: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-thumbs-up',
    EmailChanged: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-thumbs-up',
    Welcome: (notification: any, transloco: TranslocoService, store: Store) =>
        'fas fa-envelope-open-text',
    ApplicationViewed: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-eye',
    ApplicationWithdrawSuccessful: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-ban',
    TalentPoolApplySuccessful: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-inbox-in',
    ApplySuccessful: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-inbox-in',
    ZVReady: (notification: any, transloco: TranslocoService, store: Store) =>
        'fas fa-film',
    ZVSubmissionSuccessful: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-film',
    StoreCRApproved: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-user-check',
    StoreCRReceived: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-inbox-in',
    GeniusOrderReceived: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-mind-share',
    ResumeCheckup: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-file-user',
    MentionNotification: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-quote-right',
    PreInterviewApplicationInvite: (
        notification: any,
        transloco: TranslocoService,
        store: Store
    ) => 'fas fa-question'
};
