import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import { fuseAnimations } from '@fuse/animations/public-api';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { User } from '@jobzmall/models';
import { RefreshUser, UserService, UserState } from '@jobzmall/user';
import { Select, Store } from '@ngxs/store';
import { Observable, first } from 'rxjs';

@Component({
    selector: 'privacy-header-panel',
    templateUrl: './privacy-header-panel.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})
export class PrivacyHeaderPanelComponent
    extends JMBaseComponent
    implements OnInit
{
    options = [
        {
            name: 'Maximum Visibility',
            icon: 'mimicons_outline:brightness-up',
            class: 'text-green-500',
            value: false,
            description:
                "My profile is openly visible to companies and recruiters, maximizing my exposure and job opportunities."
        },
        {
            name: 'Privacy Focused',
            icon: 'mimicons_outline:brightness-down',
            class: 'text-red-500',
            value: true,
            description:
                "My profile remains hidden from public view, visible only to organizations I directly apply to, ensuring my privacy."
        }
    ];

    @Select(UserState.user) user$: Observable<User>;

    @Output() onClosePanel = new EventEmitter();
    @Output() onSave = new EventEmitter();

    constructor(
        private _store: Store,
        private _userService: UserService,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {}

    save(option: any): void {
        this.onSave.emit(option.value);
        this._userService
            .save(
                { privacy: option.value },
                this._store.selectSnapshot(UserState.user).slug
            )
            .pipe(first())
            .subscribe(() => {
                this._store.dispatch(new RefreshUser());
            });
    }
}
