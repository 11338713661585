<!-- Navigation -->
<jbz-vertical-navigation *ngIf="showNavigation" class="bg-card dark:bg-gray-900 print:hidden"
    [appearance]="'thin'" [mode]="isScreenSmall ? 'over'
    : 'side'" [name]="'mainNavigation'" [navigation]="data.navigation.compact" [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center justify-center h-20">
            <!-- <img class="w-8" src="assets/images/logo/logo.svg" alt="Logo image"> -->
        </div>
    </ng-container>
</jbz-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <jm-header *ngIf="showHeader" [showLogo]="showHeaderLogo"
        [showLanguageSwitcher]="router.url === '/' && headerTransparent" [showOmni]="showHeaderOmni"
        [transparent]="headerTransparent" [absolute]="headerAbsolute" class="top-0 z-50 w-full" [ngClass]="{'fixed left-0': headerAbsolute, 'sticky':
            !headerAbsolute, 'md:pl-15': showNavigation && headerAbsolute}"></jm-header>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <jm-footer *ngIf="showFooter" class="w-full"></jm-footer>
    <mobile-navigation *ngIf="showBottomNavigation"></mobile-navigation>
    <messages-widget></messages-widget>
    <contact-widget></contact-widget>
</div>
