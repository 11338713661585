import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Restangular } from '@jobzmall/core/api';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ViewService {
    constructor(private api: Restangular) {}

    log(entityType, entityId, cooldown = 1) {
        this.api
            .all(`insights/view/${entityType}/${entityId}`)
            .customPOST({
                cooldown
            })
            .pipe(first())
            .subscribe(() => {});
    }

    b(
        entityType,
        entityId = undefined,
        amount = 52,
        date_from = undefined,
        date_to = undefined,
        country_ids = []
    ): Observable<any> {
        if (entityId) {
            return this.api
                .all(`insights/view/${entityType}/${entityId}/b`)
                .customPOST({
                    amount
                })
                .pipe(map((res: any) => res.data));
        }
        return this.api
            .all(`insights/view/${entityType}/date/b`)
            .customPOST(
                Object.entries({
                    amount,
                    date_from,
                    date_to,
                    country_ids
                }).reduce((a, [k, v]) => (v == null ? a : ((a[k] = v), a)), {})
            )
            .pipe(map((res: any) => res.data));
    }

    p(
        entityType,
        date_from = undefined,
        date_to = undefined,
        country_ids = []
    ): Observable<any> {

        let options = Object.entries({
            date_from,
            date_to,
            country_ids
        }).reduce((a, [k, v]) => (v == null ? a : ((a[k] = v), a)), {})

        return this.api
            .all(`insights/view/${entityType}/date/b`)
            .customPATCH(
                options
            )
            .pipe(map((res: any) => res.data));
    }
}
