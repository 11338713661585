import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    HostBinding,
    forwardRef,
    Inject,
    Optional
} from '@angular/core';

import * as dayjs from 'dayjs';

import { ConfigService } from '@jobzmall/config';
import { SearchFilter } from '../search-filters/search-filters.component';
import { connectCurrentRefinements } from 'instantsearch.js/es/connectors';
import { ViewEncapsulation } from '@angular/core';
import {
    NgAisIndex,
    NgAisInstantSearch,
    BaseWidget
} from 'angular-instantsearch';

@Component({
    selector: 'dashboard-filters',
    styleUrls: ['./dashboard-filters.component.scss'],
    encapsulation: ViewEncapsulation.None,
    templateUrl: './dashboard-filters.component.html'
})
export class DashboardFiltersComponent extends BaseWidget implements OnInit {
    @Input() searchInstance: any;
    @Input() type: string;
    @Input() kind: string = 'drawer';
    @Input() showApply = false;
    @Input() @HostBinding('class') class;
    @Input() minimal = false;
    @Input() exclude = [];

    state: any = {};

    filters: Array<SearchFilter> = [];
    @Output() filtersInitialized: EventEmitter<any> = new EventEmitter();
    @Output() filtersClosed: EventEmitter<any> = new EventEmitter();
    @Output() applied: EventEmitter<any> = new EventEmitter();
    @Output() cleared: EventEmitter<any> = new EventEmitter();

    constructor(
        @Inject(forwardRef(() => NgAisIndex))
        @Optional()
        public parentIndex: NgAisIndex,
        @Inject(forwardRef(() => NgAisInstantSearch))
        public instantSearchInstance: NgAisInstantSearch,
        private _config: ConfigService
    ) {
        super('DashboardSearchFilters');
    }

    ngOnInit(): void {
        this.createWidget(connectCurrentRefinements, {});
        super.ngOnInit();

        this.initFilters();
    }

    initFilters() {
        switch (this.type) {
            case 'candidates':
                this.filters = this.candidateFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'jobs':
                this.filters = this.jobFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'applications':
                this.filters = this.applicationFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'async_interview_completions':
                this.filters = this.asyncInterviewCompletionFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'users':
                this.filters = this.userFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            default:
                break;
        }
    }

    closeFilters() {
        this.filtersClosed.emit({});
    }

    asyncInterviewCompletionFilters(): SearchFilter[] {
        return [
            new SearchFilter('refinement', 'Interview', {
                attribute: 'interview.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Interviews...'
            }),
            new SearchFilter('refinement', 'Location', {
                attribute: 'user.location',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Locations...'
            }),
            new SearchFilter('refinement', 'Job Titles', {
                attribute: 'user.prior_experience.title',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Titles...'
            }),
            new SearchFilter('refinement', 'Organizations', {
                attribute: 'user.prior_experience.company',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Organizations...'
            }),
            new SearchFilter('refinement', 'Skills', {
                attribute: 'user.skills',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Skills...'
            }),
            new SearchFilter('refinement', 'Soft Skills', {
                attribute: 'user.soft_skills',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Soft Skills...'
            }),

            new SearchFilter('refinement', 'School', {
                attribute: 'user.education.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Schools...'
            }),
            new SearchFilter('refinement', 'Degree', {
                attribute: 'user.education.degree',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Degree Field', {
                attribute: 'user.education.field',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Fields...'
            }),
            new SearchFilter('refinement', 'Certifications', {
                attribute: 'user.certifications.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Certifications...'
            }),
            new SearchFilter('refinement', 'Languages', {
                attribute: 'user.languages',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Languages...'
            })
        ];
    }

    candidateFilters(): SearchFilter[] {
        return [
            new SearchFilter('toggle', 'Show Video Resumes Only', {
                attribute: 'user.has_video_collection',
                operator: 'and',
                icon: 'mimicons_outline:video'
            }),
            new SearchFilter('refinement', 'Location', {
                attribute: 'user.location',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Locations...'
            }),
            new SearchFilter('refinement', 'Job Titles', {
                attribute: 'user.prior_experience.title',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Titles...'
            }),
            new SearchFilter('refinement', 'Organizations', {
                attribute: 'user.prior_experience.company',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Organizations...'
            }),
            new SearchFilter('refinement', 'Skills', {
                attribute: 'user.skills',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Skills...'
            }),
            new SearchFilter('refinement', 'Soft Skills', {
                attribute: 'user.soft_skills',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Soft Skills...'
            }),

            new SearchFilter('refinement', 'School', {
                attribute: 'user.education.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Schools...'
            }),
            new SearchFilter('refinement', 'Degree', {
                attribute: 'user.education.degree',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Degree Field', {
                attribute: 'user.education.field',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Fields...'
            }),
            new SearchFilter('refinement', 'Certifications', {
                attribute: 'user.certifications.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Certifications...'
            }),
            // new SearchFilter('refinement', 'Languages', {
            //     attribute: 'user.languages',
            //     operator: 'and',
            //     searchable: true,
            //     searchPlaceholder: 'Search for Languages...'
            // })
        ];
    }

    userFilters(): SearchFilter[] {
        return [
            new SearchFilter('toggle', 'Show Video Resumes Only', {
                attribute: 'has_video_collection',
                operator: 'and',
                icon: 'mimicons_outline:video'
            }),
            new SearchFilter('refinement', 'Location', {
                attribute: 'location',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Locations...'
            }),
            new SearchFilter('refinement', 'Job Titles', {
                attribute: 'prior_experience.title',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Titles...'
            }),
            new SearchFilter('refinement', 'Organizations', {
                attribute: 'prior_experience.company',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Organizations...'
            }),
            new SearchFilter('refinement', 'Skills', {
                attribute: 'skills',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Skills...'
            }),
            new SearchFilter('refinement', 'Soft Skills', {
                attribute: 'soft_skills',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Soft Skills...'
            }),

            new SearchFilter('refinement', 'School', {
                attribute: 'education.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Schools...'
            }),
            new SearchFilter('refinement', 'Degree', {
                attribute: 'education.degree',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Degree Field', {
                attribute: 'education.field',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Fields...'
            }),
            new SearchFilter('refinement', 'Certifications', {
                attribute: 'certifications.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Certifications...'
            }),
            new SearchFilter('refinement', 'Languages', {
                attribute: 'languages',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Languages...'
            })
        ];
    }

    applicationFilters(): SearchFilter[] {
        return [
            new SearchFilter('toggle', 'Show Video Resumes Only', {
                attribute: 'has_video_collection',
                operator: 'and',
                icon: 'mimicons_outline:video'
            }),
            new SearchFilter('numeric', 'Date Applied', {
                attribute: 'created_at_unix',
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().subtract(1, 'day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(1, 'year').unix(),
                        label: 'This Year'
                    }
                ]
            }),
            new SearchFilter('refinement', 'Location', {
                attribute: 'location',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Locations...'
            }),
            new SearchFilter('refinement', 'Job Titles', {
                attribute: 'prior_experience.title',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Titles...'
            }),
            new SearchFilter('refinement', 'Organizations', {
                attribute: 'prior_experience.company',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Organizations...'
            }),
            new SearchFilter('refinement', 'Skills', {
                attribute: 'skills',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Skills...'
            }),
            new SearchFilter('refinement', 'Soft Skills', {
                attribute: 'soft_skills',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Soft Skills...'
            }),

            new SearchFilter('refinement', 'School', {
                attribute: 'education.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Schools...'
            }),
            new SearchFilter('refinement', 'Degree', {
                attribute: 'education.degree',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Degree Field', {
                attribute: 'education.field',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Fields...'
            }),
            new SearchFilter('refinement', 'Certifications', {
                attribute: 'certifications.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Certifications...'
            }),
            new SearchFilter('refinement', 'Languages', {
                attribute: 'languages',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Languages...'
            }),
            new SearchFilter('refinement', 'Status', {
                attribute: 'status',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Job', {
                attribute: 'job',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Jobs...'
            }),
            new SearchFilter('refinement', 'Job Type', {
                attribute: 'job_type',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Qualifications', {
                attribute: 'qualifications',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Qualifications...'
            })
            // new SearchFilter('hierarchical', 'Questions Answered', {
            //     attributes: ['questions', 'answers']
            // }),
        ];
    }

    jobFilters(): SearchFilter[] {
        return [
            new SearchFilter('numeric', 'Date Posted', {
                attribute: 'created_at_unix',
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().subtract(1, 'day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    },
                    {
                        start: dayjs().subtract(1, 'year').unix(),
                        label: 'This Year'
                    }
                ]
            }),
            new SearchFilter('refinement', 'Status', {
                attribute: 'status',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Skills', {
                attribute: 'required_skills',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Skills...'
            }),
            new SearchFilter('refinement', 'Job Type', {
                attribute: 'type',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Remote', {
                attribute: 'remote_type',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Job Function', {
                attribute: 'functions',
                operator: 'and'
            }),

            new SearchFilter('refinement', 'Experience Level', {
                attribute: 'experience',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Visa Support', {
                attribute: 'visa_explanation',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Reference Number', {
                attribute: 'reference_number',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Reference Numbers...'
            }),
            new SearchFilter('refinement', 'Job Tier', {
                attribute: 'tier',
                operator: 'and'
            })
        ];
    }
}
