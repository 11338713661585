import { Injectable } from '@angular/core';

import { map, extend } from 'lodash-es';

import * as dayjs from 'dayjs';

import {
    Certification,
    CertificationAdapter,
    Education,
    EducationAdapter,
    Experience,
    ExperienceAdapter,
    SEO,
    SEOAdapter,
    SkillAdapter,
    SoftSkill,
    UserLanguage,
    UserLanguageAdapter,
    Media,
    MediaAdapter,
    HonorAdapter,
    Honor,
    FunctionalSkill,
    Country,
    CountryAdapter,
    Reference,
    ReferenceAdapter,
    CoachServiceAdapter
} from '@jobzmall/models';

import { Adapter } from './adapter';

// Types
export type Scheme = 'auto' | 'dark' | 'light';
export type Screens = { [key: string]: string };
export type Theme = 'theme-default' | string;
export type Themes = { id: string; name: string }[];

export class User {
    isAuthenticated: any;
    constructor(
        public id?: number,
        public slug?: string,
        public first_name?: string,
        public last_name?: string,
        public chat_auth_key?: string,
        public education?: Array<Education>,
        public experience?: Array<Experience>,
        public certifications?: Array<Certification>,
        public honors?: Array<Honor>,
        public languages?: Array<UserLanguage>,
        public skills?: Array<FunctionalSkill>,
        public soft_skills?: Array<SoftSkill>,
        public created_at?: Date,
        public active?: boolean,
        public permissions?: any,
        public user_type?: UserType,
        public seo?: SEO,
        public professional_title?: string,
        public image?: Media,
        public location?: string,
        public phone?: string,
        public stores?: Array<number>,
        public privacy?: boolean,
        public description?: string,
        public email?: string,
        public background_id?: number,
        public background?: Media,
        public resume_id?: number,
        public resume?: Media,
        public blocked?: boolean,
        public deactivated?: boolean,
        public password_reset?: boolean,
        public ps?: number,
        public psn?: any,
        public name?: string,
        public user_state?: any,
        public status?: any,
        public json_ld?: any,
        public last_login?: Date,
        public verified?: boolean,
        public email_verified_at?: Date,
        public online?: boolean,
        public message_sender_id?: number,
        public links?: Array<Link>,
        public anon?: boolean,
        public video_collection_id?: number,
        public seeking_job?: boolean,
        public will_relocate?: boolean,
        public can_remote?: boolean,
        public talent_bank?: boolean,
        public has_management_experience?: boolean,
        public star_profile_id?: number,
        public has_video_collection?: boolean,
        public language_id?: number,
        public has_resume?: boolean,
        public phone_verified_at?: Date,
        public phone_normalized?: string,
        public phone_national?: string,
        public phone_e164?: string,
        public phone_country_code?: string,
        public phone_valid?: boolean,
        public two_factor?: boolean,
        public can_be_messaged?: boolean,
        public is_hiring?: boolean,
        public j_verified?: boolean,
        public scheme?: Scheme,
        public user_hash?: string,
        public needs_info?: boolean,
        public birthdate?: Date,
        public drivers_license_type_id?: number,
        public gender_id?: number,
        public pronouns?: string,
        public military_service_id?: number,
        public mandatory_military_id?: number,
        public disabled?: boolean,
        public compensation_interval_id?: number,
        public currency_type_id?: number,
        public compensation?: number,
        public compensation_max?: number,
        public ethnicity_id?: number,
        public nationalities?: Array<Country>,
        public references?: Array<Reference>,
        public can_change_two_factor?: boolean,
        public admin_type?: AdminType,
        public has_stores?: boolean,
        public identity_verification_status?: string,
        public value_badges?: Array<any>,
        public allow_networking?: boolean,
        public is_coaching?: boolean,
        public coach_verified?: boolean,
        public coach_services?: Array<any>,
        public coach_description?: string,
        public availability?: any,
        public can_sell?: boolean,
        public timezone?: string,
        public coach_orders_count?: number,
        public jobseeker_status?: string,
        public last_check_in?: Date,
        public applications_count?: number,
        public completed_main_onboarding?: boolean,
        public candidate_id?: number,
        public ava_sessions_count?: number,
        public currency_type?: any,
        public compensation_interval?: any,
        public linked_social_account_names?: Array<string>,
        public orgs?: Array<any>,
        public resume_parse_count?: number,
        public given_references?: Array<Reference>,
        public last_email_verification_sent_at?: Date,
        public phone_last_updated_at?: Date,
        public in_progress_coach_orders_count?: number,
        public employment_status_id?: number,
        public interview_availability_id?: number,
        public employment_status?: any,
        public interview_availability?: any
    ) {}
}

export enum AdminType {
    Sales = 'admin.sales' as any,
    Developer = 'admin.developer' as any,
    DataEntry = 'admin.data-entry' as any,
    Business = 'admin.business' as any,
    Super = 'admin.super' as any
}

export enum UserType {
    Employer = 'employer' as any,
    Jobseeker = 'jobseeker' as any,
    Default = 'default' as any,
    Admin = 'admin' as any
}

export class UserApplication {
    constructor(public id: number, public applied: boolean) {}
}
export class UserApplicationContext {
    constructor(
        public jobs?: Array<UserApplication>,
        public stores?: Array<UserApplication>
    ) {}
}

@Injectable({
    providedIn: 'root'
})
export class UserAdapter implements Adapter<User> {
    constructor(
        private _coachingServiceAdapter: CoachServiceAdapter,
        private _referenceAdapter: ReferenceAdapter,
        private _educationAdapter: EducationAdapter,
        private _experienceAdapter: ExperienceAdapter,
        private _certificationAdapter: CertificationAdapter,
        private _userLanguageAdapter: UserLanguageAdapter,
        private _skillAdapter: SkillAdapter,
        private _seoAdapter: SEOAdapter,
        private _mediaAdapter: MediaAdapter,
        private _linkAdapter: LinkAdapter,
        private _countryAdapter: CountryAdapter,
        private _honorAdapter: HonorAdapter
    ) {}

    adapt(item: any): User {
        const user = new User(
            item.id,
            item.slug,
            item.first_name,
            item.last_name,
            item.chat_auth_key,
            map(item.education, (education: any) =>
                this._educationAdapter.adapt(education)
            ),
            map(item.experience, (experience: any) =>
                this._experienceAdapter.adapt(experience)
            ),
            map(item.certifications, (certification: any) =>
                this._certificationAdapter.adapt(certification)
            ),
            map(item.honors, (honor: any) => this._honorAdapter.adapt(honor)),
            (item.languages || []).map((lang: any) => {
                return this._userLanguageAdapter.adapt(lang);
            }),
            map(
                item.skills,
                (skill: any) =>
                    this._skillAdapter.adapt(skill) as FunctionalSkill
            ),
            map(item.soft_skills, (soft_skills: any) =>
                this._skillAdapter.adapt(soft_skills)
            ),
            item.created_at ? dayjs(item.created_at).toDate() : undefined,
            item.is_active,
            item.permissions,
            item.user_type as UserType,
            item.seo ? this._seoAdapter.adapt(item.seo) : undefined,
            item.professional_title,
            item.image ? this._mediaAdapter.adapt(item.image) : undefined,
            item.location,
            item.phone,
            item.employer_stores,
            item.privacy,
            item.description,
            item.email,
            item.background_id,
            item.background
                ? this._mediaAdapter.adapt(item.background)
                : undefined,
            item.resume_id,
            item.resume ? this._mediaAdapter.adapt(item.resume) : undefined,
            item.blocked,
            item.deactivated,
            item.password_reset,
            item.ps,
            item.psn,
            item.name,
            item.user_state,
            item.status,
            item.json_ld,
            item.last_login ? dayjs(item.last_login).toDate() : undefined,
            item.verified,
            item.email_verified_at
                ? dayjs(item.email_verified_at).toDate()
                : undefined,
            item.online,
            item.message_sender_id,
            map(item.links, (link: any) => this._linkAdapter.adapt(link)),
            item.anon,
            item.video_collection_id,
            item.seeking_job,
            item.will_relocate,
            item.can_remote,
            item.talent_bank,
            item.has_management_experience,
            item.star_profile_id,
            item.has_video_collection,
            item.language_id,
            item.has_resume,
            item.phone_verified_at
                ? dayjs(item.phone_verified_at).toDate()
                : undefined,
            item.phone_normalized,
            item.phone_national,
            item.phone_e164,
            item.phone_country_code,
            item.phone_valid,
            item.two_factor ?? false,
            item.can_be_messaged ?? false,
            item.is_hiring ?? false,
            item.j_verified ?? false,
            item.scheme,
            item.user_hash,
            item.needs_info,
            item.birthdate ? dayjs(item.birthdate).toDate() : undefined,
            item.drivers_license_type_id,
            item.gender_id,
            item.pronouns,
            item.military_service_id,
            item.mandatory_military_id,
            item.disabled,
            item.compensation_interval_id,
            item.currency_type_id,
            item.compensation,
            item.compensation_max,
            item.ethnicity_id,
            item.nationalities
                ? map(item.nationalities, (country: any) =>
                      this._countryAdapter.adapt(country)
                  )
                : [],
            item.references
                ? map(item.references, (reference: any) =>
                      this._referenceAdapter.adapt(reference)
                  )
                : [],
            item.can_change_two_factor,
            item.admin_type as AdminType,
            item.has_stores,
            item.identity_verification_status,
            item.value_badges ?? [],
            item.allow_networking,
            item.is_coaching,
            item.coach_verified,
            item.coach_services
                ? map(item.coach_services, (s: any) =>
                      this._coachingServiceAdapter.adapt(s)
                  )
                : [],
            item.coach_description,
            item.availability
                ? item.availability
                : {
                      schedule: {
                          sunday: [],
                          monday: ['09:00-17:00'],
                          tuesday: ['09:00-17:00'],
                          wednesday: ['09:00-17:00'],
                          thursday: ['09:00-17:00'],
                          friday: ['09:00-17:00'],
                          saturday: []
                      }
                  },
            item.can_sell,
            item.timezone,
            item.coach_orders_count,
            item.jobseeker_status || 'ready',
            item.last_check_in ? dayjs(item.last_check_in).toDate() : undefined,
            item.applications_count,
            item.completed_main_onboarding,
            item.candidate_id,
            item.ava_sessions_count,
            item.currency_type,
            item.compensation_interval,
            item.linked_social_account_names,
            item.orgs,
            item.resume_parse_count,
            item.given_references
            ? map(item.given_references, (reference: any) =>
                  this._referenceAdapter.adapt(reference)
              )
            : [],
            item.last_email_verification_sent_at ? dayjs(item.last_email_verification_sent_at).toDate() : undefined,
            item.phone_last_updated_at ? dayjs(item.phone_last_updated_at).toDate() : undefined,
            item.in_progress_coach_orders_count,
            item.employment_status_id,
            item.interview_availability_id,
            item.employment_status,
            item.interview_availability
        );
        return user;
    }

    anon(item: any): any {
        if (item.birthdate) {
            return extend(item, {
                birthdate: item.birthdate
                    ? item.birthdate.toISOString()
                    : undefined
            });
        } else {
            return item;
        }
    }
}

export class Link {
    constructor(
        public id?: number,
        public url?: string,
        public type?: string
    ) {}
}

@Injectable({
    providedIn: 'root'
})
export class LinkAdapter implements Adapter<Link> {
    adapt(item: any): Link {
        return new Link(item.id, item.url, item.type);
    }
}

class UserApplicationAdapter implements Adapter<UserApplication> {
    adapt(item: any): UserApplication {
        return new UserApplication(item.id, item.applied);
    }
}

class UserApplicationContextAdapter implements Adapter<UserApplicationContext> {
    adapt(item: any): UserApplicationContext {
        return new UserApplicationContext(
            item.jobs
                ? map(item.jobs, (app: any) =>
                      new UserApplicationAdapter().adapt(app)
                  )
                : [],
            item.stores
                ? map(item.stores, (app: any) =>
                      new UserApplicationAdapter().adapt(app)
                  )
                : []
        );
    }
}
