<ng-container *transloco="let t">
    <div class="flex flex-col flex-auto modal">
        <!-- Header -->
        <div class="flex items-center justify-between h-16 pl-6 pr-3 flex-0 sm:pr-5 sm:pl-8 bg-primary text-on-primary">
            <div class="text-lg font-medium">Report {{type.toTitleCase()}}</div>
            <button mat-icon-button (click)="dialogRef.close()" [tabIndex]="-1">
                <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
            </button>
        </div>
        <div *ngIf="!existingReport && !loadingExistingReport"
            class="overflow-y-auto overflow-x-hidden px-6 pt-6 h-fill flex flex-auto flex-col w-full md:h-max">
            <span class="mb-4 text-lg">
                Please tell us why you want to report this {{type}}
            </span>
            <ng-container [ngSwitch]="type">
                <ng-container *ngSwitchCase="'job'">
                    <mat-radio-group class="mb-6 space-y-4 flex flex-col" [(ngModel)]="reason">
                        <mat-radio-button color="primary" class="my-1"
                            value="Inaccurate, spam or misleading job description">
                            Inaccurate, spam or misleading job description
                        </mat-radio-button>
                        <mat-radio-button color="primary" class="my-1" value="Suspicious or fraudulent job listing">
                            Suspicious or fraudulent job listing
                        </mat-radio-button>
                        <mat-radio-button color="primary" class="my-1" value="The position is expired">
                            The position is expired
                        </mat-radio-button>
                        <mat-radio-button color="primary" class="my-1"
                            value="Job is not displayed on the organizations career page">
                            Job is not displayed on the organizations career page
                        </mat-radio-button>
                        <mat-radio-button color="primary" class="my-1"
                            value="Discrimination or bias in job requirements">
                            Discrimination or bias in job requirements
                        </mat-radio-button>
                        <mat-radio-button color="primary" class="my-1" value="Expired or already filled position">
                            Expired or already filled position
                        </mat-radio-button>
                        <mat-radio-button color="primary" class="my-1" value="Other">
                            Other
                        </mat-radio-button>
                    </mat-radio-group>
                </ng-container>
                <ng-container *ngSwitchCase="'user'">
                    <mat-radio-group class="mb-6 space-y-4 flex flex-col" [(ngModel)]="reason">
                        <mat-radio-button color="primary" class="my-1" value="I think it's inappropriate for JobzMall">
                            I think it's inappropriate for JobzMall
                        </mat-radio-button>
                        <mat-radio-button color="primary" class="my-1"
                            value="I think this account may have been hacked">
                            I think this account may have been hacked
                        </mat-radio-button>
                        <mat-radio-button color="primary" class="my-1" value="Harassment or bullying">
                            Harassment or bullying
                        </mat-radio-button>
                        <mat-radio-button color="primary" class="my-1" value="Spamming or sending unsolicited messages">
                            Spamming or sending unsolicited messages
                        </mat-radio-button>
                        <mat-radio-button color="primary" class="my-1"
                            value="Posting inappropriate or offensive content">
                            Posting inappropriate or offensive content
                        </mat-radio-button>
                        <mat-radio-button color="primary" class="my-1" value="Impersonation or creating fake profiles">
                            Impersonation or creating fake profiles
                        </mat-radio-button>
                        <mat-radio-button color="primary" class="my-1" value="Scamming or fraudulent behavior">
                            Scamming or fraudulent behavior
                        </mat-radio-button>
                        <mat-radio-button color="primary" class="my-1" value="Other">
                            Other
                        </mat-radio-button>
                    </mat-radio-group>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <mat-radio-group class="mb-6 space-y-4 flex flex-col" [(ngModel)]="reason">
                        <mat-radio-button color="primary" class="my-1" value="I think it's inappropriate for JobzMall">
                            I think it's inappropriate for JobzMall
                        </mat-radio-button>
                        <mat-radio-button color="primary" class="my-1" value="I think it's fake, spam or a scam">
                            I think it's fake, spam or a scam
                        </mat-radio-button>
                        <mat-radio-button color="primary" class="my-1"
                            value="I think this account may have been hacked">
                            I think this account may have been hacked
                        </mat-radio-button>
                        <mat-radio-button color="primary" class="my-1" value="Other">
                            Other
                        </mat-radio-button>
                    </mat-radio-group>
                </ng-container>
            </ng-container>

            <div class="flex-col flex py-6">
                <form class="flex-col flex-auto w-full flex" name="form" #reasonForm="ngForm" (ngSubmit)="submit()">
                    <mat-form-field floatLabel="always" class="w-full">
                        <mat-label>Additional Details</mat-label>
                        <textarea minlength="50" maxlength="1000" [(ngModel)]="additionalDetails"
                            placeholder="Please provide any additional details to help us process your report."
                            style="resize: none;" maxlength="2000" type="text" matInput name="reason"
                            [required]="type === 'user'" [cdkTextareaAutosize] cdkAutosizeMaxRows="5"
                            cdkAutosizeMinRows="3"></textarea>
                        <mat-error
                            *ngIf="reasonForm.controls.reason?.errors?.required && reasonForm.controls.reason?.touched">
                            This field is required.
                        </mat-error>
                        <mat-error
                            *ngIf="reasonForm.controls.reason?.errors?.minlength && reasonForm.controls.reason?.touched">
                            Minimum length is 50 characters.
                        </mat-error>
                        <mat-error
                            *ngIf="reasonForm.controls.reason?.errors?.maxlength && reasonForm.controls.reason?.touched">
                            Maximum length is 1000 characters.
                        </mat-error>
                    </mat-form-field>
                    <div class="flex flex-auto"></div>
                    <div *ngIf="reason"
                        class="bg-card z-10 border-t py-2 px-4 sticky space-x-4 bottom-0 left-0 w-full flex items-center justify-end">
                        <button type="button" (click)="dialogRef.close()" mat-flat-button>
                            {{t('PUBLIC.SHARED.ACTION.CANCEL')}}
                        </button>
                        <button [disabled]="!reasonForm.valid" class="ml-4" mat-flat-button type="submit"
                            [color]="'primary'">
                            <span *ngIf="!loading">{{t('PUBLIC.SHARED.ACTION.SUBMIT')}}</span>
                            <mat-progress-spinner *ngIf="loading" [diameter]="24" [mode]="'indeterminate'">
                            </mat-progress-spinner>
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div *ngIf="existingReport && !loadingExistingReport"
            class="overflow-y-auto overflow-x-hidden px-6 py-6 h-fill flex flex-auto flex-col w-full md:h-max">
            <div
                class="flex flex-col my-6 items-center justify-center w-full mx-auto max-w-xl space-y-3 px-2 text-center step slow">
                <mat-icon class="icon-size-10 text-green-500 animate-drop-in"
                    [svgIcon]="'heroicons_outline:check-circle'">
                </mat-icon>
                <h2 class="text-2xl font-semibold font-display animate-text-appear-1">
                    Thank you for your report!
                </h2>
                <p class="mt-1 text-lg text-secondary animate-text-appear-2">
                    Your report is currently being reviewed by our team.
                </p>
            </div>
        </div>
        <div *ngIf="loadingExistingReport"
            class="overflow-y-auto overflow-x-hidden items-center justify-center p-12 h-fill flex flex-auto flex-col w-full md:h-max">
            <mat-progress-spinner [diameter]="32"
            [mode]="'indeterminate'">
        </mat-progress-spinner>
        </div>
    </div>
</ng-container>
