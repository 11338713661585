import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatFloat'
})
export class FormatFloatPipe implements PipeTransform {

  transform(value: number): string {
    if (value == null) {
      return '';
    }
    return parseFloat(value.toFixed(2)).toString();
  }

}
