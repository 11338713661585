import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TimeService {
    private evening: number;
    private morning: number;
    private afternoon: number;


    constructor() {
        this.evening = 19; // 7:00PM
        this.afternoon = 12; // 12:00PM
        this.morning = 6; // 6:00AM
    }

    /**
     * Gets time of readable time day (morning, afternoon, evening)
     * @returns {Observable<string>}
     */
    public readableTimeOfDay(input?: dayjs.Dayjs): Observable<string> {
        let g = ''; //return g

        if (!input || !input.isValid()) {
            input = dayjs(new Date());
        }

        let currentHour = input.toDate().getHours();

        if (currentHour >= this.morning && currentHour < this.afternoon) {
            g = 'morning';
        } else if (currentHour >= this.afternoon && currentHour < this.evening) {
            g = 'afternoon';
        } else {
            g = 'evening';
        }

        return of(g);
    }

    /**
     * Get whether is night time or not
     * @returns {Observable<boolean>}
     */
    public isNight(input?: dayjs.Dayjs): Observable<boolean> {
        if (!input || !input.isValid()) {
            input = dayjs(new Date());
        }
        let currentHour = input.toDate().getHours();
        return of(!(currentHour >= this.morning && currentHour < this.evening));
    }
}
