import { Injectable, Injector } from '@angular/core';

import * as dayjs from 'dayjs';

import { Adapter } from './adapter';
import { Experience, ExperienceAdapter, UserAdapter } from '@jobzmall/models';

export interface IReference {
    id?: number;
    first_name?: string;
    last_name?: string;
    professional_title?: string;
    email?: string;
    phone?: string;
    address?: string;
    description?: string;
    updated_at?: Date;
    created_at?: Date;
    completed_at?: Date;
    status?: string;
    relationship?: any;
    experience?: Experience;
    user?: any;
    reference_user?: any;
}
export class Reference implements IReference {
    id?: number;
    first_name?: string;
    last_name?: string;
    professional_title?: string;
    email?: string;
    phone?: string;
    address?: string;
    description?: string;
    updated_at?: Date;
    created_at?: Date;
    completed_at?: Date;
    status?: string;
    relationship?: any;
    experience?: Experience;
    user?: any;
    reference_user?: any;

    constructor(
        id?: number,
        first_name?: string,
        last_name?: string,
        professional_title?: string,
        email?: string,
        phone?: string,
        address?: string,
        description?: string,
        updated_at?: Date,
        created_at?: Date,
        completed_at?: Date,
        status?: string,
        relationship?: any,
        experience?: Experience,
        user?: any,
        reference_user?: any
    ) {
        this.id = id;
        this.first_name = first_name;
        this.last_name = last_name;
        this.professional_title = professional_title;
        this.email = email;
        this.phone = phone;
        this.address = address;
        this.description = description;
        this.updated_at = updated_at;
        this.created_at = created_at;
        this.completed_at = completed_at;
        this.status = status;
        this.relationship = relationship;
        this.experience = experience;
        this.user = user;
        this.reference_user = reference_user;
    }
}

@Injectable({ providedIn: 'root' })
export class ReferenceAdapter implements Adapter<Reference> {
    constructor(
        private _injector: Injector,
        private _experienceAdapter: ExperienceAdapter
    ) {}
    adapt(item: any): Reference {
        return new Reference(
            item.id,
            item.first_name,
            item.last_name,
            item.professional_title,
            item.email,
            item.phone,
            item.address,
            item.description,
            item.updated_at ? dayjs(item.updated_at).toDate() : null,
            item.created_at ? dayjs(item.created_at).toDate() : null,
            item.completed_at ? dayjs(item.completed_at).toDate() : null,
            item.status,
            item.relationship,
            item.experience
                ? this._experienceAdapter.adapt(item.experience)
                : null,
            item.user ? this._injector.get(UserAdapter).adapt(item.user) : item.user_light ? this._injector.get(UserAdapter).adapt(item.user_light) : null,
            item.reference_user
                ? this._injector.get(UserAdapter).adapt(item.reference_user)
                : null
        );
    }
}
