/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { environment } from 'environments/environment';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'home',
        title: 'PUBLIC.NAVIGATION_ITEM.HOME.TITLE',
        tooltip: 'Home',
        type: 'basic',
        link: '/home',
        icon: 'mimicons_outline:home',
        children: []
    },
    {
        id: 'mall-unauthenticated',
        title: 'PUBLIC.NAVIGATION_ITEM.MALL.TITLE',
        tooltip: 'Mall',
        type: 'basic',
        link: '/mall',
        icon: 'mimicons_outline:jobz',
        children: []
    },
    {
        id: 'jobs',
        title: 'PUBLIC.NAVIGATION_ITEM.JOBS.TITLE',
        tooltip: 'Jobs',
        type: 'basic',
        link: '/jobs',
        icon: 'mimicons_outline:case',
        children: []
    },
    {
        id: 'videoresume',
        title: 'PUBLIC.NAVIGATION_ITEM.VIDEO_RESUME_STUDIO.TITLE',
        tooltip: 'Video Resume Studio',
        type: 'basic',
        link: '/video-resume-studio/questionnaire',
        icon: 'mimicons_outline:video',
        children: []
    },


    {
        id: 'resumes',
        title: 'My Resumes',
        classes: {
            icon: 'fa fal fa-pen-nib'
        },
        tooltip: 'My Resumes',
        type: 'basic',
        link: '/my-resumes'
    },

    {
        id: 'genius',
        title: 'PUBLIC.NAVIGATION_ITEM.GENIUS.TITLE',
        tooltip: 'Career Coaching',
        type: 'basic',
        link: '/genius',
        icon: 'mimicons_outline:user-group',
        children: []
    },

    {
        id: 'meetings',
        title: 'PUBLIC.NAVIGATION_ITEM.CALENDAR.TITLE',
        tooltip: 'Calendar',
        type: 'basic',
        icon: 'mimicons_outline:calendar',
        link: '/calendar'
    },

    {
        id: 'more',
        title: 'PUBLIC.NAVIGATION_ITEM.MORE.TITLE',
        tooltip: 'More',
        type: 'aside',
        icon: 'mimicons_outline:grid-prominent',
        children: [

            {
                id: 'more.applications',
                title: 'My Applications',
                icon: 'mimicons_outline:check-box',
                type: 'basic',
                link: '/jobs/applications'
            },
            {
                id: 'more.saved-jobs',
                title: 'Saved Jobs',
                icon: 'mimicons_outline:bookmark',
                type: 'basic',
                link: '/jobs/saved'
            },
            {
                id: 'more.organizations',
                title: 'PUBLIC.NAVIGATION_ITEM.ORGANIZATIONS.TITLE',
                type: 'basic',
                link: '/organizations',
                // icon: 'mimicons_outline:cards',
                classes: {
                    icon: 'fa fal fa-compass'
                },
                children: []
            },
            {
                id: 'more.genome-guest',
                title: 'Career Assessment',
                icon: 'mimicons_outline:document',
                type: 'basic',
                link: '/genome'
            },
            {
                id: 'more.genome-authenticated',
                title: 'Career Assessment',
                icon: 'mimicons_outline:document',
                type: 'basic',
                link: '/genome/questionnaire/1'
            },
            {
                id: 'more.videoresume',
                title: 'PUBLIC.NAVIGATION_ITEM.VIDEO_RESUME_STUDIO.TITLE',
                type: 'basic',
                link: '/video-resume-studio/questionnaire',
                icon: 'mimicons_outline:video',
                children: []
            },
            // {
            //     id: 'more.academy',
            //     title: 'PUBLIC.NAVIGATION_ITEM.ACADEMY.TITLE',
            //     type: 'basic',
            //     link: '/academy',
            //     icon: 'heroicons_outline:academic-cap',
            //     children: []
            //  },

            // {
            //     id: 'more.boards',
            //     title: 'Job Boards',
            //     // tooltip: 'Job Boards',
            //     type: 'basic',
            //     link: '/boards',
            //     icon: 'mimicons_outline:copy',
            //     children: []
            // },
            {
                id: 'more.settings',
                title: 'Settings',
                icon: 'mimicons_outline:options',
                type: 'basic',
                link: '/user/settings'
            },
            {
                id: 'more.help-center',
                title: 'Help Center',
                icon: 'mimicons_outline:book',
                type: 'basic',
                link: '/help'
            },
            {
                id: 'more.talent-solutions',
                title: 'For Employers',
                icon: 'mimicons_outline:cup',
                type: 'basic',
                externalLink: true,
                link: environment.webURL + '/hire'
            },
        ]
    }
];
