<ng-container *transloco="let t">
    <header class="relative top-0 left-0 z-50 w-full lg:overflow-y-visible" [ngClass]="{'text-white bg-transparent dark:bg-transparent': transparent, 'shadow dark:shadow-none bg-card dark:border-b dark:bg-gray-900':
        !transparent}">
        <!--Desktop Header-->
        <div class="hidden px-4 mx-auto md:block sm:px-6 lg:px-8">
            <div class="relative flex justify-between md:gap-8">
                <div class="flex space-x-5 md:left-0 md:inset-y-0 lg:static xl:col-span-1"
                    [class.opacity-0]="!showLogo">
                    <div class="flex items-center flex-shrink-0 py-4">
                        <app-logo [ngClass]="{'ml-3': absolute}" [forceWhite]="transparent" class="flex w-auto h-10">
                        </app-logo>
                    </div>
                    <hr *ngIf="showLanguageSwitcher" class="bg-gray-800 w-[2px] h-[20px]">
                    <div *ngIf="showLanguageSwitcher" class="flex items-center flex-shrink-0 py-4">
                        <language></language>
                    </div>
                </div>
                <div class="justify-start flex-1 hidden w-full min-w-0 md:flex xl:col-span-1"
                    [ngClass]="{'justify-start': !showLogo, 'justify-center': showLogo}">
                    <div class="flex items-center w-full py-4 md:max-w-3xl md:mx-auto lg:mx-0 xl:px-0">
                        <omni-search [transparent]="transparent" [neumorphic]="false" *ngIf="showOmni"
                            class="w-full">
                        </omni-search>
                    </div>
                </div>

                <div class="flex items-center justify-end pl-2 ml-auto space-x-2 xl:col-span-1" [ngClass]="{'hidden':
                    (isAuthenticated$ | async)}">
                    <flipper [flipperProps]="{flipKey: currentIndex, spring: 'noWobble'}">
                        <nav class="hidden space-x-10 md:flex">
                            <div (mouseleave)="onMouseLeave()" class="hidden space-x-10 lg:flex">
                                <div *ngFor="let panel of panels; let i = index" class="relative">
                                    <button (mouseenter)="onMouseEnter(i)" type="button" [ngClass]="{'text-gray-500 dark:hover:text-gray-200 hover:text-gray-900': !transparent,
                                    'text-gray-400 hover:text-gray-200': transparent}"
                                        class="inline-flex items-center py-2 text-base font-medium rounded-md group focus:outline-none"
                                        aria-expanded="false">
                                        <span>{{t(panel.name)}}</span>
                                        <svg class="w-5 h-5 ml-2 text-gray-500 group-hover:text-gray-500 dark:group-hover:text-gray-200"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                            aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </button>

                                    <div class="absolute top-0 right-0" style="perspective:1500px"
                                        *ngIf="i === 0 && currentIndex === 0 || (animatingOut && prevIndex === 0)"
                                        @inOutAnimation>
                                        <div class="relative flex flex-col top-[56px]">
                                            <div class="absolute z-1 top-[-7px] right-[76px]" flipped [flippedProps]="{flipId: 'dropdown-caret', scale: true, translate:true, opacity:
                                            true}">
                                                <div class="w-[15px] h-[15px] bg-white relative"
                                                    style="transform: rotate(45deg);"></div>
                                            </div>
                                            <div flipped
                                                [flippedProps]="{flipId: 'dropdown', scale: true, translate:true, opacity: true}"
                                                style="transform-origin: 0 0;"
                                                class="relative flex flex-col items-center will-change-transform header-panel-wrapper">
                                                <career-seekers-header-panel (onClosePanel)="closePanel()">
                                                </career-seekers-header-panel>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="absolute right-0" style="perspective:1500px"
                                        *ngIf="i === 1 && currentIndex === 1 || (animatingOut && prevIndex === 1)"
                                        @inOutAnimation>
                                        <div class="relative flex flex-col top-[14px]">
                                            <div class="absolute z-1 top-[-7px] right-[65px]" flipped [flippedProps]="{flipId: 'dropdown-caret', scale: true, translate:true, opacity:
                                            true}">
                                                <div class="w-[15px] h-[15px] bg-white relative"
                                                    style="transform: rotate(45deg);"></div>
                                            </div>
                                            <div flipped
                                                [flippedProps]="{flipId: 'dropdown', scale: true, translate:true, opacity: true}"
                                                style="transform-origin: 0 0;"
                                                class="relative flex flex-col items-center will-change-transform header-panel-wrapper">
                                                <employers-header-panel (onClosePanel)="closePanel()">
                                                </employers-header-panel>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="relative">

                                    <button (mouseleave)="mouseLeaveHandler($event)" (mouseover)="openRecruitersPanel()"
                                        #recruitersOrigin type="button" [ngClass]="{'text-gray-500 dark:hover:text-gray-200 hover:text-gray-900': !transparent,
                                    'text-gray-400 hover:text-gray-200': transparent}"
                                        class="inline-flex items-center py-2 text-base font-medium rounded-md group focus:outline-none"
                                        aria-expanded="false">
                                        <span>{{t('PUBLIC.HEADER.PANEL.RECRUITERS.TITLE')}}</span>

                                        <svg class="w-5 h-5 ml-2 text-gray-500 group-hover:text-gray-500 dark:group-hover:text-gray-200"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                            aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </button>

                                </div> -->
                            </div>





                            <a [routerLink]="['/login']" [ngClass]="{'text-gray-500 dark:hover:text-gray-200 hover:text-gray-900': !transparent,
                            'text-gray-400 hover:text-gray-200': transparent}"
                                class="py-2 font-medium cursor-pointer ">
                                {{t('PUBLIC.SHARED.ACTION.SIGN_IN')}}
                            </a>
                            <a [routerLink]="['/signup']"
                                class="items-center hidden px-4 py-2 mr-3 font-medium text-white bg-purple-600 border border-transparent rounded-md shadow-lg shadow-purple-600/50 md:inline-flex hover:bg-purple-700 focus:outline-none">
                                {{t('PUBLIC.SHARED.ACTION.SIGN_UP')}}
                            </a>
                        </nav>
                    </flipper>
                </div>

                <div class="flex items-center justify-end pl-2 ml-auto space-x-2 xl:col-span-1" [ngClass]="{'hidden':
                    !(isAuthenticated$ | async)}">
                    <flipper class="hidden mr-2 lg:block" [flipperProps]="{flipKey: currentIndex, spring: 'noWobble'}">
                        <div (mouseleave)="onMouseLeave()" class="flex items-center space-x-3">
                            <div *ngFor="let panel of panelsAuthenticated; let i = index" class="relative">
                                <ng-container *ngIf="(user$| async)?.is_hiring && (organizations$ | async).length > 0">
                                    <button (mouseenter)="onMouseEnter(i)" *ngIf="(user$| async)?.is_hiring"
                                        type="button" [ngClass]="{'text-gray-500 dark:hover:text-gray-200 hover:text-gray-900': !transparent,
                                'text-gray-200 hover:text-white bg-black bg-opacity-40 backdrop-blur': transparent}"
                                        class="inline-flex items-center py-2 pl-4 pr-2 text-base font-medium border rounded-md group focus:outline-none"
                                        aria-expanded="false">
                                        <span>My Organizations</span>

                                        <svg class="w-5 h-5 ml-2 text-gray-500 group-hover:text-gray-500 dark:group-hover:text-gray-200"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                            aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </button>
                                    <div class="absolute top-0 right-0" style="perspective:1500px"
                                        *ngIf="i === 0 && currentIndex === 0 || (animatingOut && prevIndex === 0)"
                                        @inOutAnimation>
                                        <div class="relative flex flex-col top-[56px]">
                                            <div class="absolute z-1 top-[-7px] right-[76px]" flipped [flippedProps]="{flipId: 'dropdown-caret', scale: true, translate:true, opacity:
                                            true}">
                                                <div class="w-[15px] h-[15px] bg-white relative"
                                                    style="transform: rotate(45deg);"></div>
                                            </div>
                                            <div flipped
                                                [flippedProps]="{flipId: 'dropdown', scale: true, translate:true, opacity: true}"
                                                style="transform-origin: 0 0;"
                                                class="relative flex flex-col items-center will-change-transform header-panel-wrapper">
                                                <organizations-header-panel (onClosePanel)="closePanel()">
                                                </organizations-header-panel>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container
                                    *ngIf="(user$| async)?.is_hiring && (organizations$ | async).length === 0">
                                    <button [routerLink]="['/post-job']" type="button" [ngClass]="{'text-gray-500 dark:hover:text-gray-200 hover:text-gray-900': !transparent,
                                'text-gray-200 hover:text-white bg-black bg-opacity-40 backdrop-blur': transparent}"
                                        class="items-center hidden px-4 py-2 text-base font-medium border rounded-md md:inline-flex group focus:outline-none"
                                        aria-expanded="false">
                                        Post your first job
                                    </button>
                                </ng-container>
                                <ng-container *ngIf="!(user$| async)?.is_hiring && !(user$| async)?.is_coaching">
                                    <button *ngIf="user" (mouseenter)="onMouseEnter(i)" type="button" [ngClass]="{'text-gray-500 dark:hover:text-gray-200 hover:text-gray-900': !transparent,
                                'text-gray-200 hover:text-white bg-black bg-opacity-40 backdrop-blur border-gray-600': transparent}"
                                        class="items-center hidden py-2 pl-4 pr-2 text-base font-medium border rounded-md md:inline-flex group focus:outline-none"
                                        aria-expanded="false">
                                        <ng-container [ngSwitch]="user.privacy">
                                            <ng-container *ngSwitchCase="false">
                                                <mat-icon class="mr-2 text-green-500 icon-size-5"
                                                    [svgIcon]="'mimicons_outline:brightness-up'"></mat-icon>
                                                <span class="dark:text-gray-300">Maximum Visibility</span>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="true">
                                                <mat-icon class="mr-2 text-red-500 dark:text-red-400 icon-size-5"
                                                    [svgIcon]="'mimicons_outline:brightness-down'"></mat-icon>
                                                <span class="dark:text-gray-300">Privacy Focused</span>
                                            </ng-container>
                                        </ng-container>


                                        <svg class="w-5 h-5 ml-2 text-gray-500 group-hover:text-gray-500 dark:group-hover:text-gray-200"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                            aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </button>
                                    <div class="absolute top-0 right-0" style="perspective:1500px"
                                        *ngIf="i === 0 && currentIndex === 0 || (animatingOut && prevIndex === 0)"
                                        @inOutAnimation>
                                        <div class="relative flex flex-col top-[56px]">
                                            <div class="absolute z-1 top-[-7px] right-[76px]" flipped [flippedProps]="{flipId: 'dropdown-caret', scale: true, translate:true, opacity:
                                            true}">
                                                <div class="w-[15px] h-[15px] bg-white relative"
                                                    style="transform: rotate(45deg);"></div>
                                            </div>
                                            <div flipped
                                                [flippedProps]="{flipId: 'dropdown', scale: true, translate:true, opacity: true}"
                                                style="transform-origin: 0 0;"
                                                class="relative flex flex-col items-center will-change-transform header-panel-wrapper">
                                                <privacy-header-panel
                                                    (onSave)="onJobSeekerStatusChanged($event)"
                                                    (onClosePanel)="closePanel()">
                                                </privacy-header-panel>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>

                            <button *ngIf="(user$| async)?.is_coaching" [routerLink]="['/coach/dashboard']" [ngClass]="{'text-gray-500 dark:hover:text-gray-200 hover:text-gray-900': !transparent,
                                'text-gray-200 hover:text-white bg-black bg-opacity-40 backdrop-blur': transparent}"
                                class="items-center hidden px-4 py-2 mr-3 font-medium border rounded-md shadow-sm md:inline-flex ">
                                Coach Dashboard
                            </button>
                        </div>
                    </flipper>

                    <messages-badge [ngClass]="{'text-white': transparent}"></messages-badge>
                    <notifications [ngClass]="{'text-white': transparent}" class="hidden md:block"></notifications>
                    <user-menu></user-menu>
                </div>
            </div>
        </div>
        <!--Mobile Header-->
        <div class="block py-2 mx-auto md:hidden">
            <div class="relative flex justify-between lg:gap-8">
                <div class="flex pl-6 space-x-5 md:left-0 md:inset-y-0">
                    <div class="flex items-center flex-shrink-0 py-2">
                        <app-logo [ngClass]="{'ml-3': absolute}" [forceWhite]="transparent" class="flex w-auto h-8">
                        </app-logo>
                    </div>
                    <div class="flex items-center flex-shrink-0 w-8 py-2"></div>
                </div>
                <div class="flex justify-center flex-1 w-full">
                    <div class="flex items-center justify-center w-full py-2">
                        <div class="flex items-center flex-shrink-0">

                        </div>
                    </div>
                </div>
                <div class="flex items-center justify-start pr-2 ml-auto space-x-2">
                    <mobile-omni [transparent]="transparent" [ngClass]="{'text-white' : transparent}" appearance="bar">
                    </mobile-omni>
                    <user-menu *ngIf="isAuthenticated$ | async" [ngClass]="{'text-white': transparent}">
                    </user-menu>
                </div>
            </div>

        </div>
        <mat-progress-bar *ngIf="(appLoading$ | async)" class="absolute bottom-0 left-0 w-full"
            [mode]="'indeterminate'">
        </mat-progress-bar>

    </header>
</ng-container>


<ng-template #organizationsPanel>
    <organizations-header-panel (onClosePanel)="closePanel()">
    </organizations-header-panel>
</ng-template>


<ng-template #careerSeekersPanel>
    <career-seekers-header-panel (onClosePanel)="closePanel()"></career-seekers-header-panel>
</ng-template>

<ng-template #recruitersPanel>
    <employers-header-panel (onClosePanel)="closePanel()"></employers-header-panel>
</ng-template>
