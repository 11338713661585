import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { JMAutoplayDirective } from './autoplay.directive';
import { BackgroundColorDirective } from './background-color.directive';
import { BlurHashDirective } from './blurhash.directive';
import { ForceHttpsDirective } from './force-https.directive';
import { HorizontalScrollDirective } from './horizontal-scroll.directive';
import { IfChangesDirective } from './if-changes.directive';
import { WatchInnerHtmlDirective } from './inner-html-rendered.directive';
import { ReadMoreMarkdownDirective } from './read-more-markdown.directive';
import { ReadMoreDirective } from './read-more.directive';
import { RouterLinkNewTabDirective } from './routerlink-new-tab.directive';
import { VimeoValidatorDirective } from './vimeo-validator.directive';

const COMPONENTS = [
    BlurHashDirective,
    RouterLinkNewTabDirective,
    BackgroundColorDirective,
    IfChangesDirective,
    VimeoValidatorDirective,
    WatchInnerHtmlDirective,
    ReadMoreDirective,
    ForceHttpsDirective,
    JMAutoplayDirective,
    HorizontalScrollDirective,
    ReadMoreMarkdownDirective
];

@NgModule({
    declarations: COMPONENTS,
    imports: [CommonModule],
    exports: COMPONENTS
})
export class JMCommonDirectivesModule {}
