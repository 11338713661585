import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnInit,
    Optional,
    ViewEncapsulation
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fuseAnimations } from '@fuse/animations/public-api';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { makeLongDialogOverlay, makeResponsiveDialogOverlay } from '@jobzmall/core';
import { Restangular } from '@jobzmall/core/api';
import { CertificationDialogComponent } from '@jobzmall/profile/components/certification-dialog/certification-dialog.component';
import { Store } from '@ngxs/store';
import { first, map } from 'rxjs/operators';

@Component({
    selector: 'report-dialog',
    templateUrl: './report-dialog.component.html',
    styleUrls: ['./report-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})
export class ReportDialogComponent extends JMBaseComponent implements OnInit {
    type: string;
    entity: any;
    reason: string;
    reasonControl = new UntypedFormControl();
    additionalDetails: string;

    existingReport: boolean;
    loadingExistingReport: boolean;

    constructor(
        private _store: Store,
        private _snackbar: MatSnackBar,
        private _api: Restangular,
        private _changeDetectorRef: ChangeDetectorRef,
        public dialogRef: MatDialogRef<CertificationDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        super();

        makeResponsiveDialogOverlay(this.dialogRef);
        makeLongDialogOverlay(this.dialogRef);
    }

    ngOnInit(): void {
        this.entity = this.data.entity;
        this.type = this.data.type;
        this.check();
    }

    check() {
        if (!this.loadingExistingReport) {
            this.loadingExistingReport = true;
            this._changeDetectorRef.markForCheck();
            this._api.all(`report/check/${this.type}/${this.entity.id}`).
            customGET()
            .pipe(first(),map((res: any) => res.data))
            .subscribe((existingReport: boolean)=>{
                this.loadingExistingReport = false;
                this.existingReport = existingReport;
                this._changeDetectorRef.markForCheck();
            })
        }
    }

    submit() {
        if (!this.reason) {
            return;
        }
        let payload = this.reason
        if (this.additionalDetails) {
            payload = payload + ', ' + this.additionalDetails;
        }

        this._api
            .all(`${this.type}/${this.entity.id}/report`)
            .customPOST({
                reason: payload
            })
            .pipe(
                first(),
                map((res: any) => res.data)
            )
            .subscribe(() => {
                this._snackbar.open(`Reported successfully`, 'OK', {
                    duration: 3000,
                    panelClass: ['jobz-snackbar']
                });
                this.dialogRef.close(true);
            });
    }
}
