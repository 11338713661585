<div class="flex flex-col w-full min-h-fill flex-auto bg-card dark:bg-default">
    <header
        class="relative rounded-bl-[60px] bg-gradient-to-r from-purple-500 to-purple-900 transform flex items-center mb-8 py-8 px-6">
        <div [routerLink]="['/@' + (user$ | async)?.slug]" (click)="bottomSheetRef.dismiss()"
            class="flex flex-shrink-0 w-20 h-20 rounded-full ring-4 ring-opacity-50 ring-white">
            <ng-container *ngIf="(user$ | async)?.image">
                <img class="object-scale-down w-full h-full rounded-full" [src]="(user$ | async)?.image.full_path"
                    [alt]="'Profile avatar'">
            </ng-container>
            <ng-container *ngIf="!(user$ | async)?.image">
                <div
                    class="flex items-center justify-center w-full h-full overflow-hidden text-2xl font-semibold text-center text-gray-600 uppercase bg-gray-200 rounded-full group-hover:bg-gray-400 dark:group-hover:bg-gray-900 dark:bg-gray-700 dark:text-gray-200">
                    {{(user$ | async)?.name.charAt(0)}}
                </div>
            </ng-container>
        </div>
        <div class="ml-5">
            <h1 [routerLink]="['/@' + (user$ | async)?.slug]" (click)="bottomSheetRef.dismiss()"
                class="text-white tracking-wide text-lg">{{(user$ | async)?.name}}</h1>
            <p class="text-gray-200 tracking-wider text-md">{{(user$ | async)?.professional_title}}</p>
        </div>
        <button class="text-white absolute top-5 right-5" mat-icon-button (click)="bottomSheetRef.dismiss()"
            [tabIndex]="-1">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </header>
    <div class="flex flex-col w-full">
        <div class="w-full overflow-y-hidden overflow-x-auto">
            <div class="flex items-center justify-center">

            </div>
        </div>

        <ul role="list"
            class="px-4 divide-y divide-gray-900/5  border-gray-900/5 dark:divide-gray-300/10 dark:border-gray-300/10">
            <li class="relative flex gap-x-6 py-6">
                <div
                    class="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-gray-900/10 dark:ring-gray-300/10">


                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                        class="h-6 w-6 text-purple-600 dark:text-purple-300">
                        <path fill-rule="evenodd"
                            d="M18.685 19.097A9.723 9.723 0 0 0 21.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 0 0 3.065 7.097A9.716 9.716 0 0 0 12 21.75a9.716 9.716 0 0 0 6.685-2.653Zm-12.54-1.285A7.486 7.486 0 0 1 12 15a7.486 7.486 0 0 1 5.855 2.812A8.224 8.224 0 0 1 12 20.25a8.224 8.224 0 0 1-5.855-2.438ZM15.75 9a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                            clip-rule="evenodd" />
                    </svg>

                </div>
                <div class="flex-auto flex items-center">
                    <h3 class=" font-semibold leading-6 ">
                        <a [routerLink]="['/@' + (user$ | async)?.slug]" (click)="bottomSheetRef.dismiss()">
                            <span class="absolute inset-0" aria-hidden="true"></span>
                            My Profile
                        </a>
                    </h3>

                </div>
                <div class="flex-none self-center">
                    <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                            clip-rule="evenodd" />
                    </svg>
                </div>
            </li>
            <li class="relative flex gap-x-6 py-6">
                <div
                    class="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-gray-900/10 dark:ring-gray-300/10">

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                        class="h-6 w-6 text-purple-600 dark:text-purple-300">
                        <path
                            d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 0 0-1.032-.211 50.89 50.89 0 0 0-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 0 0 2.433 3.984L7.28 21.53A.75.75 0 0 1 6 21v-4.03a48.527 48.527 0 0 1-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979Z" />
                        <path
                            d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 0 0 1.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0 0 15.75 7.5Z" />
                    </svg>

                </div>
                <div class="flex-auto flex items-center">
                    <h3 class=" font-semibold leading-6 ">
                        <a [routerLink]="['/messages']" (click)="bottomSheetRef.dismiss()">
                            <span class="absolute inset-0" aria-hidden="true"></span>
                            My Inbox
                        </a>
                    </h3>
                </div>
                <div class="flex-none self-center">
                    <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                            clip-rule="evenodd" />
                    </svg>
                </div>
            </li>
            <li class="relative flex gap-x-6 py-6">
                <div
                    class="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-gray-900/10 dark:ring-gray-300/10">

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                        class="h-6 w-6 text-purple-600 dark:text-purple-300">
                        <path
                            d="M18.75 12.75h1.5a.75.75 0 0 0 0-1.5h-1.5a.75.75 0 0 0 0 1.5ZM12 6a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5h-7.5A.75.75 0 0 1 12 6ZM12 18a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5h-7.5A.75.75 0 0 1 12 18ZM3.75 6.75h1.5a.75.75 0 1 0 0-1.5h-1.5a.75.75 0 0 0 0 1.5ZM5.25 18.75h-1.5a.75.75 0 0 1 0-1.5h1.5a.75.75 0 0 1 0 1.5ZM3 12a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5h-7.5A.75.75 0 0 1 3 12ZM9 3.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM12.75 12a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0ZM9 15.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z" />
                    </svg>


                </div>
                <div class="flex-auto flex items-center">
                    <h3 class=" font-semibold leading-6 ">
                        <a [routerLink]="['/user/settings']" (click)="bottomSheetRef.dismiss()">
                            <span class="absolute inset-0" aria-hidden="true"></span>
                            Settings
                        </a>
                    </h3>
                </div>
                <div class="flex-none self-center">
                    <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                            clip-rule="evenodd" />
                    </svg>
                </div>
            </li>
            <li class="relative flex gap-x-6 py-6">
                <div
                    class="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-gray-900/10 dark:ring-gray-300/10">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                        class="h-6 w-6 text-purple-600 dark:text-purple-300">
                        <path fill-rule="evenodd"
                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 0 1-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 0 1-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 0 1-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584ZM12 18a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                            clip-rule="evenodd" />
                    </svg>
                </div>
                <div class="flex-auto flex items-center">
                    <h3 class=" font-semibold leading-6 ">
                        <a [routerLink]="['/help']" (click)="bottomSheetRef.dismiss()">
                            <span class="absolute inset-0" aria-hidden="true"></span>
                            Help & Support
                        </a>
                    </h3>
                </div>
                <div class="flex-none self-center">
                    <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                            clip-rule="evenodd" />
                    </svg>
                </div>
            </li>

        </ul>

        <div class="text-2xl px-4 mt-6 mb-3 font-extrabold">
            <span class="text-transform-gradient font-medium">
                Explore JobzMall
            </span>
        </div>

        <ul role="list"
            class="px-4 divide-y border-b divide-gray-900/5  border-gray-900/5 dark:divide-gray-300/10 dark:border-gray-300/10">
            <!-- The rest -->
            <li class="relative flex gap-x-6 py-6">
                <div
                    class="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-gray-900/10 dark:ring-gray-300/10">

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                        class="h-6 w-6 text-purple-600 dark:text-purple-300">
                        <path fill-rule="evenodd"
                            d="M7.5 5.25a3 3 0 0 1 3-3h3a3 3 0 0 1 3 3v.205c.933.085 1.857.197 2.774.334 1.454.218 2.476 1.483 2.476 2.917v3.033c0 1.211-.734 2.352-1.936 2.752A24.726 24.726 0 0 1 12 15.75c-2.73 0-5.357-.442-7.814-1.259-1.202-.4-1.936-1.541-1.936-2.752V8.706c0-1.434 1.022-2.7 2.476-2.917A48.814 48.814 0 0 1 7.5 5.455V5.25Zm7.5 0v.09a49.488 49.488 0 0 0-6 0v-.09a1.5 1.5 0 0 1 1.5-1.5h3a1.5 1.5 0 0 1 1.5 1.5Zm-3 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                            clip-rule="evenodd" />
                        <path
                            d="M3 18.4v-2.796a4.3 4.3 0 0 0 .713.31A26.226 26.226 0 0 0 12 17.25c2.892 0 5.68-.468 8.287-1.335.252-.084.49-.189.713-.311V18.4c0 1.452-1.047 2.728-2.523 2.923-2.12.282-4.282.427-6.477.427a49.19 49.19 0 0 1-6.477-.427C4.047 21.128 3 19.852 3 18.4Z" />
                    </svg>
                </div>
                <div class="flex-auto">
                    <h3 class=" font-semibold leading-6 ">
                        <a [routerLink]="['/jobs']" (click)="bottomSheetRef.dismiss()">
                            <span class="absolute inset-0" aria-hidden="true"></span>
                            Find your next job
                        </a>
                    </h3>
                    <p class="mt-2  leading-6 text-secondary"> Discover exciting opportunities tailored for you
                    </p>
                </div>
                <div class="flex-none self-center">
                    <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                            clip-rule="evenodd" />
                    </svg>
                </div>
            </li>
            <li class="relative flex gap-x-6 py-6">
                <div
                    class="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-gray-900/10 dark:ring-gray-300/10">
                    <svg class="h-6 w-6 text-purple-600 dark:text-purple-300" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24" fill="currentColor">
                        <path
                            d="M4.5 4.5a3 3 0 0 0-3 3v9a3 3 0 0 0 3 3h8.25a3 3 0 0 0 3-3v-9a3 3 0 0 0-3-3H4.5ZM19.94 18.75l-2.69-2.69V7.94l2.69-2.69c.944-.945 2.56-.276 2.56 1.06v11.38c0 1.336-1.616 2.005-2.56 1.06Z" />
                    </svg>

                </div>
                <div class="flex-auto">
                    <h3 class=" font-semibold leading-6 ">
                        <a [routerLink]="['/video-resume-studio/questionnaire']" (click)="bottomSheetRef.dismiss()">
                            <span class="absolute inset-0" aria-hidden="true"></span>
                            Create your Video Resume
                        </a>
                    </h3>
                    <p class="mt-2  leading-6 text-secondary">Stand out with a dynamic video introduction </p>
                </div>
                <div class="flex-none self-center">
                    <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                            clip-rule="evenodd" />
                    </svg>
                </div>
            </li>
            <li class="relative flex gap-x-6 py-6">
                <div
                    class="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-gray-900/10 dark:ring-gray-300/10">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                        class="h-6 w-6 text-purple-600 dark:text-purple-300">
                        <path fill-rule="evenodd"
                            d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z"
                            clip-rule="evenodd" />
                    </svg>
                </div>
                <div class="flex-auto">
                    <h3 class=" font-semibold leading-6 ">
                        <a [routerLink]="['/jobs/applications']" (click)="bottomSheetRef.dismiss()">
                            <span class="absolute inset-0" aria-hidden="true"></span>
                            Track Your Applications
                        </a>
                    </h3>
                    <p class="mt-2  leading-6 text-secondary">Check the status of your applications</p>
                </div>
                <div class="flex-none self-center">
                    <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                            clip-rule="evenodd" />
                    </svg>
                </div>
            </li>
            <li class="relative flex gap-x-6 py-6">
                <div
                    class="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-gray-900/10 dark:ring-gray-300/10">

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                        class="h-6 w-6 text-purple-600 dark:text-purple-300">
                        <path fill-rule="evenodd"
                            d="M9 1.5H5.625c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5Zm6.61 10.936a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 14.47a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                            clip-rule="evenodd" />
                        <path
                            d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                    </svg>

                </div>
                <div class="flex-auto">
                    <h3 class=" font-semibold leading-6 ">
                        <a [routerLink]="['/my-resumes']" (click)="bottomSheetRef.dismiss()">
                            <span class="absolute inset-0" aria-hidden="true"></span>
                            Manage My Resumes
                        </a>
                    </h3>
                    <p class="mt-2  leading-6 text-secondary">Manage your resumes and receive feedback from resume
                        experts
                    </p>
                </div>
                <div class="flex-none self-center">
                    <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                            clip-rule="evenodd" />
                    </svg>
                </div>
            </li>
            <li class="relative flex gap-x-6 py-6">
                <div
                    class="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-gray-900/10 dark:ring-gray-300/10">

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                        class="h-6 w-6 text-purple-600 dark:text-purple-300">
                        <path
                            d="M4.5 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM14.25 8.625a3.375 3.375 0 1 1 6.75 0 3.375 3.375 0 0 1-6.75 0ZM1.5 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM17.25 19.128l-.001.144a2.25 2.25 0 0 1-.233.96 10.088 10.088 0 0 0 5.06-1.01.75.75 0 0 0 .42-.643 4.875 4.875 0 0 0-6.957-4.611 8.586 8.586 0 0 1 1.71 5.157v.003Z" />
                    </svg>

                </div>
                <div class="flex-auto">
                    <h3 class=" font-semibold leading-6 ">
                        <a [routerLink]="['/genius']" (click)="bottomSheetRef.dismiss()">
                            <span class="absolute inset-0" aria-hidden="true"></span>
                            Find a Career Coach
                        </a>
                    </h3>
                    <p class="mt-2  leading-6 text-secondary">Gain personalized guidance for your career path</p>
                </div>
                <div class="flex-none self-center">
                    <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                            clip-rule="evenodd" />
                    </svg>
                </div>
            </li>
            <li class="relative flex gap-x-6 py-6">
                <div
                    class="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-gray-900/10 dark:ring-gray-300/10">

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                        class="h-6 w-6 text-purple-600 dark:text-purple-300">
                        <path fill-rule="evenodd"
                            d="M3 2.25a.75.75 0 0 0 0 1.5v16.5h-.75a.75.75 0 0 0 0 1.5H15v-18a.75.75 0 0 0 0-1.5H3ZM6.75 19.5v-2.25a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75v2.25a.75.75 0 0 1-.75.75h-3a.75.75 0 0 1-.75-.75ZM6 6.75A.75.75 0 0 1 6.75 6h.75a.75.75 0 0 1 0 1.5h-.75A.75.75 0 0 1 6 6.75ZM6.75 9a.75.75 0 0 0 0 1.5h.75a.75.75 0 0 0 0-1.5h-.75ZM6 12.75a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 0 1.5h-.75a.75.75 0 0 1-.75-.75ZM10.5 6a.75.75 0 0 0 0 1.5h.75a.75.75 0 0 0 0-1.5h-.75Zm-.75 3.75A.75.75 0 0 1 10.5 9h.75a.75.75 0 0 1 0 1.5h-.75a.75.75 0 0 1-.75-.75ZM10.5 12a.75.75 0 0 0 0 1.5h.75a.75.75 0 0 0 0-1.5h-.75ZM16.5 6.75v15h5.25a.75.75 0 0 0 0-1.5H21v-12a.75.75 0 0 0 0-1.5h-4.5Zm1.5 4.5a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75h-.008a.75.75 0 0 1-.75-.75v-.008Zm.75 2.25a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75v-.008a.75.75 0 0 0-.75-.75h-.008ZM18 17.25a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75h-.008a.75.75 0 0 1-.75-.75v-.008Z"
                            clip-rule="evenodd" />
                    </svg>


                </div>
                <div class="flex-auto">
                    <h3 class=" font-semibold leading-6 ">
                        <a [routerLink]="['/organizations']" (click)="bottomSheetRef.dismiss()">
                            <span class="absolute inset-0" aria-hidden="true"></span>
                            Discover Organizations
                        </a>
                    </h3>
                    <p class="mt-2  leading-6 text-secondary">Find organizations that match your skills and interests
                    </p>
                </div>
                <div class="flex-none self-center">
                    <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                            clip-rule="evenodd" />
                    </svg>
                </div>
            </li>
        </ul>

    </div>
</div>
