import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap, mergeMap, catchError } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { Login, Logout, SetToken, SignUp, SignUpFailure, SocialLogin } from './actions';
import { AuthStateModel } from './model';
import { RefreshUser } from '../../../user/ngxs/actions';
import { UserService } from '@jobzmall/user/user.service';
import { of, Observable } from 'rxjs';

@State<AuthStateModel>({
    name: 'auth',
    defaults: {
        token: null
    }
})
@Injectable()
export class AuthState {
    @Selector()
    static token(state: AuthStateModel): string | null {
        return state.token;
    }

    @Selector()
    static isAuthenticated(state: AuthStateModel): boolean {
        return !!state.token;
    }

    constructor(
        private _authService: AuthService,
        private _userService: UserService
    ) {}

    @Action(Login)
    login(ctx: StateContext<AuthStateModel>, action: Login) {
        return this._authService.login(action.payload).pipe(
            tap((data: { access_token: string }) => {
                ctx.dispatch(new SetToken(data.access_token));
            }),
            mergeMap(() => {
                return ctx.dispatch(new RefreshUser());
            })
        );
    }

    @Action(SetToken)
    setToken(ctx: StateContext<AuthStateModel>, action: SetToken) {
        ctx.patchState({
            token: action.token
        });
        return ctx.dispatch(new RefreshUser());
    }

    @Action(SocialLogin)
    socialLogin(
        ctx: StateContext<AuthStateModel>,
        action: SocialLogin
    ): Observable<any> {
        if (action.payload.access_token) {
            return this._authService
                .socialLogin(
                    action.payload.provider,
                    action.payload.access_token
                )
                .pipe(
                    tap((data: { access_token: string }) => {
                        ctx.dispatch(new SetToken(data.access_token));
                    }),
                    mergeMap(() => {
                        return ctx.dispatch(new RefreshUser());
                    })
                );
        } else {
            return this._authService.socialAuth(action.payload.provider).pipe(
                mergeMap((data: any) => {
                    if (data) {
                        return this._authService
                            .socialLogin(
                                action.payload.provider,
                                data.access_token
                            )
                            .pipe(
                                tap((data: { access_token: string }) => {
                                   ctx.dispatch(
                                       new SetToken(data.access_token)
                                   );
                                }),
                                mergeMap(() => {
                                    return ctx.dispatch(new RefreshUser());
                                })
                            );
                    } else {
                        return of(undefined);
                    }
                })
            );
        }
    }

    @Action(Logout)
    logout(ctx: StateContext<AuthStateModel>) {
        const state = ctx.getState();
        return this._authService.logout().pipe(
            catchError((err: any, caught: any) => {
                return of(undefined);
            }),
            tap(() => {
                ctx.dispatch(new SetToken(undefined));
            }),
            mergeMap(() => {
                return ctx.dispatch(new RefreshUser());
            })
        );
    }

    @Action(SignUp)
    signUp(ctx: StateContext<AuthStateModel>, action: SignUp) {
        return this._userService.signUp(action.type, action.data).pipe(
            tap((data: { access_token: string }) => {
                ctx.dispatch(new SetToken(data.access_token));
            }),
            mergeMap(() => {
                return ctx.dispatch(new RefreshUser());
            }),
            catchError((err: any)=>{
                return ctx.dispatch(new SignUpFailure(err))
            })
        );
    }

    @Action(SignUpFailure)
    signUpFailure(ctx: StateContext<AuthStateModel>, action: SignUpFailure) {
        return of(action.error);
    }
}
