import { enableProdMode, Injector } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from 'environments/environment';
import { bootloader } from '@angularclass/bootloader';
import { AppModule } from './app/app.module';
import { jbzInjector } from '@jobzmall/injector/injector';

if (environment.production) {
    enableProdMode();
}

const main = () =>
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .then((appRef: { injector: Injector }) => jbzInjector(appRef.injector))
        .catch((err) => console.error(err));

bootloader(main);
