import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Output,
    EventEmitter,
    Input
} from '@angular/core';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { fuseAnimations } from '@fuse/animations/public-api';
import { BehaviorSubject, Subject, debounceTime, pairwise } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SearchFacet } from '@jobzmall/models';
import { waitForGlobal } from '@jobzmall/core';
import { FormControl, UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'search-location',
    templateUrl: './search-location.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})
export class SearchLocationComponent extends JMBaseComponent implements OnInit {
    algoliaConfigValue: any;
    @Output() algoliaConfigChange = new EventEmitter<any>();
    hasTyped: boolean;
    isFocused: boolean;
    @Input()
    get algoliaConfig() {
        return this.algoliaConfigValue;
    }
    set algoliaConfig(val) {
        this.algoliaConfigValue = val;
        this.algoliaConfigChange.emit(this.algoliaConfigValue);
    }

    @Input() transparent: boolean = true;

    @Output() locationChanged: EventEmitter<any> = new EventEmitter();


    formControl = new UntypedFormControl({});

    results: Array<google.maps.places.AutocompletePrediction> = [];

    query: string;

    private autocompleteService: google.maps.places.AutocompleteService;
    private placesService: google.maps.places.PlacesService;
    private searchSubject: Subject<string> = new Subject();
    private resultSubject: BehaviorSubject<
        google.maps.places.AutocompletePrediction[]
    > = new BehaviorSubject([]);

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {
        waitForGlobal('google.maps', () => {
            this.autocompleteService =
                new google.maps.places.AutocompleteService();
            this.placesService = new google.maps.places.PlacesService(
                document.createElement('div')
            );
            this.hookSearch();
            this.hookResults();
            this.hookChange();
            this.loadFromParams();
        });
    }

    loadFromParams() {
        if (this._activatedRoute.snapshot.queryParams.location) {
            this.query = this._activatedRoute.snapshot.queryParams.location;
            if (this.query.toLowerCase() == 'remote') {
                this.algoliaConfig['extra']['aroundLatLng'] = undefined;
                this.algoliaConfig['extra']['aroundLatLngViaIP'] = false;
                this.algoliaConfig['extra']['filters'] = this.algoliaConfig[
                    'extra'
                ]['filters'].replace(` AND ${SearchFacet.REMOTE}:false`, '');
                this.algoliaConfig['extra'][
                    'filters'
                ] += ` AND ${SearchFacet.REMOTE}:true`;
                this.algoliaConfig['extra']['page'] = 0;
            } else {
                let obj = { input: this.query, types: ['(cities)'] };
                this.autocompleteService.getPlacePredictions(
                    obj,
                    (
                        result: google.maps.places.AutocompletePrediction[],
                        status: google.maps.places.PlacesServiceStatus
                    ) => {
                        if (
                            status != google.maps.places.PlacesServiceStatus.OK
                        ) {
                            return;
                        }
                        this.placesService.getDetails(
                            result[0] as any,
                            (
                                result: google.maps.places.PlaceResult,
                                status: google.maps.places.PlacesServiceStatus
                            ) => {
                                this.algoliaConfig['extra'][
                                    'aroundLatLng'
                                ] = `${result.geometry.location.lat()}, ${result.geometry.location.lng()}`;
                                this.algoliaConfig['extra'][
                                    'aroundRadius'
                                ] = 100000;
                                this.algoliaConfig['extra']['page'] = 0;
                            }
                        );
                    }
                );
            }
        }
    }

    hookResults() {
        this.resultSubject.subscribe((val) => {
            this.results = val.slice(0, 10);
        });
    }

    clearLocation() {
        this.query = '';
        this.algoliaConfig['extra']['aroundLatLngViaIP'] = false;
        this.algoliaConfig['extra']['aroundLatLng'] = undefined;
        this.algoliaConfig['extra']['filters'] = this.algoliaConfig['extra'][
            'filters'
        ].replace(` AND ${SearchFacet.REMOTE}:true`, '');
        this.algoliaConfig['extra']['filters'] = this.algoliaConfig['extra'][
            'filters'
        ].replace(` AND ${SearchFacet.REMOTE}:false`, '');
        this.algoliaConfig['extra']['page'] = 0;
    }

    onSelected($event: any) {
        if ($event.option.value.place_id) {
            setTimeout(() => {
                this.query = $event.option.value.description;
                this._changeDetectorRef.markForCheck();
            });
            this.algoliaConfig['extra']['aroundLatLngViaIP'] = false;
            this.placesService.getDetails(
                $event.option.value,
                (
                    result: google.maps.places.PlaceResult,
                    status: google.maps.places.PlacesServiceStatus
                ) => {
                    this.algoliaConfig['extra'][
                        'aroundLatLng'
                    ] = `${result.geometry.location.lat()}, ${result.geometry.location.lng()}`;
                    this.algoliaConfig['extra']['aroundRadius'] = 100000;
                    this.algoliaConfig['extra']['page'] = 0;
                    this.locationChanged.emit(result);
                }
            );
        } else {
            switch ($event.option.value) {
                case 'current':
                    this.query = 'Current Location';
                    this.algoliaConfig['extra']['aroundLatLng'] = undefined;
                    this.algoliaConfig['extra']['aroundLatLngViaIP'] = true;
                    this.algoliaConfig['extra']['filters'] = this.algoliaConfig[
                        'extra'
                    ]['filters'].replace(` AND ${SearchFacet.REMOTE}:true`, '');
                    this.algoliaConfig['extra'][
                        'filters'
                    ] += ` AND ${SearchFacet.REMOTE}:false`;
                    this.algoliaConfig['extra']['page'] = 0;
                    this.algoliaConfig['extra']['aroundRadius'] = 100000;
                    break;
                case 'everywhere':
                    this.query = 'Everywhere';
                    this.algoliaConfig['extra']['aroundLatLng'] = undefined;
                    this.algoliaConfig['extra']['aroundLatLngViaIP'] = false;
                    this.algoliaConfig['extra']['filters'] = this.algoliaConfig[
                        'extra'
                    ]['filters'].replace(` AND ${SearchFacet.REMOTE}:true`, '');
                    this.algoliaConfig['extra']['filters'] = this.algoliaConfig[
                        'extra'
                    ]['filters'].replace(
                        ` AND ${SearchFacet.REMOTE}:false`,
                        ''
                    );
                    this.algoliaConfig['extra']['page'] = 0;
                    break;
                case 'remote':
                    this.query = 'Remote';
                    this.algoliaConfig['extra']['aroundLatLng'] = undefined;
                    this.algoliaConfig['extra']['aroundLatLngViaIP'] = false;
                    this.algoliaConfig['extra']['filters'] = this.algoliaConfig[
                        'extra'
                    ]['filters'].replace(
                        ` AND ${SearchFacet.REMOTE}:false`,
                        ''
                    );
                    this.algoliaConfig['extra'][
                        'filters'
                    ] += ` AND ${SearchFacet.REMOTE}:true`;
                    this.algoliaConfig['extra']['page'] = 0;
                    break;
                default:
                    this.clearLocation();
                    break;
            }
            this.locationChanged.emit($event.option.value);
        }
        // this.updateParams();
    }

    onFocus(event) {
        this.hasTyped = true;
        this.isFocused = true;
    }


    hookChange() {
        this.formControl.valueChanges
            .pipe(pairwise())
            .subscribe(([oldValue, newValue]) => {
                if (oldValue !== newValue) {
                    if (newValue) {
                        this.searchSubject.next(newValue as string);
                    }
                }
            });
    }

    hookSearch() {
        this.searchSubject
            .pipe(debounceTime(700))
            .subscribe((searchTextValue) => {
                this.loading = true;
                if (searchTextValue.length > 3){
                    this.search(searchTextValue);
                }
            });
    }

    search(value: string) {
        if (this.query) {
            let obj = { input: this.query, types: ['(cities)'] };
            this.autocompleteService.getPlacePredictions(
                obj,
                this.collectSuggestions.bind(this)
            );
        } else {
            this.clearLocation();
        }
    }

    collectSuggestions(
        predictions: google.maps.places.AutocompletePrediction[],
        status: google.maps.places.PlacesServiceStatus
    ) {
        if (status != google.maps.places.PlacesServiceStatus.OK) {
            return;
        }
        this.resultSubject.next(predictions);
    }
}
