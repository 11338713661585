import { Observable, of, BehaviorSubject } from 'rxjs';
import algoliasearch from 'algoliasearch/lite';
import { Injectable, Inject } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { ConfigService } from '@jobzmall/config';
import { Restangular } from '@jobzmall/core/api';
import { SearchClient } from 'algoliasearch/lite';
var algoliasearchHelper = require('algoliasearch-helper');
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import {SearchClient as TypesenseNativeSearchClient} from 'typesense'

@Injectable()
export class SearchService {
    constructor(private _config: ConfigService, private _api: Restangular) {}

    public recordSearchHistory(
        query: string = null,
        location: string = null,
        searchable_type = null,
        searchable_id = null
    ) {
        return this._api
            .all(`search/history`)
            .customPOST({
                query,
                location,
                searchable_type,
                searchable_id
            })
            .pipe(
                catchError((err: any, caught: any) => {
                    return of(undefined);
                }),
                map((res: any) => res.data)
            );
    }

    public getSearchHelper(options: any, index: string): Observable<any> {
        let client = algoliasearch(
            this._config.getSettings('data.algolia.app_id'),
            this._config.getSettings('data.algolia.app_key')
        );
        let helper = algoliasearchHelper(client, index, options);
        return of(helper);
    }

    public getStoreSecuredSearchClient(
        slug: string,
        options: any
    ): Observable<SearchClient> {
        return this._api
            .all(`store/${slug}/search/key`)
            .customGET(undefined, options)
            .pipe(
                map((res: any) => res.data),
                map((key: any) => {
                    let client = algoliasearch(
                        this._config.getSettings('data.algolia.app_id'),
                        key
                    );
                    return client;
                })
            );
    }

    public getStoreSecuredTypesenseSearchClient(
        slug: string,
        options: any
    ): Observable<SearchClient> {
        return this._api
            .all(`store/${slug}/search/t/key`)
            .customGET(undefined, options)
            .pipe(
                map((res: any) => res.data),
                map((data: any) => {
                    const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
                        server: {
                          apiKey: data.key,
                          nodes: [
                            data.node
                          ],
                        },
                        additionalSearchParameters: {
                          query_by: data.query_by,
                        },
                      });
                      return typesenseInstantsearchAdapter.searchClient;
                })
            );
    }

    public getSecuredTypesenseSearchClient(payload: any): Observable<SearchClient> {
        return this._api
            .all('search/t/key')
            .customPOST(payload)
            .pipe(
                map((res: any) => res.data),
                map((data: any) => {
                    const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
                        server: {
                          apiKey: data.key,
                          nodes: [
                            data.node
                          ],
                        },
                        additionalSearchParameters: {
                          query_by: data.query_by,
                        },
                      });
                      return typesenseInstantsearchAdapter.searchClient;
                })
            );
    }

    public getSecuredNativeTypesenseSearchClient(payload: any): Observable<TypesenseNativeSearchClient> {
        return this._api
            .all('search/t/key')
            .customPOST(payload)
            .pipe(
                map((res: any) => res.data),
                map((data: any) => {
                    const client = new TypesenseNativeSearchClient({
                          apiKey: data.key,
                          nodes: [
                            data.node
                          ],
                          nearestNode: data.node,
                      });
                      return client;
                })
            );
    }

    public getSecureTypesenseSearchKey(payload: any): Observable<any> {
        return this._api
            .all('search/t/key')
            .customPOST(payload)
            .pipe(
                map((res: any) => res.data),
                map((data: any) => {
                    return data;
                })
            );
    }

    public getSecuredSearchClient(options: any): Observable<SearchClient> {
        return this._api
            .all('search/key')
            .customGET(undefined, options)
            .pipe(
                map((res: any) => res.data),
                map((key: any) => {
                    let client = algoliasearch(
                        this._config.getSettings('data.algolia.app_id'),
                        key
                    );
                    return client;
                })
            );
    }

    public getSecureSearchKey(options: any): Observable<any> {
        return this._api
            .all('search/key')
            .customGET(undefined, options)
            .pipe(
                map((res: any) => res.data),
                map((key: any) => {
                    return key;
                })
            );
    }

    public getSecuredSearchHelper(options: any): Observable<any> {
        return this._api
            .all('search/key')
            .customGET(undefined, options)
            .pipe(
                map((res: any) => res.data),
                map((key: any) => {
                    let client = algoliasearch(
                        this._config.getSettings('data.algolia.app_id'),
                        key
                    );
                    let helper = algoliasearchHelper(client, options.indices);
                    return helper;
                })
            );
    }
}
