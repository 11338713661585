import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import {
    firstValueFrom,
    Subject,
    first,
    Observable,
    combineLatest
} from 'rxjs';
import { fuseAnimations } from '@fuse/animations/public-api';
import { autocomplete } from '@algolia/autocomplete-js';
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia';
import { JMBaseComponent } from '@jobzmall/components';
import { TranslocoService } from '@ngneat/transloco';
import { nanoid } from 'nanoid';
import { Router } from '@angular/router';
import { ConfigService } from '@jobzmall/config';
import { SearchService } from '@jobzmall/search/search.service';
import algoliasearch from 'algoliasearch/lite';
import { Select } from '@ngxs/store';
import { AuthState } from '@jobzmall/core';

@Component({
    selector: 'mobile-omni',
    templateUrl: './mobile-omni.component.html',
    encapsulation: ViewEncapsulation.None,
    exportAs: 'fuseSearch',
    animations: fuseAnimations
})
export class MobileOmniComponent
    extends JMBaseComponent
    implements OnChanges, OnInit, OnDestroy
{
    @Input() appearance: 'basic' | 'bar' = 'basic';
    @Input() debounce: number = 300;
    @Input() minLength: number = 2;
    @Output() search: EventEmitter<any> = new EventEmitter<any>();
    @Input() transparent = false;

    acomplete: any;
    algoliaClient: any;
    indexes: Object;
    suggestionPressed: boolean = false;
    id = nanoid();

    opened: boolean = false;
    results: any[];
    searchControl: UntypedFormControl = new UntypedFormControl();
    private _unsubscribeAll: Subject<void> = new Subject<void>();

    @Select(AuthState.isAuthenticated) isAuthenticated$: Observable<boolean>;

    authenticated: boolean;

    /**
     * Constructor
     */
    constructor(
        private _searchService: SearchService,
        private _config: ConfigService,
        private _router: Router,
        private _transloco: TranslocoService,
        private _elementRef: ElementRef,
        private _httpClient: HttpClient,
        private _renderer2: Renderer2
    ) {
        super();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Host binding for component classes
     */
    @HostBinding('class') get classList(): any {
        return {
            'search-appearance-bar': this.appearance === 'bar',
            'search-appearance-basic': this.appearance === 'basic',
            'search-opened': this.opened
        };
    }

    /**
     * Setter for bar search input
     *
     * @param value
     */
    @ViewChild('barSearchInput')
    set barSearchInput(value: ElementRef) {
        // If the value exists, it means that the search input
        // is now in the DOM and we can focus on the input..
        if (value) {
            // Give Angular time to complete the change detection cycle
            setTimeout(() => {
                // Focus to the input element
                value.nativeElement.focus();
            });
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On changes
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        // Appearance
        if ('appearance' in changes) {
            // To prevent any issues, close the
            // search after changing the appearance
            this.close();
        }
    }

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the search field value changes
        this.subscriptions.sink = combineLatest([
            this.isAuthenticated$,
            this._transloco.langChanges$
        ]).subscribe(([authenticated, lang]) => {
            this.authenticated = authenticated;
            setTimeout(() => {
                this._transloco
                    .selectTranslation()
                    .pipe(first())
                    .subscribe(() => {
                        this.initAutocomplete();
                    });
            });
        });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this._transloco
                .selectTranslation()
                .pipe(first())
                .subscribe(() => {
                    this.initAutocomplete();
                });
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        super.ngOnDestroy();
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    initAutocomplete() {
        if (this.acomplete) {
            this.acomplete.destroy();
        }
        const searchClient = algoliasearch(
            this._config.getSettings('data.algolia.app_id'),
            this._config.getSettings('data.algolia.app_key')
        );
        if (!this._elementRef.nativeElement.querySelector(`#omni-${this.id}`)) {
            return;
        }

        this.acomplete = autocomplete({
            placeholder: 'Search JobzMall',
            detachedMediaQuery: '',
            container: `#omni-${this.id}`,
            openOnFocus: true,
            autoFocus: true,
            onSubmit: ({ state }: { state: any }) => {
                this._router.navigate(['/search/jobs'], {
                    queryParams: { query: state.query }
                });
            },
            getSources: () => {
                return [
                    {
                        sourceId: 'links',
                        getItems: ({ query }) => {
                            return this.authenticated
                                ? [
                                      {
                                          label: 'Discover Jobs',
                                          icon: '<svg class="text-purple-600 dark:text-default h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 6C19.2 6 21 7.32394 21 13.5C21 19.6761 19.2 21 12 21C4.8 21 3 19.6082 3 13.5C3 7.39181 4.8 6 12 6Z" class="stroke-current" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.79736 6C7.52146 3.40115 9.76073 3 12 3C14.2393 3 16.4785 3.40115 16.2026 6" class="stroke-current" stroke-width="1.6" stroke-linejoin="round"/><path d="M3 12.0007C3 12.0007 7.50022 13.0002 12.0001 13C16.5 12.9998 21 12 21 12" class="stroke-current" stroke-width="1.6" stroke-linejoin="round"/><path d="M11.2 15C11.2 15.4418 11.5582 15.8 12 15.8C12.4418 15.8 12.8 15.4418 12.8 15H11.2ZM11.2 13V15H12.8V13H11.2Z" class="fill-current"/></svg>',
                                          colorClass: 'text-default',
                                          description:
                                              'Seamlessly browser available jobs hiring now',
                                          url: '/jobs'
                                      },
                                      {
                                          label: 'My Applications',
                                          icon: '<svg class="text-purple-600 dark:text-default h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M3 12C3 5 5.00092 3 12.0005 3C19 3 21 5 21 12C21 19 18.9231 21 12.0005 21C5.07784 21 3 19 3 12Z" class="stroke-current" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" /> <path d="M15 10.2L11 14.2L9 12.2"  class="stroke-current" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" /></svg>',
                                          colorClass: 'text-default',
                                          description:
                                              'From applied to hired, track all of your applications in one spot',
                                          url: '/jobs/applications'
                                      },
                                      {
                                          label: 'Video Resume Studio',
                                          icon: '<svg class="text-purple-600 dark:text-default h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 5.77778 3.71511 4 10.0004 4C16.2857 4 18 5.71429 18 12C18 18.2857 16.2857 20 10.0004 20C3.71511 20 2 18.2222 2 12Z" class="stroke-current" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" /><path d="M18 10C19 8 20.1739 6.30306 21.2174 7.25255C22.2609 8.20204 22.2609 15.798 21.2174 16.7474C20.1739 17.6969 19 16 18 14" class="stroke-current" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" /></svg>',
                                          colorClass: 'text-default',
                                          description:
                                              'Record your video resume and share your story with the world',
                                          url: '/video-resume-studio/questionnaire'
                                      },
                                      {
                                        label: 'Career Coaching',
                                        icon: '<svg class="text-purple-600 dark:text-default h-6 w-6" viewBox="0 0 24 24" fill="none"><path d="M5 10V17.5C5 19.433 6.567 21 8.5 21C10.433 21 12 19.433 12 17.5V6.5C12 4.567 13.567 3 15.5 3C17.433 3 19 4.567 19 6.5V13" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" /><path fill-rule="evenodd" clip-rule="evenodd" d="M19 16C20.1047 16 21 16.8953 21 18C21 19.1047 20.1047 20 19 20C17.8953 20 17 19.1047 17 18C17 16.8953 17.8953 16 19 16Z" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" /><path d="M7 6L5 4L3 6" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" /></svg>',
                                        colorClass: 'text-default',
                                        description:
                                            'Find and order services from the top coaches on JobzMall',
                                        url: '/genius'
                                    },
                                      {
                                          label: 'My Resumes',
                                          icon: '<svg  class="text-purple-600 dark:text-default h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M20.9999 12C20.9999 5.00001 18.9999 3.00001 12.0004 3.00001C5.00086 3.00001 2.99994 5.00001 2.99994 12C2.99994 19 5.07778 21 12.0004 21M20.9999 12C20.9999 15 14.9999 21 12.0004 21M20.9999 12C16.9999 13 14.9999 12 13.4999 13.5C12 15 12.9999 17 12.0004 21" class="stroke-current" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" /></svg>',
                                          colorClass: 'text-default',
                                          description:
                                              'Manage your resumes and receive feedback from resume experts with a click of a button',
                                          url: '/my-resumes'
                                      },
                                      {
                                          label: 'Organizations',
                                          icon: '<svg class="text-purple-600 dark:text-default h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z" /></svg>',
                                          colorClass: 'text-default',
                                          description:
                                              "Expand your horizons and discover the world's most exciting organizations",
                                          url: '/organizations'
                                      },
                                      {
                                          label: 'Job Boards',
                                          icon: '<svg class="text-purple-600 dark:text-default h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M16.5 8.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v8.25A2.25 2.25 0 006 16.5h2.25m8.25-8.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-7.5A2.25 2.25 0 018.25 18v-1.5m8.25-8.25h-6a2.25 2.25 0 00-2.25 2.25v6" /></svg>',
                                          colorClass: 'text-default',
                                          description:
                                              'Discover the best job opportunities tailored to your skills and experience with curated job boards',
                                          url: '/boards'
                                      }
                                  ].filter(({ label }) =>
                                      label
                                          .toLowerCase()
                                          .includes(query.toLowerCase())
                                  )
                                : [
                                      {
                                          label: 'Discover Jobs',
                                          icon: '<svg class="text-purple-600 dark:text-default h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 6C19.2 6 21 7.32394 21 13.5C21 19.6761 19.2 21 12 21C4.8 21 3 19.6082 3 13.5C3 7.39181 4.8 6 12 6Z" class="stroke-current" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.79736 6C7.52146 3.40115 9.76073 3 12 3C14.2393 3 16.4785 3.40115 16.2026 6" class="stroke-current" stroke-width="1.6" stroke-linejoin="round"/><path d="M3 12.0007C3 12.0007 7.50022 13.0002 12.0001 13C16.5 12.9998 21 12 21 12" class="stroke-current" stroke-width="1.6" stroke-linejoin="round"/><path d="M11.2 15C11.2 15.4418 11.5582 15.8 12 15.8C12.4418 15.8 12.8 15.4418 12.8 15H11.2ZM11.2 13V15H12.8V13H11.2Z" class="fill-current"/></svg>',
                                          colorClass: 'text-default',
                                          description:
                                              'Seamlessly browser available jobs hiring now',
                                          url: '/jobs'
                                      },
                                      {
                                          label: 'Video Resume Studio',
                                          icon: '<svg class="text-purple-600 dark:text-default h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 5.77778 3.71511 4 10.0004 4C16.2857 4 18 5.71429 18 12C18 18.2857 16.2857 20 10.0004 20C3.71511 20 2 18.2222 2 12Z" class="stroke-current" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" /><path d="M18 10C19 8 20.1739 6.30306 21.2174 7.25255C22.2609 8.20204 22.2609 15.798 21.2174 16.7474C20.1739 17.6969 19 16 18 14" class="stroke-current" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" /></svg>',
                                          colorClass: 'text-default',
                                          description:
                                              'Record your video resume and share your story with the world',
                                          url: '/video-resume-studio/questionnaire'
                                      },
                                      {
                                        label: 'Career Coaching',
                                        icon: '<svg class="text-purple-600 dark:text-default h-6 w-6" viewBox="0 0 24 24" fill="none"><path d="M5 10V17.5C5 19.433 6.567 21 8.5 21C10.433 21 12 19.433 12 17.5V6.5C12 4.567 13.567 3 15.5 3C17.433 3 19 4.567 19 6.5V13" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" /><path fill-rule="evenodd" clip-rule="evenodd" d="M19 16C20.1047 16 21 16.8953 21 18C21 19.1047 20.1047 20 19 20C17.8953 20 17 19.1047 17 18C17 16.8953 17.8953 16 19 16Z" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" /><path d="M7 6L5 4L3 6" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" /></svg>',
                                        colorClass: 'text-default',
                                        description:
                                            'Find and order services from the top coaches on JobzMall',
                                        url: '/genius'
                                    },
                                      {
                                          label: 'Organizations',
                                          icon: '<svg class="text-purple-600 dark:text-default h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z" /></svg>',
                                          colorClass: 'text-default',
                                          description:
                                              "Expand your horizons and discover the world's most exciting organizations",
                                          url: '/organizations'
                                      },
                                      {
                                          label: 'Job Boards',
                                          icon: '<svg class="text-purple-600 dark:text-default h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M16.5 8.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v8.25A2.25 2.25 0 006 16.5h2.25m8.25-8.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-7.5A2.25 2.25 0 018.25 18v-1.5m8.25-8.25h-6a2.25 2.25 0 00-2.25 2.25v6" /></svg>',
                                          colorClass: 'text-default',
                                          description:
                                              'Discover the best job opportunities tailored to your skills and experience with curated job boards',
                                          url: '/boards'
                                      }
                                  ].filter(({ label }) =>
                                      label
                                          .toLowerCase()
                                          .includes(query.toLowerCase())
                                  );
                        },
                        onSelect: ({ item }: { item: any }) => {
                            this._router.navigate([item.url]);
                        },
                        templates: {
                            header({
                                createElement
                            }: {
                                createElement: Function;
                            }) {
                                return createElement('div', {
                                    dangerouslySetInnerHTML: {
                                        __html: `
                                        <h2 class="bg-gray-100 dark:bg-gray-900 dark:text-white py-2.5 px-4 text-md font-semibold text-gray-900">Quick Actions</h2>
                                    `
                                    }
                                });
                            },
                            item({
                                item,
                                createElement
                            }: {
                                item: any;
                                createElement: Function;
                            }) {
                                return createElement('div', {
                                    dangerouslySetInnerHTML: {
                                        __html: `
                                        <div class="py-3 px-2 flex flex-row items-center">
                                             <div
                                                class="flex items-center justify-center flex-shrink-0 w-10 h-10 ${item.colorClass} rounded-md sm:h-12 sm:w-12">
                                                ${item.icon}
                                            </div>

                                            <div class="ml-2 flex flex-col">
                                                <span class="text-lg font-semibold">${item.label}</span>
                                                <span class="mt-2 text-md text-secondary">${item.description}</span>
                                            </div>
                                        </div>
                                    `
                                    }
                                });
                            }
                        }
                    },
                    {
                        sourceId: 'job_types',
                        onSelect: ({ item }: { item: any }) => {

                            this._router.navigate(['/search/jobs'], {
                                queryParams: {
                                    'refinementList[type][0]': item.name
                                }
                            });
                        },

                        getItems({ query }) {
                            return query
                                ? getAlgoliaResults({
                                      searchClient,
                                      queries: [
                                          {
                                              indexName: 'job_types',
                                              query,
                                              params: {
                                                  hitsPerPage: 1
                                              }
                                          }
                                      ]
                                  })
                                : [];
                        },
                        displayKey: 'name',
                        name: 'job_type',
                        templates: {
                            header({
                                createElement
                            }: {
                                createElement: Function;
                            }) {
                                return createElement('div', {
                                    dangerouslySetInnerHTML: {
                                        __html: `
                                        <h2 class="bg-gray-100 dark:bg-gray-900 dark:text-white py-2.5 px-4 text-md font-semibold text-gray-900">Job Types</h2>
                                    `
                                    }
                                });
                            },
                            item({
                                item,
                                createElement
                            }: {
                                item: any;
                                createElement: Function;
                            }) {
                                return createElement('div', {
                                    dangerouslySetInnerHTML: {
                                        __html: `
                                        <div class="py-2 px-3 flex flex-row items-start">
                                            <i class="fa fa-search icon-size-4 mr-3"></i>
                                            <div class="flex flex-col w-full">
                                                <div class="flex flex-row items-center">
                                                    <span class="text-lg font-semibold">${item.name} Jobs</span>
                                                </div>
                                            </div>
                                        </div>
                             `
                                    }
                                });
                            }
                        }
                    },
                    {
                        sourceId: 'job_templates',
                        onSelect: ({ item }: { item: any }) => {
                            this._router.navigate(['/search/jobs'], {
                                queryParams: { query: item.title }
                            });
                        },
                        getItems({ query }) {
                            return query
                                ? getAlgoliaResults({
                                      searchClient,
                                      queries: [
                                          {
                                              indexName: 'job_templates',
                                              query,
                                              params: {
                                                  hitsPerPage: 3
                                              }
                                          }
                                      ]
                                  })
                                : [];
                        },
                        displayKey: 'title',
                        name: 'job_templates',
                        templates: {
                            header({
                                createElement
                            }: {
                                createElement: Function;
                            }) {
                                return createElement('div', {
                                    dangerouslySetInnerHTML: {
                                        __html: `
                                        <h2 class="bg-gray-100 dark:bg-gray-900 dark:text-white py-2.5 px-4 text-md font-semibold text-gray-900">Job Titles</h2>
                                    `
                                    }
                                });
                            },
                            item({
                                item,
                                createElement
                            }: {
                                item: any;
                                createElement: Function;
                            }) {
                                return createElement('div', {
                                    dangerouslySetInnerHTML: {
                                        __html: `
                                        <div class="py-2 px-3 flex flex-row items-start">
                                            <i class="fa fa-search icon-size-4 mr-3"></i>
                                            <div class="flex flex-col w-full">
                                                <div class="flex flex-row items-center">
                                                    <span class="text-lg font-semibold">${item.title}</span>
                                                </div>
                                            </div>
                                        </div>
                                    `
                                    }
                                });
                            }
                        }
                    },
                    {
                        sourceId: 'jobs',
                        onSelect: ({ item }: { item: any }) => {
                            this._router.navigate(['/search/jobs'], {
                                queryParams: { query: item.title }
                            });
                        },
                        getItems({ query }) {
                            return query
                                ? getAlgoliaResults({
                                      searchClient,
                                      queries: [
                                          {
                                              indexName: 'jobs',
                                              query,
                                              params: {
                                                  hitsPerPage: 3,
                                                  facetFilters:
                                                      'store.visible:true,status:active'
                                              }
                                          }
                                      ]
                                  })
                                : [];
                        },
                        displayKey: 'title',
                        name: 'jobs',
                        templates: {
                            header({
                                createElement
                            }: {
                                createElement: Function;
                            }) {
                                return createElement('div', {
                                    dangerouslySetInnerHTML: {
                                        __html: `
                                        <h2 class="bg-gray-100 dark:bg-gray-900 dark:text-white py-2.5 px-4 text-md font-semibold text-gray-900">Popular Jobs</h2>
                                    `
                                    }
                                });
                            },
                            item({
                                item,
                                createElement
                            }: {
                                item: any;
                                createElement: Function;
                            }) {
                                return createElement('div', {
                                    dangerouslySetInnerHTML: {
                                        __html: `
                                         <div class="py-2 px-3 flex flex-row items-start">
                                            <i class="fa fa-search icon-size-4 mr-3"></i>
                                            <div class="flex flex-col w-full">
                                                <div class="flex flex-row items-center">
                                                    <span class="text-lg font-semibold">${item.title}</span>
                                                </div>
                                                <div class="pt-0.5 text-sm flex flex-row items-center">
                                                    <span>by <b>${item.store.name}</b></span>
                                                </div>
                                            </div>
                                        </div>
                                    `
                                    }
                                });
                            }
                        }
                    },
                    {
                        sourceId: 'boards',
                        onSelect: ({ item }: { item: any }) => {
                            this._router.navigate(['/boards/' + item.slug]);
                        },
                        getItems({ query }) {
                            return query
                                ? getAlgoliaResults({
                                      searchClient,
                                      queries: [
                                          {
                                              indexName: 'boards',
                                              query,
                                              params: {
                                                  hitsPerPage: 3
                                              }
                                          }
                                      ]
                                  })
                                : [];
                        },
                        displayKey: 'name',
                        name: 'boards',
                        templates: {
                            header({
                                createElement
                            }: {
                                createElement: Function;
                            }) {
                                return createElement('div', {
                                    dangerouslySetInnerHTML: {
                                        __html: `
                                        <h2 class="bg-gray-100 dark:bg-gray-900 dark:text-white py-2.5 px-4 text-md font-semibold text-gray-900">Job Boards</h2>
                                    `
                                    }
                                });
                            },
                            item({
                                item,
                                createElement
                            }: {
                                item: any;
                                createElement: Function;
                            }) {
                                if (item.image) {
                                    return createElement('div', {
                                        dangerouslySetInnerHTML: {
                                            __html: `
                                            <div class="py-2 px-3 flex flex-row items-center">
                                                <div class="relative flex flex-shrink-0 mr-3 flex-0 w-12 h-12 overflow-hidden rounded-lg bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                                                        <img src="${item.image.full_path}" class="w-full object-cover h-full rounded-lg"/>
                                                    </div>
                                                <div class="flex flex-col w-full">
                                                    <div class="flex flex-row items-center">
                                                     <span class="text-lg font-semibold">${item.name}</span>
                                                    </div>
                                                   <span class="text-secondary text-md">${item.jobs_count} jobs</span>
                                                </div>
                                            </div>
                                        `
                                        }
                                    });
                                } else {
                                    return createElement('div', {
                                        dangerouslySetInnerHTML: {
                                            __html: `
                                            <div class="py-2 px-3 flex flex-row items-center">
                                                    <div class="relative flex-shrink-0 flex mr-3 flex-0 w-12 h-12 overflow-hidden rounded-lg bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                                                            <div class="group-hover:bg-gray-400 dark:group-hover:bg-gray-900 overflow-hidden text-lg
                                                                                text-center flex items-center justify-center w-full
                                                                                h-full rounded-lg font-semibold uppercase bg-gray-200 text-gray-600 dark:bg-gray-700
                                                                                dark:text-gray-200">
                                                                    ${item.name.charAt(
                                                                        0
                                                                    )}
                                                            </div>
                                                    </div>
                                                    <div class="flex flex-col w-full">
                                                        <div class="flex flex-row items-center">
                                                        <span class="text-lg font-semibold">${
                                                            item.name
                                                        }</span>
                                                        </div>
                                                        <span class="text-secondary text-md">${
                                                            item.jobs_count
                                                        } jobs</span>
                                                    </div>
                                            </div>

                        `
                                        }
                                    });
                                }
                            }
                        }
                    },
                    {
                        sourceId: 'lists',
                        onSelect: ({ item }: { item: any }) => {
                            this._router.navigate(['/lists/' + item.slug]);
                        },
                        getItems({ query }) {
                            return query
                                ? getAlgoliaResults({
                                      searchClient,
                                      queries: [
                                          {
                                              indexName: 'store_lists',
                                              query,
                                              params: {
                                                  hitsPerPage: 3
                                              }
                                          }
                                      ]
                                  })
                                : [];
                        },
                        displayKey: 'name',
                        name: 'store_lists',
                        templates: {
                            header({
                                createElement
                            }: {
                                createElement: Function;
                            }) {
                                return createElement('div', {
                                    dangerouslySetInnerHTML: {
                                        __html: `
                                        <h2 class="bg-gray-100 dark:bg-gray-900 dark:text-white py-2.5 px-4 text-md font-semibold text-gray-900">Lists</h2>
                                    `
                                    }
                                });
                            },
                            item({
                                item,
                                createElement
                            }: {
                                item: any;
                                createElement: Function;
                            }) {
                                if (item.image) {
                                    return createElement('div', {
                                        dangerouslySetInnerHTML: {
                                            __html: `
                                            <div class="py-2 px-3 flex flex-row items-center">
                                                <div class="relative flex flex-shrink-0 mr-3 flex-0 w-12 h-12 overflow-hidden rounded-full bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                                                        <img src="${item.image.full_path}" class="w-full object-cover h-full rounded-full"/>
                                                    </div>
                                                <div class="flex flex-col w-full">
                                                    <div class="flex flex-row items-center">
                                                     <span class="text-lg font-semibold">${item.name}</span>
                                                    </div>
                                                   <span class="text-secondary text-md">${item.stores_count} organizations</span>
                                                </div>
                                            </div>
                                        `
                                        }
                                    });
                                } else {
                                    return createElement('div', {
                                        dangerouslySetInnerHTML: {
                                            __html: `
                                            <div class="py-2 px-3 flex flex-row items-center">
                                                    <div class="relative flex-shrink-0 flex mr-3 flex-0 w-12 h-12 overflow-hidden rounded-full bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                                                            <div class="group-hover:bg-gray-400 dark:group-hover:bg-gray-900 overflow-hidden text-lg
                                                                                text-center flex items-center justify-center w-full
                                                                                h-full rounded-full font-semibold uppercase bg-gray-200 text-gray-600 dark:bg-gray-700
                                                                                dark:text-gray-200">
                                                                    ${item.name.charAt(
                                                                        0
                                                                    )}
                                                            </div>
                                                    </div>
                                                    <div class="flex flex-col w-full">
                                                        <div class="flex flex-row items-center">
                                                        <span class="text-lg font-semibold">${
                                                            item.name
                                                        }</span>
                                                        </div>
                                                        <span class="text-secondary text-md">${
                                                            item.stores_count
                                                        } organizations</span>
                                                    </div>
                                            </div>

                        `
                                        }
                                    });
                                }
                            }
                        }
                    },

                    {
                        sourceId: 'stores',
                        onSelect: function ({ item }: { item: any }) {

                            this._router.navigate(['/'.concat(item.slug)]);
                        }.bind(this),
                        getItems({ query }) {
                            return query
                                ? getAlgoliaResults({
                                      searchClient,
                                      queries: [
                                          {
                                              indexName: 'stores_public',
                                              query,
                                              params: {
                                                  hitsPerPage: 3,
                                                  facetFilters: 'visible:true'
                                              }
                                          }
                                      ]
                                  })
                                : [];
                        },
                        displayKey: 'name',
                        name: 'stores',
                        templates: {
                            header({
                                createElement
                            }: {
                                createElement: Function;
                            }) {
                                return createElement('div', {
                                    dangerouslySetInnerHTML: {
                                        __html: `
                                        <h2 class="bg-gray-100 dark:bg-gray-900 dark:text-white py-2.5 px-4 text-md font-semibold text-gray-900">Organizations</h2>
                                    `
                                    }
                                });
                            },
                            item({
                                item,
                                createElement
                            }: {
                                item: any;
                                createElement: Function;
                            }) {
                                if (item.image) {
                                    return createElement('div', {
                                        dangerouslySetInnerHTML: {
                                            __html: `
                                            <div class="py-2 px-3 flex flex-row items-center">
                                                <div class="relative flex flex-shrink-0 mr-3 flex-0 w-12 h-12 overflow-hidden rounded-full bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                                                        <img src="${item.image.full_path}" class="w-full object-scale-down h-full rounded-full"/>
                                                    </div>
                                                <div class="flex flex-col w-full">
                                                    <div class="flex flex-row items-center">
                                                     <span class="text-lg font-semibold">${item.name}</span>
                                                    </div>
                                                   <span class="text-secondary text-md">${item.jobs_count} jobs</span>
                                                </div>
                                            </div>
                                        `
                                        }
                                    });
                                } else {
                                    return createElement('div', {
                                        dangerouslySetInnerHTML: {
                                            __html: `
                                            <div class="py-2 px-3 flex flex-row items-center">
                                                    <div class="relative flex-shrink-0 flex mr-3 flex-0 w-12 h-12 overflow-hidden rounded-full bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                                                            <div class="group-hover:bg-gray-400 dark:group-hover:bg-gray-900 overflow-hidden text-lg
                                                                                text-center flex items-center justify-center w-full
                                                                                h-full rounded-full font-semibold uppercase bg-gray-200 text-gray-600 dark:bg-gray-700
                                                                                dark:text-gray-200">
                                                                    ${item.name.charAt(
                                                                        0
                                                                    )}
                                                            </div>
                                                    </div>
                                                    <div class="flex flex-col w-full">
                                                        <div class="flex flex-row items-center">
                                                        <span class="text-lg font-semibold">${
                                                            item.name
                                                        }</span>
                                                        </div>
                                                        <span class="text-secondary text-md">${
                                                            item.jobs_count
                                                        } jobs</span>
                                                    </div>
                                            </div>

                        `
                                        }
                                    });
                                }
                            }
                        }
                    }
                ];
            }
        });
    }

    /**
     * On keydown of the search input
     *
     * @param event
     */
    onKeydown(event: KeyboardEvent): void {
        // Listen for escape to close the search
        // if the appearance is 'bar'
        if (this.appearance === 'bar') {
            // Escape
            if (event.code === 'Escape') {
                // Close the search
                this.close();
            }
        }
    }

    /**
     * Open the search
     * Used in 'bar'
     */
    open(): void {
        // Return if it's already opened
        if (this.opened) {
            return;
        }

        // Open the search
        this.opened = true;
        setTimeout(() => {
            this.initAutocomplete();
        });
    }

    /**
     * Close the search
     * * Used in 'bar'
     */
    close(): void {
        // Return if it's already closed
        if (!this.opened) {
            return;
        }

        // Clear the search input
        this.searchControl.setValue('');

        if (this.acomplete) {
            this.acomplete.destroy();
        }
        // Close the search
        this.opened = false;
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }
}
