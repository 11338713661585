import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    HostBinding,
    forwardRef,
    ViewEncapsulation,
    Inject,
    Optional
} from '@angular/core';

import { union } from 'lodash-es';
import * as dayjs from 'dayjs';

import { ConfigService } from '@jobzmall/config';
import { SearchType } from '../search.types';
import {
    NgAisInstantSearch,
    NgAisIndex,
    BaseWidget
} from 'angular-instantsearch';
import { connectCurrentRefinements } from 'instantsearch.js/es/connectors';
import { SearchFacet } from '@jobzmall/models';

@Component({
    selector: 'search-filters',
    styleUrls: ['./search-filters.component.scss'],
    encapsulation: ViewEncapsulation.None,
    templateUrl: './search-filters.component.html'
})
export class SearchFiltersComponent extends BaseWidget implements OnInit {
    @Input() searchInstance: any;
    @Input() type: string;
    @Input() kind: string = 'drawer';
    @Input() showApply = false;
    @Input() @HostBinding('class') class;
    @Input() minimal = false;
    @Input() exclude = [];

    @Output() filtersInitialized: EventEmitter<any> = new EventEmitter();
    @Output() filtersClosed: EventEmitter<any> = new EventEmitter();
    @Output() applied: EventEmitter<any> = new EventEmitter();
    @Output() cleared: EventEmitter<any> = new EventEmitter();

    state: any = {};
    filters: Array<SearchFilter> = [];

    constructor(
        @Inject(forwardRef(() => NgAisIndex))
        @Optional()
        public parentIndex: NgAisIndex,
        @Inject(forwardRef(() => NgAisInstantSearch))
        public instantSearchInstance: NgAisInstantSearch,
        private _config: ConfigService
    ) {
        super('SearchFilters');
    }

    ngOnInit(): void {
        this.createWidget(connectCurrentRefinements, {});
        super.ngOnInit();

        this.initFilters();
    }

    initFilters() {
        switch (this.type) {
            case SearchType.JOBS:
                this.filters = this.jobFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case SearchType.ORGANIZATIONS:
                this.filters = this.storeFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case SearchType.DISCUSSIONS:
                this.filters = this.discussionFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case SearchType.COMMUNITIES:
                this.filters = this.communityFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case SearchType.POSTS:
                this.filters = this.postFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case SearchType.USERS:
                this.filters = this.userFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case SearchType.COURSES:
                this.filters = this.courseFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'resources':
                this.filters = this.resourceFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'genius':
                this.filters = this.geniusFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'talent_pool_applications':
                this.filters = this.talentPoolApplicationFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'store_list_stores':
                this.filters = this.storeListStoreFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'boards':
                this.filters = this.boardFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            case 'board_jobs':
                this.filters = this.boardJobFilters();
                this.filtersInitialized.emit(this.filters);
                break;
                case 'jm_coaches':
                this.filters = this.jmCoachFilters();
                this.filtersInitialized.emit(this.filters);
                break;
            default:
                break;
        }
    }

    closeFilters() {
        this.filtersClosed.emit({});
    }

    boardFilters(): SearchFilter[] {
        return [
            new SearchFilter('numeric', 'Job Count', {
                attribute: SearchFacet.JOB_COUNT,
                operator: 'or',
                items: [
                    { label: 'All' },
                    { start: 0, end: 0, label: 'None' },
                    { start: 1, end: 10, label: '1-10' },
                    { start: 11, end: 50, label: '11-50' },
                    { start: 51, end: 100, label: '51-100' },
                    { start: 101, end: 500, label: '101-500' },
                    { start: 500, label: '500+' }
                ]
            }),
            new SearchFilter('refinement', 'Language', {
                attribute: 'language',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Languages...'
            }),
            new SearchFilter('refinement', 'Country', {
                attribute: 'location.country.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Countries...'
            }),
            new SearchFilter('refinement', 'City', {
                attribute: 'location.city.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Cities...'
            }),
            new SearchFilter('refinement', 'Tags', {
                attribute: 'tags',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Tags...'
            })
        ];
    }

    boardJobFilters(): SearchFilter[] {
        return [
            new SearchFilter('refinement', 'Job Type', {
                attribute: 'job.type',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Experience Level', {
                attribute: 'job.experience',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Organizations', {
                attribute: 'job.store.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Organizations...'
            }),
            new SearchFilter('refinement', 'Remote', {
                attribute: 'job.remote_type',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Skills', {
                attribute: 'job.required_skills',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Skills...'
            }),
            new SearchFilter('refinement', 'Education', {
                attribute: 'job.education',
                operator: 'and'
            }),
            new SearchFilter('refinement', 'Job Function', {
                attribute: 'job.functions',
                operator: 'and'
            })
        ];
    }

    jmCoachFilters(): Array<SearchFilter> {
        const arr = [
            new SearchFilter('refinement', 'Areas of Expertise', {
                attribute: 'tags.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Specialities...'
            })
        ];
        return arr;
    }

    resourceFilters(): Array<SearchFilter> {
        const arr = [
            new SearchFilter('refinement', 'Tags', {
                attribute: 'tags.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Tags...'
            }),
            new SearchFilter('refinement', 'Organizations', {
                attribute: 'store.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Organizations...'
            }),
            new SearchFilter('refinement', 'Languages', {
                attribute: 'language',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Languages...'
            })
        ];
        return arr;
    }

    courseFilters(): Array<SearchFilter> {
        const arr = [
            new SearchFilter('refinement', 'Type', {
                attribute: 'type',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Types...'
            }),
            new SearchFilter('refinement', 'Location', {
                attribute: 'address',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Locations...'
            }),

            new SearchFilter('numeric', 'Price', {
                attribute: 'unit_cost',
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: 0,
                        end: 0,
                        label: 'Free'
                    },
                    {
                        start: 1,
                        end: 250,
                        label: '$1 - $250'
                    },
                    {
                        start: 251,
                        end: 500,
                        label: '$250 - $500'
                    },
                    {
                        start: 500,
                        label: '$500+'
                    }
                ]
            }),
            new SearchFilter('refinement', 'Providers', {
                attribute: 'provider.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Providers...'
            }),
            new SearchFilter('refinement', 'Tags', {
                attribute: 'tags.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Tags...'
            }),
            new SearchFilter('refinement', 'Languages', {
                attribute: 'languages',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Languages...'
            })
        ];
        return arr;
    }

    discussionFilters(): Array<SearchFilter> {
        const arr = [
            new SearchFilter('refinement', 'Tags', {
                attribute: 'tags.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Tags...'
            })
        ];
        return arr;
    }

    geniusFilters(): Array<SearchFilter> {
        const arr = [
            new SearchFilter('refinement', 'Services', {
                attribute: 'coach_services.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Services...'
            }),
            new SearchFilter('numeric', 'Price', {
                attribute: 'coach_services.price',
                operator: 'or',
                items: [
                    { label: 'All' },
                    {
                        start: 0,
                        end: 0,
                        label: 'Free'
                    },
                    {
                        start: 1,
                        end: 250,
                        label: '$1 - $250'
                    },
                    {
                        start: 251,
                        end: 500,
                        label: '$250 - $500'
                    },
                    {
                        start: 500,
                        label: '$500+'
                    }
                ]
            }),
            new SearchFilter('refinement', 'Languages', {
                attribute: 'languages',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Languages...'
            })
        ];
        if (this.exclude.length) {
            this.exclude.forEach((exclude: string) => {
                let i = arr.findIndex((i) => i.args.attribute == exclude);
                if (i > -1) {
                    arr.splice(i, 1);
                }
            });
        }
        return arr;
    }

    userFilters(): Array<SearchFilter> {
        const arr = [
            new SearchFilter('refinement', 'Skills', {
                attribute: 'skills',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Skills...'
            }),
            new SearchFilter('refinement', 'Soft Skills', {
                attribute: 'soft_skills',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Soft Skills...'
            })
        ];
        return arr;
    }

    talentPoolApplicationFilters(): Array<SearchFilter> {
        const arr = [
            new SearchFilter('refinement', 'Skills', {
                attribute: 'user.skills',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Skills...'
            }),
            new SearchFilter('refinement', 'Soft Skills', {
                attribute: 'user.soft_skills',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Soft Skills...'
            })
        ];
        return arr;
    }

    postFilters(): Array<SearchFilter> {
        const arr = [
            new SearchFilter('refinement', 'Tags', {
                attribute: 'tags.name',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Tags...'
            }),
            new SearchFilter('numeric', 'Date Posted', {
                attribute: 'created_at_unix',
                operator: 'and',
                items: [
                    { label: 'All' },
                    {
                        start: dayjs().subtract(1, 'day').unix(),
                        label: 'Today'
                    },
                    {
                        start: dayjs().subtract(1, 'week').unix(),
                        label: 'This Week'
                    },
                    {
                        start: dayjs().subtract(1, 'month').unix(),
                        label: 'This Month'
                    }
                ]
            })
        ];
        return arr;
    }

    communityFilters(): Array<SearchFilter> {
        const arr = [
            new SearchFilter('refinement', 'Community Type', {
                attribute: 'communable_type',
                operator: 'and',
                searchable: true,
                searchPlaceholder: 'Search for Community Types...'
            })
        ];
        return arr;
    }

    storeFilters(): Array<SearchFilter> {
        const orgSizes = union(
            [{ name: 'All' }],
            this._config.getSettings('data.store.sizes') as Array<object>
        ).map((item: any) => {
            return { start: item.id, end: item.id, label: item.name };
        });
        const arr = [
            new SearchFilter('numeric', 'Organization Size', {
                attribute: 'store_size_id',
                operator: 'or',
                items: orgSizes
            }),
            new SearchFilter('refinement', 'Industry', {
                attribute: 'industry.name',
                searchable: true,
                operator: 'and'
            })
        ];
        return arr;
    }

    storeListStoreFilters(): Array<SearchFilter> {
        const orgSizes = union(
            [{ name: 'All' }],
            this._config.getSettings('data.store.sizes') as Array<object>
        ).map((item: any) => {
            return { start: item.id, end: item.id, label: item.name };
        });
        const arr = [
            new SearchFilter('numeric', 'Organization Size', {
                attribute: 'store.store_size_id',
                operator: 'or',
                items: orgSizes
            }),
            new SearchFilter('refinement', 'Industry', {
                attribute: 'store.industry.name',
                searchable: true,
                operator: 'and'
            })
        ];
        return arr;
    }

    jobFilters(): Array<SearchFilter> {
        let arr = this.minimal
            ? [
                  new SearchFilter('refinement', 'Job Type', {
                      attribute: 'type',
                      operator: 'and'
                  }),
                  new SearchFilter('refinement', 'Experience Level', {
                      attribute: 'experience',
                      operator: 'and'
                  }),
                  new SearchFilter('refinement', 'Organizations', {
                      attribute: SearchFacet.STORE_NAME,
                      operator: 'and',
                      searchable: true,
                      searchPlaceholder: 'Search for Organizations...'
                  }),
                  new SearchFilter('refinement', 'Remote', {
                      attribute: 'remote_type',
                      operator: 'and'
                  }),
                  new SearchFilter('refinement', 'Skills', {
                      attribute: 'required_skills',
                      operator: 'and',
                      searchable: true,
                      searchPlaceholder: 'Search for Skills...'
                  }),
                  new SearchFilter('refinement', 'Education', {
                      attribute: 'education',
                      operator: 'and'
                  }),
                  new SearchFilter('refinement', 'Job Function', {
                      attribute: 'functions',
                      operator: 'and'
                  })
              ]
            : [
                  new SearchFilter('numeric', 'Date Posted', {
                      attribute: 'created_at_unix',
                      operator: 'and',
                      items: [
                          { label: 'All' },
                          {
                              start: dayjs().subtract(1, 'day').unix(),
                              label: 'Today'
                          },
                          {
                              start: dayjs().subtract(1, 'week').unix(),
                              label: 'This Week'
                          },
                          {
                              start: dayjs().subtract(1, 'month').unix(),
                              label: 'This Month'
                          }
                      ]
                  }),
                  new SearchFilter('refinement', 'Job Type', {
                      attribute: 'type',
                      operator: 'and'
                  }),
                  new SearchFilter('refinement', 'Experience Level', {
                      attribute: 'experience',
                      operator: 'and'
                  }),
                  new SearchFilter('refinement', 'Organizations', {
                      attribute: 'store.name',
                      operator: 'and',
                      searchable: true,
                      searchPlaceholder: 'Search for Organizations...'
                  }),
                  new SearchFilter('refinement', 'Remote', {
                      attribute: 'remote_type',
                      operator: 'and'
                  }),
                  new SearchFilter('refinement', 'Skills', {
                      attribute: 'required_skills',
                      operator: 'and',
                      searchable: true,
                      searchPlaceholder: 'Search for Skills...'
                  }),
                  new SearchFilter('refinement', 'Job Function', {
                      attribute: 'functions',
                      operator: 'and'
                  }),
                  new SearchFilter('refinement', 'Visa Support', {
                      attribute: 'visa_explanation',
                      operator: 'and'
                  })
              ];
        if (this.exclude.length) {
            this.exclude.forEach((exclude: string) => {
                let i = arr.findIndex((i) => i.args.attribute == exclude);
                if (i > -1) {
                    arr.splice(i, 1);
                }
            });
        }
        return arr;
    }
}

export class SearchFilter {
    constructor(public type: string, public name: string, public args?: any) {}
}
