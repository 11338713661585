<ng-container *transloco="let t">
    <div class="w-full mx-auto max-w-100 sm:w-100 sm:mx-0">
        <!-- Logo -->
        <app-logo *ngIf="showLogo" class="flex items-center justify-center w-auto h-15"></app-logo>

        <ng-container *ngIf="!user?.email_verified_at">
            <div class="w-full mt-8 text-4xl font-extrabold leading-tight tracking-tight text-center">
                Email Verification
            </div>
            <p class="text-center mt-4 mb-5 font-small">
                Before updating your phone number, we require that you verify your email first.
            </p>
            <button (click)="sendVerificationEmail()" type="button" class="w-full mt-6 jbz-mat-button-large" mat-flat-button color="primary">Send Verification Email</button>
        </ng-container>

        <ng-container *ngIf="user?.email_verified_at">
            <!-- Title -->
            <div class="w-full mt-8 text-4xl font-extrabold leading-tight tracking-tight text-center">
                {{t('PUBLIC.PHONE_UPDATE_FORM.TITLE') }}
            </div>
            <p class="text-center mt-0.5 mb-5 font-small">
                {{t('PUBLIC.PHONE_UPDATE_FORM.SUBTITLE')}}
            </p>
            <form [formGroup]="form" autocomplete="off" (ngSubmit)="confirm()">
                <div class="flex flex-col">
                    <mat-form-field class="w-full" fxFlex>
                        <mat-label>{{t('PUBLIC.SIGNUP.FORM.LABEL.PHONE')}}</mat-label>
                        <ngx-intl-tel-input [separateDialCode]="true" [(ngModel)]="phone" [required]="true" class="mb-1"
                            [preferredCountries]="[CountryISO.UnitedStates, CountryISO.Canada,
              CountryISO.India, CountryISO.France,
              CountryISO.UnitedKingdom, CountryISO.Turkey]" [excludeCountries]="[CountryISO.Iran,
              CountryISO.Afghanistan, CountryISO.NorthKorea, CountryISO.Syria,
              CountryISO.Russia]" [enableAutoCountrySelect]="false" [enablePlaceholder]="true"
                            [searchCountryFlag]="true"
                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                            [selectFirstCountry]="false" [selectedCountryISO]="selectedCountryISO" maxLength="15"
                            [phoneValidation]="true" inputId="phone" name="phone" formControlName="phone">
                        </ngx-intl-tel-input>
                        <mat-error *ngIf="form.controls.phone.errors?.required">
                            {{t('PUBLIC.SHARED.FORM.ERROR.REQUIRED')}}</mat-error>
                        <mat-error *ngIf="form.controls.phone.errors?.validatePhoneNumber">Invalid Number</mat-error>
                    </mat-form-field>
                </div>
                <div class="flex flex-col">
                    <button class="w-full mt-6 jbz-mat-button-large" mat-flat-button [color]="'primary'"
                        [disabled]="form.invalid || loading" type="submit">
                        <span *ngIf="!loading">
                            {{t('PUBLIC.PHONE_UPDATE_FORM.SUBMIT')}}
                        </span>
                        <mat-progress-spinner *ngIf="loading" [diameter]="24" [mode]="'indeterminate'">
                        </mat-progress-spinner>
                    </button>
                </div>
            </form>
        </ng-container>




    </div>
</ng-container>
