import { MarkedOptions, MarkedRenderer } from 'ngx-markdown';

export function markedOptionsFactory(): MarkedOptions {
    const renderer = new MarkedRenderer();

    renderer.link = (href: string, title: string, text: string) => {
        return (
            `${text}`
        );
    };

    return {
        renderer: renderer,
        gfm: true,
        breaks: true
    };
}
