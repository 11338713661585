import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from '@angular/core';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { fuseAnimations } from '@fuse/animations/public-api';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Select } from '@ngxs/store';
import { UserState } from '@jobzmall/user';
import { Observable } from 'rxjs';
import { User } from '@jobzmall/models';

@Component({
    selector: 'mobile-bottom-sheet',
    templateUrl: './mobile-bottom-sheet.component.html',
    styleUrls: ['./mobile-bottom-sheet.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})
export class MobileBottomSheetComponent
    extends JMBaseComponent
    implements OnInit
{
    @Select(UserState.user) user$: Observable<User>;

    constructor(
        public bottomSheetRef: MatBottomSheetRef<MobileBottomSheetComponent>,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {

    }
}
