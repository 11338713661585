import {
    Component,
    HostListener,
    Inject,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmationConfig } from '@fuse/services/confirmation/confirmation.types';
import {
    UntypedFormGroup,
    UntypedFormControl,
    Validators
} from '@angular/forms';

@Component({
    selector: 'jbz-confirmation-dialog',
    templateUrl: './dialog.component.html',
    styles: [
        /* language=SCSS */
        `
            .jbz-confirmation-dialog-panel {
                @screen md {
                    @apply w-128;
                }

                .mat-dialog-container {
                    padding: 0 !important;
                }
            }
        `
    ],
    encapsulation: ViewEncapsulation.None
})
export class FuseConfirmationDialogComponent implements OnInit {
    form = new UntypedFormGroup({
        content: new UntypedFormControl('', [])
    });
    /**
     * Constructor
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: FuseConfirmationConfig,
        public matDialogRef: MatDialogRef<FuseConfirmationDialogComponent>
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        if (this.data.input) {
            this.form.controls.content.setValue(this.data.input.value);
            this.form.controls.content.setValidators([Validators.required]);
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    // Listen for "Enter" key to confirm
    @HostListener('document:keydown.enter', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
        if (!this.data.input && this.data.actions.confirm.show) {
            this.matDialogRef.close('confirmed');
        }
    }

    // Listen for "Escape" key to cancel
    @HostListener('document:keydown.escape', ['$event'])
    onKeydownCancel(event: KeyboardEvent) {
        if (!this.data.input && this.data.actions.cancel.show) {
            this.matDialogRef.close('cancelled');
        } else if (this.data.input && this.data.actions.cancel.show) {
            this.matDialogRef.close(undefined); // The specific close action when there's an input
        }
    }
}
