import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';
import { JMCommonDirectivesModule } from '@jobzmall/common/directives/common-directives.module';
import { JMSharedModule } from '@jobzmall/shared/shared.module';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { JMPipesModule } from '@jobzmall/core/pipes/pipes.module';
import { NgPipesModule } from 'ngx-pipes';
import { SearchService } from './search.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {
    NgAisClearRefinementsModule,
    NgAisModule,
    NgAisPanelModule,
    NgAisStatsModule
} from 'angular-instantsearch';
import { SearchBoxComponent } from './search-box/search-box.component';
import { MatInputModule } from '@angular/material/input';
import { AisTablePaginationComponent } from './ais-table-pagination/ais-table-pagination.component';
import { SearchFiltersComponent } from './search-filters/search-filters.component';
import { DashboardFiltersComponent } from './dashboard-filters/dashboard-filters.component';
import { OmniSearchComponent } from './omni-search/omni-search.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { AdminFiltersComponent } from './admin-filters/admin-filters.component';
import { MatSelectModule } from '@angular/material/select';
import { MatBadgeModule } from '@angular/material/badge';
import { FilterButtonComponent } from './filter-button/filter-button.component';
import { SearchFilterDropdownComponent } from './search-filter-dropdown/search-filter-dropdown.component';
import { SearchLocationComponent } from './search-location/search-location.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SearchFilterClearButtonComponent } from './search-filter-clear-button/search-filter-clear-button.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { GeoSearchComponent } from './geo-search/geo-search.component';

const COMPONENTS = [
    GeoSearchComponent,
    DashboardFiltersComponent,
    OmniSearchComponent,
    SearchFiltersComponent,
    AdminFiltersComponent,
    AisTablePaginationComponent,
    SearchBoxComponent,
    FilterButtonComponent,
    SearchLocationComponent,
    SearchFilterDropdownComponent,
    SearchFilterClearButtonComponent
];

@NgModule({
    declarations: COMPONENTS,
    imports: [
        RouterModule,
        NgPipesModule,
        JMPipesModule,
        JMCommonDirectivesModule,
        JMSharedModule,
        MatButtonModule,
        MatIconModule,
        TranslocoModule,
        ReactiveFormsModule,
        MatInputModule,
        MatFormFieldModule,
        CommonModule,
        NgAisModule,
        MatSelectModule,
        MatExpansionModule,
        MatBadgeModule,
        MatAutocompleteModule,
        FormsModule,
        GoogleMapsModule
    ],
    exports: COMPONENTS,
    providers: [SearchService]
})
export class JMSearchModule {}
