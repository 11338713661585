import { Inject, Injectable, Injector } from '@angular/core';
import { Action, State, StateContext, Store, Selector } from '@ngxs/store';
import { tap, map, first, catchError, takeUntil } from 'rxjs/operators';
import { MessagesStateModel } from './model';
import * as Noty from 'noty';
import { cloneDeep, extend, random } from 'lodash-es';
import { Restangular } from '@jobzmall/core/api';
import {
    GetConversations,
    ProcessNewUnreadMessage,
    StartConversation,
    SetCurrentConversation,
    CloseCurrentConversation,
    GetMessages,
    ProcessReceiverMessageAdded,
    ProcessNewMessage,
    ProcessReceiverMessageUpdated,
    ProcessReceiverMessageRead,
    ProcessReceiverMessageRemoved,
    AddMessage,
    GetUnread,
    MuteConversation,
    UnmuteConversation,
    ReadCurrentConversation,
    ProcessMessageRead,
    ArchiveConversation,
    DeleteConversation,
    BlockConversationMember,
    StartApplicationConversation,
    PinConversation,
    UnpinConversation,
    DisableConversationPinning,
    EnableConversationPinning,
    UnpinAllConversations,
    ReadConversation,
    GetConversationMembers,
    MaximizeConversation,
    MinimizeConversation,
    UnsendMessage,
    SetCurrentConversationTextValue,
    MaximizeMessagesWidget,
    MinimizeMessagesWidget,
    SetMinimizeWidgetCompletely,
    ProcessReceiverBulkRead,
    ProcessBulkRead,
    GetRequests,
    GetRequestsCount,
    AcceptRequest,
    ClearConversationMessageLimit,
    StartCandidateConversation
} from './actions';
import {
    Conversation,
    ConversationAdapter
} from '@jobzmall/models/conversation.model';
import { UserState } from '@jobzmall/user/ngxs/state';
import { MessageService, MESSAGE_SERVICE } from '../../message.service';
import { Router } from '@angular/router';
import { of, from, throwError } from 'rxjs';
import { UserService } from '@jobzmall/user/user.service';
import { Message, MessageAdapter } from '@jobzmall/models/message.model';
import { SoundService } from '@jobzmall/sound/sound.service';
import { nanoid } from 'nanoid';
import {
    append,
    insertItem,
    patch,
    updateItem,
    removeItem
} from '@ngxs/store/operators';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { SetAppLoading } from '@jobzmall/state/ngxs/actions';
import { SubSink } from 'subsink';
import { NetworkConnection } from '@jobzmall/core/browser/network-connection';
import {
    StartCoachOrderConversation,
    VerifyPhoneNumberForMessaging
} from './actions';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';

@State<MessagesStateModel>({
    name: 'messages',
    defaults: {
        currentConversationText: undefined,
        currentConversation: undefined,
        currentConversationMessages: [],
        currentConversationMessagesPage: 1,
        currentConversationMessagesLoading: false,
        currentConversationMessagesEnd: false,
        conversations: [],
        conversationsLoading: false,
        conversationsEnd: false,
        conversationsPage: 1,
        loadingConversation: false,
        requests: [],
        requestsLoading: false,
        requestsEnd: false,
        requestsPage: 1,
        requestsCount: 0,
        unreadCount: 0,
        unreadCountLoading: false,
        pinningConversation: false,
        minimizeWidgetCompletely: false,
        widgetMaximized: false,
        allowPinning: true,
        isMobile: false
    }
})
@Injectable()
export class MessagesState {
    subscriptions = new SubSink();

    @Selector()
    static currentConversation(state: MessagesStateModel): Conversation {
        return state.currentConversation;
    }

    @Selector()
    static currentConversationText(state: MessagesStateModel): string {
        return state.currentConversationText;
    }

    @Selector()
    static currentConversationMessages(
        state: MessagesStateModel
    ): Array<Message> {
        return state.currentConversationMessages;
    }

    @Selector()
    static currentConversationMessagesLoading(
        state: MessagesStateModel
    ): boolean {
        return state.currentConversationMessagesLoading;
    }

    @Selector()
    static currentConversationMessagesEnd(state: MessagesStateModel): boolean {
        return state.currentConversationMessagesEnd;
    }

    @Selector()
    static loadingConversation(state: MessagesStateModel): boolean {
        return state.loadingConversation;
    }

    @Selector()
    static pinningConversation(state: MessagesStateModel): boolean {
        return state.pinningConversation;
    }

    @Selector()
    static allowPinning(state: MessagesStateModel): boolean {
        return state.allowPinning && !state.isMobile;
    }

    @Selector()
    static minimizeWidgetCompletely(state: MessagesStateModel): boolean {
        return state.minimizeWidgetCompletely;
    }

    @Selector()
    static widgetMaximized(state: MessagesStateModel): boolean {
        return state.widgetMaximized;
    }

    @Selector()
    static conversations(state: MessagesStateModel): Array<any> {
        return state.conversations;
    }

    @Selector()
    static requests(state: MessagesStateModel): Array<Conversation> {
        return state.requests;
    }

    @Selector()
    static pinnedConversations(state: MessagesStateModel): Array<any> {
        return state.conversations.filter((c) => c.pinned == true);
    }

    @Selector()
    static unreadCount(state: MessagesStateModel): number {
        return state.unreadCount;
    }

    @Selector()
    static conversationsLoading(state: MessagesStateModel): boolean {
        return state.conversationsLoading;
    }

    @Selector()
    static conversationsEnd(state: MessagesStateModel): boolean {
        return state.conversationsEnd;
    }

    @Selector()
    static requestsCount(state: MessagesStateModel): number {
        return state.requestsCount;
    }

    @Selector()
    static requestsLoading(state: MessagesStateModel): boolean {
        return state.requestsLoading;
    }

    @Selector()
    static requestsEnd(state: MessagesStateModel): boolean {
        return state.requestsEnd;
    }

    constructor(
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _confirmation: FuseConfirmationService,
        public _userService: UserService,
        public _router: Router,
        public _messageAdapter: MessageAdapter,
        @Inject(MESSAGE_SERVICE) public _messageService: MessageService,
        public _conversationAdapter: ConversationAdapter,
        public _api: Restangular,
        public _sound: SoundService,
        public _store: Store
    ) {}

    ngxsOnInit(ctx: StateContext<MessagesStateModel>) {
        // Subscribe to media changes
        this.subscriptions.sink =
            this._fuseMediaWatcherService.onMediaChange$.subscribe(
                ({ matchingAliases }) => {
                    // Check if the screen is small
                    ctx.patchState({
                        isMobile: !matchingAliases.includes('md')
                    });
                }
            );
    }

    @Action(ProcessNewUnreadMessage)
    processNewUnreadMessage(
        ctx: StateContext<MessagesStateModel>,
        action: ProcessNewUnreadMessage
    ) {
        const currentConversation = ctx.getState().currentConversation;

        if (action.event.type == 'system') {
            return;
        }

        if (
            ctx
                .getState()
                .conversations.find(
                    (c) =>
                        c.message_receiver_id == action.event.receiver_id &&
                        c.maximized
                )
        ) {
            return;
        }

        if (
            currentConversation &&
            action.event.receiver_id === currentConversation.message_receiver_id
        ) {
            return;
        }

        this.setNotyDefaults();

        let noty = new (Noty as any)(
            extend(action.event, {
                timeout: 5000,
                closeWith: ['click']
            })
        );

        noty.on('onClick', () => {
            this._messageService
                .getConversationFromReceiverId(action.event.receiver_id)
                .pipe(first())
                .subscribe((convo: Conversation) => {
                    ctx.dispatch(new PinConversation(convo.channel));
                });
        });
        noty.show();

        if (!ctx.getState().unreadCountLoading) {
            ctx.patchState({
                unreadCount: ctx.getState().unreadCount + 1
            });

            this._sound
                .play('/assets/sounds/new-message.m4a')
                .pipe(first())
                .subscribe(() => {});
        }
    }

    @Action(ProcessNewMessage)
    processNewMessage(
        ctx: StateContext<MessagesStateModel>,
        action: ProcessNewMessage
    ) {
        let conversations = cloneDeep(ctx.getState().conversations);
        let currentConversation = cloneDeep(ctx.getState().currentConversation);

        if (action.event.type == 'system') {
            return of(undefined);
        }

        const updateConversation = ($event: any, index: number) => {
            let convo = cloneDeep(conversations[index]);
            convo.last_message = this._messageAdapter.adapt($event);
            ctx.setState(
                patch({
                    conversations: updateItem<Conversation>(
                        (c) => c.message_receiver_id == $event.receiver_id,
                        convo
                    )
                })
            );

            if (
                conversations.find(
                    (c) =>
                        c.message_receiver_id == $event.receiver_id &&
                        c.maximized
                )
            ) {
                return;
            }

            if (
                currentConversation &&
                $event.receiver_id === currentConversation.message_receiver_id
            ) {
                return;
            }

            convo = cloneDeep(conversations[index]);
            convo.unread++;
            convo.last_message = this._messageAdapter.adapt($event);

            ctx.setState(
                patch({
                    conversations: removeItem<Conversation>(
                        (c) => c.message_receiver_id == $event.receiver_id
                    )
                })
            );
            ctx.setState(
                patch({
                    conversations: insertItem<Conversation>(convo, 0)
                })
            );
            ctx.dispatch(new ClearConversationMessageLimit(convo.channel));
            return cloneDeep(ctx.getState().conversations);
        };

        const index = conversations.findIndex(
            (convo: Conversation) =>
                convo.message_receiver_id === action.event.receiver_id
        );

        if (index === -1) {
            return this._messageService
                .getConversationFromReceiverId(action.event.receiver_id)
                .pipe(first())
                .subscribe((convo: Conversation) => {
                    ctx.setState(
                        patch({
                            conversations: insertItem<Conversation>(convo, 0)
                        })
                    );
                });
        } else {
            conversations = updateConversation(action.event, index);
            return of(conversations);
        }
    }

    @Action(ProcessMessageRead)
    processMessageRead(
        ctx: StateContext<MessagesStateModel>,
        action: ProcessMessageRead
    ) {
        const user = this._store.selectSnapshot(UserState.user);
        const unreadCount = ctx.getState().unreadCount;
        if (unreadCount && action.event.user_id === user.id) {
            let newUnreadCount = unreadCount - action.event.count;
            if (newUnreadCount < 0) {
                newUnreadCount = 0;
            }
            ctx.patchState({
                unreadCount: newUnreadCount
            });
        }
    }

    @Action(ProcessBulkRead)
    processBulkRead(
        ctx: StateContext<MessagesStateModel>,
        action: ProcessBulkRead
    ) {
        const user = this._store.selectSnapshot(UserState.user);
        const unreadCount = ctx.getState().unreadCount;
        if (unreadCount && action.event.user_id === user.id) {
            let newUnreadCount = unreadCount - action.event.count;
            if (newUnreadCount < 0) {
                newUnreadCount = 0;
            }
            ctx.patchState({
                unreadCount: newUnreadCount
            });
        }
    }

    @Action(BlockConversationMember)
    blockConversationMember(
        ctx: StateContext<MessagesStateModel>,
        action: BlockConversationMember
    ) {
        const user = this._store.selectSnapshot(UserState.user);
        const currentConversation = ctx.getState().currentConversation;
        const member =
            action.member ||
            currentConversation.members.find((p: any) => p.user.id !== user.id);

        let conversation = currentConversation;
        if (!conversation) {
            conversation = ctx
                .getState()
                .conversations.find(
                    (c: Conversation) => c.id === member.memberable_id
                );
        }

        return from(
            this._userService.block(member.user).then(() => {
                if (currentConversation) {
                    ctx.dispatch(new CloseCurrentConversation());
                }
                ctx.setState(
                    patch({
                        conversations: removeItem<Conversation>(
                            (c) => c.channel === conversation.channel
                        ),
                        requests: removeItem<Conversation>(
                            (c) => c.channel === conversation.channel
                        )
                    })
                );
            })
        );
    }

    @Action(ArchiveConversation)
    archiveConversation(
        ctx: StateContext<MessagesStateModel>,
        action: ArchiveConversation
    ) {
        return this._messageService.archiveConversation(action.channel).pipe(
            tap(() => {
                ctx.setState(
                    patch({
                        conversations: removeItem<Conversation>(
                            (c) => c.channel === action.channel
                        ),
                        requests: removeItem<Conversation>(
                            (c) => c.channel === action.channel
                        )
                    })
                );
            })
        );
    }

    @Action(DeleteConversation)
    deleteConversation(
        ctx: StateContext<MessagesStateModel>,
        action: DeleteConversation
    ) {
        return this._messageService.deleteConversation(action.channel).pipe(
            tap(() => {
                ctx.setState(
                    patch({
                        conversations: removeItem<Conversation>(
                            (c) => c.channel === action.channel
                        ),
                        requests: removeItem<Conversation>(
                            (c) => c.channel === action.channel
                        )
                    })
                );
            })
        );
    }

    @Action(GetConversations)
    getConversations(
        ctx: StateContext<MessagesStateModel>,
        action: GetConversations
    ) {
        if (action.refresh) {
            ctx.patchState({
                conversationsLoading: false,
                conversationsEnd: false,
                conversations: [],
                conversationsPage: 1
            });
        }
        if (
            !ctx.getState().conversationsLoading &&
            !ctx.getState().conversationsEnd
        ) {
            ctx.patchState({
                conversationsLoading: true
            });
            return this._api
                .all('chat')
                .customGET(undefined, {
                    page: ctx.getState().conversationsPage
                })
                .pipe(
                    map((res: any) => res.data),
                    map((data: any) =>
                        data.map((c: any) => this._conversationAdapter.adapt(c))
                    ),
                    tap((convos: Array<any>) => {
                        if (convos.length) {
                            ctx.patchState({
                                conversationsPage:
                                    ctx.getState().conversationsPage + 1
                            });
                            let conversations = cloneDeep(
                                ctx.getState().conversations
                            );
                            convos.forEach((convo: Conversation) => {
                                if (
                                    conversations.findIndex(
                                        (c: Conversation) => c.id === convo.id
                                    ) === -1
                                ) {
                                    ctx.setState(
                                        patch({
                                            conversations: append<Conversation>(
                                                [convo]
                                            )
                                        })
                                    );
                                }
                            });
                        } else {
                            ctx.patchState({
                                conversationsEnd: true
                            });
                        }

                        ctx.patchState({
                            conversationsLoading: false
                        });
                    })
                );
        }
    }

    @Action(GetRequests)
    getRequests(ctx: StateContext<MessagesStateModel>, action: GetRequests) {
        if (action.refresh) {
            ctx.patchState({
                requestsLoading: false,
                requestsEnd: false,
                requests: [],
                requestsPage: 1
            });
        }
        if (!ctx.getState().requestsLoading && !ctx.getState().requestsEnd) {
            ctx.patchState({
                requestsLoading: true
            });
            return this._api
                .all('chat/request')
                .customGET(undefined, {
                    page: ctx.getState().requestsPage
                })
                .pipe(
                    map((res: any) => res.data),
                    map((data: any) =>
                        data.map((c: any) => this._conversationAdapter.adapt(c))
                    ),
                    tap((reqs: Array<any>) => {
                        if (reqs.length) {
                            ctx.patchState({
                                requestsPage: ctx.getState().requestsPage + 1
                            });
                            let requests = cloneDeep(ctx.getState().requests);
                            reqs.forEach((req: Conversation) => {
                                if (
                                    requests.findIndex(
                                        (c: Conversation) => c.id === req.id
                                    ) === -1
                                ) {
                                    ctx.setState(
                                        patch({
                                            requests: append<Conversation>([
                                                req
                                            ])
                                        })
                                    );
                                }
                            });
                        } else {
                            ctx.patchState({
                                requestsEnd: true
                            });
                        }

                        ctx.patchState({
                            requestsLoading: false
                        });
                    })
                );
        }
    }

    @Action(AcceptRequest)
    acceptRequest(
        ctx: StateContext<MessagesStateModel>,
        action: AcceptRequest
    ) {
        return this._messageService.acceptRequest(action.channel).pipe(
            tap((conversation: Conversation) => {
                if (
                    ctx
                        .getState()
                        .conversations.findIndex(
                            (c: Conversation) => c.id === conversation.id
                        ) === -1
                ) {
                    ctx.setState(
                        patch({
                            conversations: insertItem<Conversation>(
                                conversation,
                                0
                            )
                        })
                    );
                } else {
                    let convo = cloneDeep(
                        ctx
                            .getState()
                            .conversations.find(
                                (c: Conversation) => c.id === conversation.id
                            )
                    );
                    convo.is_request = false;
                    ctx.setState(
                        patch({
                            conversations: updateItem<Conversation>(
                                (c) => c.channel === conversation.channel,
                                convo
                            )
                        })
                    );
                }
                ctx.dispatch(
                    new ClearConversationMessageLimit(conversation.channel)
                );

                ctx.patchState({
                    requestsCount: ctx.getState().requestsCount - 1
                });

                let currentConversation = cloneDeep(
                    ctx.getState().currentConversation
                );
                if (currentConversation) {
                    currentConversation.is_request = false;
                    ctx.patchState({
                        currentConversation: currentConversation
                    });
                    ctx.dispatch(
                        new ClearConversationMessageLimit(
                            currentConversation.channel
                        )
                    );
                }
            })
        );
    }

    @Action(GetUnread)
    getUnreadCount(ctx: StateContext<MessagesStateModel>) {
        if (!ctx.getState().unreadCountLoading) {
            ctx.patchState({
                unreadCountLoading: true
            });
            return this._messageService.getUnreadCount().pipe(
                tap((count: number) => {
                    ctx.patchState({
                        unreadCountLoading: false,
                        unreadCount: count
                    });
                })
            );
        }
    }

    @Action(GetRequestsCount)
    getRequestCount(ctx: StateContext<MessagesStateModel>) {
        return this._messageService.getRequestsCount().pipe(
            tap((count: number) => {
                ctx.patchState({
                    requestsCount: count
                });
            })
        );
    }

    @Action(StartCoachOrderConversation)
    startCoachOrderConversation(
        ctx: StateContext<MessagesStateModel>,
        action: StartCoachOrderConversation
    ) {
        if (!ctx.getState().loadingConversation) {
            const user = this._store.selectSnapshot(UserState.user);

            if (user.email_verified_at && user.phone_verified_at) {
                ctx.patchState({
                    loadingConversation: true
                });
                // this._store.dispatch(new SetAppLoading(true));
                return this._messageService
                    .startCoachOrderConversation(action.id)
                    .pipe(
                        first(),
                        tap((conversation: Conversation) => {
                            conversation.pinned = action.pin;
                            conversation.maximized = action.pin;

                            const conversations = cloneDeep(
                                ctx.getState().conversations
                            );
                            if (action.pin) {
                                this._unpinLastConversation(ctx);
                                this._minimizeAll(ctx);
                                if (ctx.getState().minimizeWidgetCompletely) {
                                    ctx.dispatch(new MaximizeMessagesWidget());
                                }
                            }
                            if (
                                conversations.findIndex(
                                    (c: Conversation) =>
                                        c.id === conversation.id
                                ) === -1
                            ) {
                                ctx.setState(
                                    patch({
                                        conversations: insertItem<Conversation>(
                                            conversation,
                                            0
                                        )
                                    })
                                );
                            } else {
                                ctx.setState(
                                    patch({
                                        conversations: updateItem<Conversation>(
                                            (c) =>
                                                c.channel ===
                                                conversation.channel,
                                            conversation
                                        )
                                    })
                                );
                            }
                            ctx.patchState({
                                loadingConversation: false
                            });
                            this._store.dispatch(new SetAppLoading(false));
                            if (!action.pin) {
                                ctx.dispatch(
                                    new SetCurrentConversation(
                                        conversation.channel
                                    )
                                );
                            }
                        })
                    );
            } else {
                if (!user.email_verified_at) {
                    this._confirmation
                        .open({
                            title: 'Verify Email',
                            message:
                                'You must verify your email before you can message someone.',
                            icon: {
                                show: true,
                                name: 'heroicons_outline:mail',
                                color: 'primary'
                            },
                            actions: {
                                confirm: {
                                    show: true,
                                    label: 'Send Verification Email',
                                    color: 'primary'
                                },
                                cancel: {
                                    show: false
                                }
                            }
                        })
                        .afterClosed()
                        .pipe(first())
                        .subscribe((result: string) => {
                            if (result == 'confirmed') {
                                this._userService.sendVerificationEmail(
                                    user.slug
                                );
                            }
                        });
                    return of(undefined);
                }
                if (!user.phone_verified_at) {
                    this._confirmation
                        .open({
                            title: 'Verify Phone Number',
                            message:
                                'You must verify your phone number before you can message someone.',
                            icon: {
                                show: true,
                                name: 'heroicons_outline:phone',
                                color: 'primary'
                            },
                            actions: {
                                confirm: {
                                    show: true,
                                    label: 'Verify Phone Number',
                                    color: 'primary'
                                },
                                cancel: {
                                    show: false
                                }
                            }
                        })
                        .afterClosed()
                        .pipe(first())
                        .subscribe((result: string) => {
                            if (result == 'confirmed') {
                                ctx.dispatch(
                                    new VerifyPhoneNumberForMessaging()
                                );
                            }
                        });
                    return of(undefined);
                }
            }
        }
    }

    @Action(StartApplicationConversation)
    startApplicationConversation(
        ctx: StateContext<MessagesStateModel>,
        action: StartApplicationConversation
    ) {
        if (!ctx.getState().loadingConversation) {
            const user = this._store.selectSnapshot(UserState.user);

            if (user.email_verified_at && user.phone_verified_at) {
                ctx.patchState({
                    loadingConversation: true
                });
                // this._store.dispatch(new SetAppLoading(true));
                return this._messageService
                    .startApplicationConversation(action.applicationId)
                    .pipe(
                        first(),
                        tap((conversation: Conversation) => {
                            conversation.pinned = action.pin;
                            conversation.maximized = action.pin;

                            const conversations = cloneDeep(
                                ctx.getState().conversations
                            );
                            if (action.pin) {
                                this._unpinLastConversation(ctx);
                                this._minimizeAll(ctx);
                                if (ctx.getState().minimizeWidgetCompletely) {
                                    ctx.dispatch(new MaximizeMessagesWidget());
                                }
                            }
                            if (
                                conversations.findIndex(
                                    (c: Conversation) =>
                                        c.id === conversation.id
                                ) === -1
                            ) {
                                ctx.setState(
                                    patch({
                                        conversations: insertItem<Conversation>(
                                            conversation,
                                            0
                                        )
                                    })
                                );
                            } else {
                                ctx.setState(
                                    patch({
                                        conversations: updateItem<Conversation>(
                                            (c) =>
                                                c.channel ===
                                                conversation.channel,
                                            conversation
                                        )
                                    })
                                );
                            }
                            ctx.patchState({
                                loadingConversation: false
                            });
                            this._store.dispatch(new SetAppLoading(false));
                            if (!action.pin) {
                                ctx.dispatch(
                                    new SetCurrentConversation(
                                        conversation.channel
                                    )
                                );
                            }
                        })
                    );
            } else {
                if (!user.email_verified_at) {
                    this._confirmation
                        .open({
                            title: 'Verify Email',
                            message:
                                'You must verify your email before you can message someone.',
                            icon: {
                                show: true,
                                name: 'heroicons_outline:mail',
                                color: 'primary'
                            },
                            actions: {
                                confirm: {
                                    show: true,
                                    label: 'Send Verification Email',
                                    color: 'primary'
                                },
                                cancel: {
                                    show: false
                                }
                            }
                        })
                        .afterClosed()
                        .pipe(first())
                        .subscribe((result: string) => {
                            if (result == 'confirmed') {
                                this._userService.sendVerificationEmail(
                                    user.slug
                                );
                            }
                        });
                    return of(undefined);
                }
                if (!user.phone_verified_at) {
                    this._confirmation
                        .open({
                            title: 'Verify Phone Number',
                            message:
                                'You must verify your phone number before you can message someone.',
                            icon: {
                                show: true,
                                name: 'heroicons_outline:phone',
                                color: 'primary'
                            },
                            actions: {
                                confirm: {
                                    show: true,
                                    label: 'Verify Phone Number',
                                    color: 'primary'
                                },
                                cancel: {
                                    show: false
                                }
                            }
                        })
                        .afterClosed()
                        .pipe(first())
                        .subscribe((result: string) => {
                            if (result == 'confirmed') {
                                ctx.dispatch(
                                    new VerifyPhoneNumberForMessaging()
                                );
                            }
                        });
                    return of(undefined);
                }
            }
        }
    }

    @Action(StartCandidateConversation)
    startCandidateConversation(
        ctx: StateContext<MessagesStateModel>,
        action: StartCandidateConversation
    ) {
        if (!ctx.getState().loadingConversation) {
            const user = this._store.selectSnapshot(UserState.user);

            if (user.email_verified_at && user.phone_verified_at) {
                ctx.patchState({
                    loadingConversation: true
                });
                // this._store.dispatch(new SetAppLoading(true));
                return this._messageService
                    .startCandidateConversation(
                        action.entityType,
                        action.entityId,
                        action.id
                    )
                    .pipe(
                        first(),
                        tap((conversation: Conversation) => {
                            conversation.pinned = action.pin;
                            conversation.maximized = action.pin;

                            const conversations = cloneDeep(
                                ctx.getState().conversations
                            );
                            if (action.pin) {
                                this._unpinLastConversation(ctx);
                                this._minimizeAll(ctx);
                                if (ctx.getState().minimizeWidgetCompletely) {
                                    ctx.dispatch(new MaximizeMessagesWidget());
                                }
                            }
                            if (
                                conversations.findIndex(
                                    (c: Conversation) =>
                                        c.id === conversation.id
                                ) === -1
                            ) {
                                ctx.setState(
                                    patch({
                                        conversations: insertItem<Conversation>(
                                            conversation,
                                            0
                                        )
                                    })
                                );
                            } else {
                                ctx.setState(
                                    patch({
                                        conversations: updateItem<Conversation>(
                                            (c) =>
                                                c.channel ===
                                                conversation.channel,
                                            conversation
                                        )
                                    })
                                );
                            }
                            ctx.patchState({
                                loadingConversation: false
                            });
                            this._store.dispatch(new SetAppLoading(false));
                            if (!action.pin) {
                                ctx.dispatch(
                                    new SetCurrentConversation(
                                        conversation.channel
                                    )
                                );
                            }
                        })
                    );
            } else {
                if (!user.email_verified_at) {
                    this._confirmation
                        .open({
                            title: 'Verify Email',
                            message:
                                'You must verify your email before you can message someone.',
                            icon: {
                                show: true,
                                name: 'heroicons_outline:mail',
                                color: 'primary'
                            },
                            actions: {
                                confirm: {
                                    show: true,
                                    label: 'Send Verification Email',
                                    color: 'primary'
                                },
                                cancel: {
                                    show: false
                                }
                            }
                        })
                        .afterClosed()
                        .pipe(first())
                        .subscribe((result: string) => {
                            if (result == 'confirmed') {
                                this._userService.sendVerificationEmail(
                                    user.slug
                                );
                            }
                        });
                    return of(undefined);
                }
                if (!user.phone_verified_at) {
                    this._confirmation
                        .open({
                            title: 'Verify Phone Number',
                            message:
                                'You must verify your phone number before you can message someone.',
                            icon: {
                                show: true,
                                name: 'heroicons_outline:phone',
                                color: 'primary'
                            },
                            actions: {
                                confirm: {
                                    show: true,
                                    label: 'Verify Phone Number',
                                    color: 'primary'
                                },
                                cancel: {
                                    show: false
                                }
                            }
                        })
                        .afterClosed()
                        .pipe(first())
                        .subscribe((result: string) => {
                            if (result == 'confirmed') {
                                ctx.dispatch(
                                    new VerifyPhoneNumberForMessaging()
                                );
                            }
                        });
                    return of(undefined);
                }
            }
        }
    }

    @Action(StartConversation)
    startConversation(
        ctx: StateContext<MessagesStateModel>,
        action: StartConversation
    ) {
        if (!ctx.getState().loadingConversation) {
            const user = this._store.selectSnapshot(UserState.user);

            if (user.email_verified_at && user.phone_verified_at) {
                // this._store.dispatch(new SetAppLoading(true));
                ctx.patchState({
                    loadingConversation: true
                });
                return this._messageService
                    .startConversation(action.userIds)
                    .pipe(
                        first(),
                        tap((conversation: Conversation) => {
                            conversation.pinned = action.pin;
                            conversation.maximized = action.pin;
                            const conversations = ctx.getState().conversations;
                            if (action.pin) {
                                this._unpinLastConversation(ctx);
                                this._minimizeAll(ctx);
                                if (ctx.getState().minimizeWidgetCompletely) {
                                    ctx.dispatch(new MaximizeMessagesWidget());
                                }
                            }

                            if (
                                conversations.find(
                                    (c: Conversation) =>
                                        c.id === conversation.id
                                )
                            ) {
                                ctx.setState(
                                    patch({
                                        conversations: updateItem<Conversation>(
                                            (c) => c.id === conversation.id,
                                            conversation
                                        )
                                    })
                                );
                            } else {
                                ctx.setState(
                                    patch({
                                        conversations: insertItem<Conversation>(
                                            conversation,
                                            0
                                        )
                                    })
                                );
                            }

                            if (!action.pin) {
                                this._router.navigate([
                                    `/messages/${conversation.channel}`
                                ]);
                            }
                            ctx.patchState({
                                loadingConversation: false
                            });
                            this._store.dispatch(new SetAppLoading(false));
                        })
                    );
            } else {
                if (!user.email_verified_at) {
                    this._confirmation
                        .open({
                            title: 'Verify Email',
                            message:
                                'You must verify your email before you can message someone.',
                            icon: {
                                show: true,
                                name: 'heroicons_outline:mail',
                                color: 'primary'
                            },
                            actions: {
                                confirm: {
                                    show: true,
                                    label: 'Send Verification Email',
                                    color: 'primary'
                                },
                                cancel: {
                                    show: false
                                }
                            }
                        })
                        .afterClosed()
                        .pipe(first())
                        .subscribe((result: string) => {
                            if (result == 'confirmed') {
                                this._userService.sendVerificationEmail(
                                    user.slug
                                );
                            }
                        });
                    return of(undefined);
                }
                if (!user.phone_verified_at) {
                    this._confirmation
                        .open({
                            title: 'Verify Phone Number',
                            message:
                                'You must verify your phone number before you can message someone.',
                            icon: {
                                show: true,
                                name: 'heroicons_outline:phone',
                                color: 'primary'
                            },
                            actions: {
                                confirm: {
                                    show: true,
                                    label: 'Verify Phone Number',
                                    color: 'primary'
                                },
                                cancel: {
                                    show: false
                                }
                            }
                        })
                        .afterClosed()
                        .pipe(first())
                        .subscribe((result: string) => {
                            if (result == 'confirmed') {
                                ctx.dispatch(
                                    new VerifyPhoneNumberForMessaging()
                                );
                            }
                        });
                    return of(undefined);
                }
            }
        }
    }

    @Action(SetCurrentConversationTextValue)
    setCurrentConversationTextValue(
        ctx: StateContext<MessagesStateModel>,
        action: SetCurrentConversationTextValue
    ) {
        ctx.patchState({
            currentConversationText: undefined
        });
        if (ctx.getState().currentConversation) {
            ctx.patchState({
                currentConversationText: action.value
            });
        }
    }

    @Action(SetCurrentConversation)
    setCurrentConversation(
        ctx: StateContext<MessagesStateModel>,
        action: SetCurrentConversation
    ) {
        if (!ctx.getState().loadingConversation) {
            if (
                ctx.getState().currentConversation &&
                ctx.getState().currentConversation.channel !== action.channel
            ) {
                ctx.dispatch(new CloseCurrentConversation());
            } else if (
                ctx.getState().currentConversation &&
                ctx.getState().currentConversation.channel == action.channel
            ) {
                return of(ctx.getState().currentConversation);
            }

            ctx.patchState({
                loadingConversation: true
            });
            // this._store.dispatch(new SetAppLoading(true));

            return this._messageService.getConversation(action.channel).pipe(
                first(),
                map((conversation: Conversation) => {
                    const conversations = cloneDeep(
                        ctx.getState().conversations
                    );
                    if (
                        conversations.findIndex(
                            (c: Conversation) => c.id === conversation.id
                        ) === -1
                    ) {
                        if (!conversation.is_request) {
                            ctx.setState(
                                patch({
                                    conversations: insertItem<Conversation>(
                                        conversation,
                                        0
                                    )
                                })
                            );
                        }
                    }
                    this._messageService.startListeningToConversation(
                        conversation
                    );
                    ctx.patchState({
                        loadingConversation: false,
                        currentConversation: conversation,
                        currentConversationMessages: cloneDeep(
                            conversation.messages
                        ),
                        currentConversationMessagesPage: 1,
                        currentConversationMessagesLoading: false,
                        currentConversationMessagesEnd: false
                    });
                    this._store.dispatch(new SetAppLoading(false));
                    ctx.dispatch(new GetMessages());
                    ctx.dispatch(new ReadCurrentConversation());
                    return conversation;
                })
            );
        }
    }

    @Action(DisableConversationPinning)
    disableConversationPinning(ctx: StateContext<MessagesStateModel>) {
        ctx.patchState({
            allowPinning: false
        });
    }

    @Action(EnableConversationPinning)
    enableConversationPinning(ctx: StateContext<MessagesStateModel>) {
        ctx.patchState({
            allowPinning: true
        });
    }

    @Action(PinConversation)
    pinConversation(
        ctx: StateContext<MessagesStateModel>,
        action: PinConversation
    ) {
        if (
            !ctx.getState().pinningConversation &&
            ctx.getState().allowPinning
        ) {
            const conversations = ctx.getState().conversations;
            const existing = cloneDeep(
                conversations.find(
                    (c: Conversation) => c.channel === action.channel
                )
            );

            if (existing) {
                if (!existing.pinned) {
                    this._unpinLastConversation(ctx);
                }
                this._minimizeAll(ctx);
                existing.pinned = true;
                existing.maximized = true;
                if (ctx.getState().minimizeWidgetCompletely) {
                    ctx.dispatch(new MaximizeMessagesWidget());
                }
                ctx.setState(
                    patch({
                        conversations: updateItem<Conversation>(
                            (c) => c.channel === action.channel,
                            existing
                        )
                    })
                );
                ctx.dispatch(new ReadConversation(existing.channel));

                return;
            }

            this._unpinLastConversation(ctx);

            ctx.patchState({
                pinningConversation: true
            });

            return this._messageService.getConversation(action.channel).pipe(
                first(),
                map((conversation: Conversation) => {
                    this._minimizeAll(ctx);
                    conversation.pinned = true;
                    conversation.maximized = true;
                    conversation.loading = false;
                    if (ctx.getState().minimizeWidgetCompletely) {
                        ctx.dispatch(new MaximizeMessagesWidget());
                    }
                    ctx.patchState({
                        pinningConversation: false
                    });
                    ctx.setState(
                        patch({
                            conversations: insertItem<Conversation>(
                                conversation,
                                0
                            )
                        })
                    );
                    ctx.dispatch(new ReadConversation(conversation.channel));

                    return conversation;
                })
            );
        }
    }

    @Action(GetConversationMembers)
    getConversationMembers(
        ctx: StateContext<MessagesStateModel>,
        action: GetConversationMembers
    ) {
        const conversation = cloneDeep(
            ctx
                .getState()
                .conversations.find(
                    (c: Conversation) => c.channel === action.channel
                )
        );
        if (conversation) {
            return this._messageService
                .getConversationMembers(action.channel)
                .pipe(
                    first(),
                    tap((members: Array<any>) => {
                        conversation.members = members;
                        if (
                            ctx.getState().currentConversation &&
                            ctx.getState().currentConversation.channel ==
                                action.channel
                        ) {
                            ctx.patchState({
                                currentConversation: conversation
                            });
                        }
                        ctx.setState(
                            patch({
                                conversations: updateItem<Conversation>(
                                    (c) => c.channel === action.channel,
                                    conversation
                                )
                            })
                        );
                    })
                );
        }
    }

    @Action(SetMinimizeWidgetCompletely)
    setMinimizeWidgetCompletely(
        ctx: StateContext<MessagesStateModel>,
        action: SetMinimizeWidgetCompletely
    ) {
        ctx.patchState({
            minimizeWidgetCompletely: action.value
        });
    }

    @Action(MaximizeMessagesWidget)
    maximizeWidget(
        ctx: StateContext<MessagesStateModel>,
        action: MaximizeMessagesWidget
    ) {
        ctx.patchState({
            widgetMaximized: true
        });
    }

    @Action(MinimizeMessagesWidget)
    minimizeWidget(
        ctx: StateContext<MessagesStateModel>,
        action: MinimizeMessagesWidget
    ) {
        if (action.minimizeAll) {
            this._minimizeAll(ctx);
        }
        ctx.patchState({
            widgetMaximized: false
        });
    }

    @Action(MaximizeConversation)
    maximizeConversation(
        ctx: StateContext<MessagesStateModel>,
        action: MaximizeConversation
    ) {
        const conversation = cloneDeep(
            ctx
                .getState()
                .conversations.find(
                    (c: Conversation) => c.channel === action.channel
                )
        );
        if (conversation) {
            this._minimizeAll(ctx);
            conversation.maximized = true;
            ctx.setState(
                patch({
                    conversations: updateItem<Conversation>(
                        (c) => c.channel === action.channel,
                        conversation
                    )
                })
            );
            ctx.dispatch(new ReadConversation(conversation.channel));
        }
    }

    @Action(MinimizeConversation)
    minimizeConversation(
        ctx: StateContext<MessagesStateModel>,
        action: MinimizeConversation
    ) {
        const conversation = cloneDeep(
            ctx
                .getState()
                .conversations.find(
                    (c: Conversation) => c.channel === action.channel
                )
        );
        if (conversation) {
            conversation.maximized = false;
            ctx.setState(
                patch({
                    conversations: updateItem<Conversation>(
                        (c) => c.channel === action.channel,
                        conversation
                    )
                })
            );
        }
    }

    @Action(UnpinConversation)
    unpinConversation(
        ctx: StateContext<MessagesStateModel>,
        action: UnpinConversation
    ) {
        const conversation = cloneDeep(
            ctx
                .getState()
                .conversations.find(
                    (c: Conversation) => c.channel === action.channel
                )
        );
        if (conversation) {
            conversation.pinned = false;
            conversation.maximized = false;
            ctx.setState(
                patch({
                    conversations: updateItem<Conversation>(
                        (c) => c.channel === action.channel,
                        conversation
                    )
                })
            );
        }
    }

    @Action(UnpinAllConversations)
    unpinAllConversation(ctx: StateContext<MessagesStateModel>) {
        const conversations = cloneDeep(
            ctx
                .getState()
                .conversations.filter((c: Conversation) => c.pinned == true)
        );
        conversations.forEach((conversation: Conversation) => {
            conversation.pinned = false;
            conversation.maximized = false;
            ctx.setState(
                patch({
                    conversations: updateItem<Conversation>(
                        (c) => c.channel === conversation.channel,
                        conversation
                    )
                })
            );
        });
    }

    @Action(ReadCurrentConversation)
    readCurrentConversation(ctx: StateContext<MessagesStateModel>) {
        return this._messageService
            .readConversation(ctx.getState().currentConversation.channel)
            .pipe(tap(() => {}));
    }

    @Action(ReadConversation)
    readConversation(
        ctx: StateContext<MessagesStateModel>,
        action: ReadConversation
    ) {
        return this._messageService
            .readConversation(action.channel)
            .pipe(tap(() => {}));
    }

    @Action(CloseCurrentConversation)
    closeCurrentConversation(ctx: StateContext<MessagesStateModel>) {
        this._messageService.stopListeningToConversation();

        ctx.patchState({
            currentConversation: undefined,
            currentConversationMessages: [],
            currentConversationMessagesPage: 1,
            currentConversationMessagesLoading: false,
            currentConversationMessagesEnd: false
        });
    }

    @Action(MuteConversation)
    muteConversation(
        ctx: StateContext<MessagesStateModel>,
        action: MuteConversation
    ) {
        let conversations = cloneDeep(ctx.getState().conversations);
        let currentConversation = cloneDeep(ctx.getState().currentConversation);

        return this._messageService.muteConversation(action.channel).pipe(
            tap(() => {
                let index = conversations.findIndex(
                    (c) => c.channel == action.channel
                );
                conversations[index].muted = true;
                if (currentConversation?.channel == action.channel) {
                    currentConversation.muted = true;
                }
                ctx.patchState({
                    conversations,
                    currentConversation
                });
            })
        );
    }

    @Action(UnmuteConversation)
    unmuteConversation(
        ctx: StateContext<MessagesStateModel>,
        action: UnmuteConversation
    ) {
        let conversations = cloneDeep(ctx.getState().conversations);
        let currentConversation = cloneDeep(ctx.getState().currentConversation);

        return this._messageService.unmuteConversation(action.channel).pipe(
            tap(() => {
                let index = conversations.findIndex(
                    (c) => c.channel == action.channel
                );
                conversations[index].muted = false;
                if (currentConversation?.channel == action.channel) {
                    currentConversation.muted = false;
                }
                ctx.patchState({
                    conversations,
                    currentConversation
                });
            })
        );
    }

    @Action(GetMessages)
    getMessages(ctx: StateContext<MessagesStateModel>) {
        if (
            !ctx.getState().currentConversationMessagesLoading &&
            !ctx.getState().currentConversationMessagesEnd &&
            ctx.getState().currentConversation
        ) {
            ctx.patchState({
                currentConversationMessagesLoading: true
            });
            let last_id = ctx.getState().currentConversationMessages[0]?.id;
            return this._messageService
                .getConversationMessages(
                    ctx.getState().currentConversation.channel,
                    last_id
                )
                .pipe(
                    first(),
                    tap((messages: Array<Message>) => {
                        if (messages.length) {
                            if (messages.length < 10) {
                                ctx.patchState({
                                    currentConversationMessagesEnd: true
                                });
                            }
                            messages.forEach((message: Message) => {
                                ctx.setState(
                                    patch({
                                        currentConversationMessages:
                                            insertItem<Message>(message, 0)
                                    })
                                );
                            });
                        } else {
                            ctx.patchState({
                                currentConversationMessagesEnd: true
                            });
                        }
                        ctx.patchState({
                            currentConversationMessagesLoading: false
                        });
                    })
                );
        }
    }

    @Action(AddMessage)
    addMessage(ctx: StateContext<MessagesStateModel>, action: AddMessage) {
        let id = nanoid();
        const user = this._store.selectSnapshot(UserState.user);
        let conversations = cloneDeep(ctx.getState().conversations);

        this._messageService.startListeningToConversation(
            ctx.getState().currentConversation,
            true
        );

        let message = new Message(
            id,
            action.text,
            { has_media: action.uploads.length > 0 },
            user,
            ctx.getState().currentConversation,
            new Date(),
            null,
            'user',
            [],
            action.uploads.length,
            null,
            !NetworkConnection.online,
            id
        );

        ctx.setState(
            patch({
                currentConversationMessages: append<Message>([message])
            })
        );

        if (!NetworkConnection.online) {
            return;
        }

        this._sound
            .play('/assets/sounds/add-message.m4a')
            .pipe(first())
            .subscribe(() => {});

        return this._messageService
            .addMessageToConversation(
                ctx.getState().currentConversation.channel,
                {
                    text: action.text,
                    temp_id: id,
                    uploads: action.uploads
                }
            )
            .pipe(
                first(),
                catchError((err: any, caught: any) => {
                    ctx.setState(
                        patch({
                            currentConversationMessages: removeItem<Message>(
                                (i) => i.temp_id == id
                            )
                        })
                    );
                    return throwError(err);
                }),
                tap((message: Message) => {
                    if (message) {
                        let currentConversation = cloneDeep(
                            ctx.getState().currentConversation
                        );
                        currentConversation.last_message = message;

                        ctx.setState(
                            patch({
                                currentConversationMessages:
                                    updateItem<Message>(
                                        (m) => m.id == id,
                                        message
                                    ),
                                conversations: updateItem<Conversation>(
                                    (c) =>
                                        c.message_receiver_id ===
                                        currentConversation.message_receiver_id,
                                    currentConversation
                                )
                            })
                        );
                    }
                })
            );
    }

    @Action(UnsendMessage)
    unsendMessageFromConversation(
        ctx: StateContext<MessagesStateModel>,
        action: UnsendMessage
    ) {
        let currentConversation = cloneDeep(ctx.getState().currentConversation);
        if (currentConversation) {
            return this._messageService
                .unsendMessageFromConversation(
                    currentConversation.channel,
                    action.id
                )
                .pipe(first());
        }
    }

    @Action(ProcessReceiverMessageAdded)
    processReceiverMessageAdded(
        ctx: StateContext<MessagesStateModel>,
        action: ProcessReceiverMessageAdded
    ) {
        let currentConversation = cloneDeep(ctx.getState().currentConversation);
        const user = this._store.selectSnapshot(UserState.user);

        // If another user message
        if (
            currentConversation &&
            action.event.receiver_id ===
                currentConversation.message_receiver_id &&
            action.event.sender_id !== user.message_sender_id
        ) {
            ctx.setState(
                patch({
                    currentConversationMessages: append<Message>([
                        this._messageAdapter.adapt(action.event)
                    ])
                })
            );

            if (!currentConversation.is_request) {
                ctx.dispatch(new ReadCurrentConversation());
                this._sound
                    .play('/assets/sounds/message-received.m4a')
                    .pipe(first())
                    .subscribe(() => {});
            }

            // This will remove message limits for convos who get accepted and messaged
            if (currentConversation.message_limit) {
                ctx.dispatch(
                    new ClearConversationMessageLimit(
                        currentConversation.channel
                    )
                );
            }
        }
        // If current user message
        else if (
            currentConversation &&
            action.event.receiver_id ===
                currentConversation.message_receiver_id &&
            action.event.sender_id == user.message_sender_id
        ) {
            let existingMessage = ctx
                .getState()
                .currentConversationMessages.find(
                    (m) => m.temp_id == action.event.temp_id
                );
            if (existingMessage) {
                ctx.setState(
                    patch({
                        currentConversationMessages: updateItem<Message>(
                            (m) => m.temp_id == action.event.temp_id,
                            this._messageAdapter.adapt(action.event)
                        )
                    })
                );
            } else {
                ctx.setState(
                    patch({
                        currentConversationMessages: append<Message>([
                            this._messageAdapter.adapt(action.event)
                        ])
                    })
                );
            }
        }
    }

    @Action(ProcessReceiverMessageUpdated)
    processReceiverMessageUpdated(
        ctx: StateContext<MessagesStateModel>,
        action: ProcessReceiverMessageUpdated
    ) {
        let currentConversation = cloneDeep(ctx.getState().currentConversation);

        if (
            currentConversation &&
            action.event.receiver_id === currentConversation.message_receiver_id
        ) {
            ctx.setState(
                patch({
                    currentConversationMessages: updateItem<Message>(
                        (m) => m.id == action.event.id,
                        this._messageAdapter.adapt(action.event)
                    )
                })
            );
        }
    }

    @Action(ClearConversationMessageLimit)
    clearConversationMessageLimit(
        ctx: StateContext<MessagesStateModel>,
        action: ClearConversationMessageLimit
    ) {
        let currentConversation = cloneDeep(ctx.getState().currentConversation);
        if (currentConversation) {
            currentConversation.message_limit = 0;
            ctx.patchState({
                currentConversation: currentConversation
            });
        }

        let convo = cloneDeep(
            ctx
                .getState()
                .conversations.find((c) => c.channel == action.channel)
        );
        if (convo) {
            convo.message_limit = 0;
            ctx.setState(
                patch({
                    conversations: updateItem<Conversation>(
                        (c) => c.channel === convo.channel,
                        convo
                    )
                })
            );
        }
    }

    @Action(ProcessReceiverMessageRemoved)
    processReceiverMessageRemoved(
        ctx: StateContext<MessagesStateModel>,
        action: ProcessReceiverMessageRemoved
    ) {
        let currentConversation = cloneDeep(ctx.getState().currentConversation);

        if (
            currentConversation &&
            action.event.receiver_id === currentConversation.message_receiver_id
        ) {
            ctx.setState(
                patch({
                    currentConversationMessages: updateItem<Message>(
                        (m) => m.id == action.event.id,
                        this._messageAdapter.adapt(action.event)
                    )
                })
            );
        }
    }

    @Action(ProcessReceiverBulkRead)
    processReceiverBulkRead(
        ctx: StateContext<MessagesStateModel>,
        action: ProcessReceiverBulkRead
    ) {
        let currentConversation = cloneDeep(ctx.getState().currentConversation);
        let conversations = cloneDeep(ctx.getState().conversations);
        const user = this._store.selectSnapshot(UserState.user);

        let convo = cloneDeep(
            conversations.find(
                (convo: Conversation) =>
                    convo.message_receiver_id ===
                    action.event.conversation.message_receiver_id
            )
        );

        if (convo) {
            if (action.event.user_id === user.id) {
                if (convo.unread > 0) {
                    convo.unread = convo.unread - action.event;
                    if (Math.sign(convo.unread) == -1) {
                        convo.unread = 0;
                    }
                    ctx.setState(
                        patch({
                            conversations: updateItem<Conversation>(
                                (c) =>
                                    c.message_receiver_id ===
                                    convo.message_receiver_id,
                                convo
                            )
                        })
                    );
                }
            }

            // Update list
            let index = convo.latest_reads.findIndex(
                (p: any) => p.user_id == action.event.user_id
            );
            let latest_reads = cloneDeep(convo.latest_reads);
            latest_reads[index] = action.event.latest_read;
            convo = cloneDeep(convo);
            convo.latest_reads = latest_reads;

            ctx.setState(
                patch({
                    conversations: updateItem<Conversation>(
                        (c) =>
                            c.message_receiver_id === convo.message_receiver_id,
                        convo
                    )
                })
            );

            // Update current conversation
            if (
                currentConversation &&
                currentConversation.message_receiver_id ===
                    action.event.receiver_id
            ) {
                let index = currentConversation.latest_reads.findIndex(
                    (p: any) => p.user_id == action.event.user_id
                );
                let latest_reads = cloneDeep(currentConversation.latest_reads);
                latest_reads[index] = action.event.latest_read;
                currentConversation = cloneDeep(currentConversation);
                currentConversation.latest_reads = latest_reads;

                ctx.patchState({
                    currentConversation
                });
            }
        }
    }

    @Action(ProcessReceiverMessageRead)
    processReceiverMessageRead(
        ctx: StateContext<MessagesStateModel>,
        action: ProcessReceiverMessageRead
    ) {
        let currentConversation = cloneDeep(ctx.getState().currentConversation);
        let conversations = cloneDeep(ctx.getState().conversations);
        const user = this._store.selectSnapshot(UserState.user);

        if (action.event.user_id === user.id) {
            // Update conversation list
            let convo = cloneDeep(
                conversations.find(
                    (convo: Conversation) =>
                        convo.message_receiver_id === action.event.receiver_id
                )
            );
        } else {
            // Update conversation list
            let convo = cloneDeep(
                conversations.find(
                    (convo: Conversation) =>
                        convo.message_receiver_id === action.event.receiver_id
                )
            );
            if (convo) {
                let index = convo.latest_reads.findIndex(
                    (p: any) => p.user_id == action.event.user_id
                );
                convo.latest_reads[index] = action.event;
                ctx.setState(
                    patch({
                        conversations: updateItem<Conversation>(
                            (c) =>
                                c.message_receiver_id ===
                                convo.message_receiver_id,
                            convo
                        )
                    })
                );
            }
            // Update current conversation
            if (
                currentConversation &&
                currentConversation.message_receiver_id ===
                    action.event.receiver_id
            ) {
                let index = currentConversation.latest_reads.findIndex(
                    (p: any) => p.user_id == action.event.user_id
                );
                currentConversation.latest_reads[index] = action.event;
                ctx.patchState({
                    currentConversation
                });
            }
        }
    }

    private setNotyDefaults() {
        Noty.overrideDefaults({
            callbacks: {
                onTemplate: function () {
                    let html = `
                        <div class="max-w-md w-full bg-white dark:bg-gray-600 shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5">
                            <div class="w-0 flex-1 p-4">
                                <div class="flex items-start">

                                <div class="w-10 h-10 rounded-full flex flex-0 pt-0.5">
                                    ${
                                        this.options.sender.image
                                            ? `
                                                <img class="object-cover w-full h-full rounded-full" src="${this.options.sender.image.full_path}" alt="Profile avatar"/>`
                                            : `
                                                <div class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                                                    ${this.options.sender.name.charAt(
                                                        0
                                                    )}
                                                </div>
                                            `
                                    }
                                </div>
                                <div class="ml-3 w-0 flex-1">
                                    <p class="text-sm font-medium dark:text-white text-gray-900">
                                        ${this.options.sender.name}
                                    </p>
                                    <p class="line-clamp-3 mt-1 text-sm text-gray-500 dark:text-gray-300">
                                        ${
                                            this.options.meta?.has_media
                                                ? `Sent ${this.options.meta.media_count} files`
                                                : this.options.text
                                        }
                                    </p>
                                </div>
                                </div>
                            </div>
                            <div class="flex border-l border-gray-200">
                                <button class="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium dark:text-gray-300 dark:hover:text-gray-200 text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                                    Reply
                                </button>
                            </div>
                            </div>
                        </div>
                    `;

                    this.barDom.innerHTML = html;
                }
            }
        });
    }

    private _minimizeAll(ctx: StateContext<MessagesStateModel>) {
        const conversations = ctx
            .getState()
            .conversations.filter((c) => c.maximized);
        conversations.forEach((conversation: Conversation) => {
            let convo = cloneDeep(conversation);
            convo.maximized = false;
            ctx.setState(
                patch({
                    conversations: updateItem<Conversation>(
                        (c) => c.channel === convo.channel,
                        convo
                    )
                })
            );
        });
    }

    private _unpinLastConversation(ctx: StateContext<MessagesStateModel>) {
        const conversations = ctx.getState().conversations;
        if (conversations.filter((c) => c.pinned).length === 3) {
            let unset = cloneDeep(conversations.filter((c) => c.pinned)[0]);
            unset.pinned = false;
            unset.maximized = false;
            ctx.setState(
                patch({
                    conversations: updateItem<Conversation>(
                        (c) => c.channel === unset.channel,
                        unset
                    )
                })
            );
            ctx.dispatch(new ReadConversation(unset.channel));
        }
    }
}
