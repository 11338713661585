<div *ngIf="kind == 'drawer'" class="flex flex-col">
    <div class="flex flex-row">
        <h2>Filters</h2>
        <ais-clear-refinements (click)="cleared.emit({})" class="clear-filters">
        </ais-clear-refinements>

        <ais-stats data-layout="mobile">
            <ng-template let-state="state">
                <span class="ais-Stats-text">
                    <strong>{{ state.nbHits | number }}</strong> results
                </span>
            </ng-template>
        </ais-stats>
    </div>
    <div class="w-full">
        <ais-panel [ngSwitch]="true" [ngClass]="filter.type" class="filter"
            *ngFor="let filter of filters; trackBy: 'name' | trackByProperty" [header]="filter.name">
            <div *ngSwitchCase="filter.type == 'toggle'" class="content">
                <form>
                    <ais-toggle [attribute]="filter.args.attribute" [label]="filter.name">
                    </ais-toggle>
                </form>
            </div>
            <div *ngSwitchCase="filter.type == 'numeric'" class="content">
                <form>
                    <ais-numeric-menu [attribute]="filter.args.attribute" [items]="filter.args.items">
                    </ais-numeric-menu>
                </form>
            </div>
            <div *ngSwitchCase="filter.type == 'refinement'">
                <form>
                    <ais-refinement-list [searchable]="filter.args.searchable"
                        [searchPlaceholder]="filter.args.searchPlaceholder" [attribute]="filter.args.attribute"
                        [operator]="filter.args.operator">
                    </ais-refinement-list>
                </form>
            </div>
            <div *ngSwitchCase="filter.type == 'hierarchical'">
                <form>
                    <ais-hierarchical-menu [attributes]="filter.args.attributes">
                    </ais-hierarchical-menu>
                </form>
            </div>
        </ais-panel>
    </div>

    <footer class="container-filters-footer" data-layout="mobile">
        <ais-stats class="container-filters-footer-button-wrapper" (click)="closeFilters()">
            <ng-template let-state="state">
                <button class="button button-primary">
                    See {{ state.nbHits | number }} results
                </button>
            </ng-template>
        </ais-stats>
    </footer>
</div>


<div *ngIf="kind == 'dropdowns'" class="flex flex-row flex-wrap items-center gap-4">
    <ng-container *ngFor="
                        let filter of filters;
                        trackBy: 'name' | trackByProperty
                    ">
        <search-filter-dropdown [filter]="filter"></search-filter-dropdown>
    </ng-container>
        <search-filter-clear-button></search-filter-clear-button>


</div>

<div *ngIf="kind == 'accordion'" class="flex w-full min-h-full bg-card">
    <!-- Filters -->
    <div class="flex flex-col w-full">
        <div class="flex items-center justify-between p-4 border-b">
            <div class="flex items-center">
                <mat-icon class="mx-2 icon-size-5" [svgIcon]="'heroicons_outline:adjustments'"></mat-icon>
                <span class="text-xl font-bold">Filters</span>
                <span *ngIf="state.items?.length"
                    class="inline-flex px-3 ml-2 text-sm font-semibold leading-5 text-white capitalize rounded-full bg-primary-800">
                    {{ state.items?.length }}
                </span>
            </div>
            <div class="flex items-center">
                <button *ngIf="state.items?.length" (click)="clearRefinements.state.refine()" mat-button>
                    Clear Filters
                </button>
                <ais-clear-refinements #clearRefinements class="hidden"></ais-clear-refinements>
            </div>
        </div>
        <div class="flex items-center w-full">
            <mat-accordion class="w-full divide-y shadow-none">
                <mat-expansion-panel *ngFor="
                        let filter of filters;
                        trackBy: 'name' | trackByProperty
                    " class="mb-0 shadow-none">
                    <mat-expansion-panel-header
                        class="shadow-none">
                        <mat-panel-title class="text-xl font-bold">
                            {{ filter.name }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ais-panel [ngSwitch]="true" [ngClass]="filter.type" class="filter">
                        <div *ngSwitchCase="filter.type == 'toggle'" class="content">
                            <form>
                                <ais-toggle #filterElement [attribute]="filter.args.attribute" [label]="filter.name">
                                </ais-toggle>
                            </form>
                        </div>
                        <div *ngSwitchCase="filter.type == 'numeric'" class="content">
                            <form>
                                <ais-numeric-menu #filterElement [attribute]="filter.args.attribute"
                                    [items]="filter.args.items">
                                </ais-numeric-menu>
                            </form>
                        </div>
                        <div *ngSwitchCase="filter.type == 'refinement'">
                            <form>
                                <ais-refinement-list #filterElement [searchable]="filter.args.searchable"
                                    [showMore]="true"
                                    [showMoreLimit]="1000"
                                    [searchPlaceholder]="
                                        filter.args.searchPlaceholder
                                    " [attribute]="filter.args.attribute" [operator]="filter.args.operator">
                                </ais-refinement-list>
                            </form>
                        </div>
                        <div *ngSwitchCase="filter.type == 'hierarchical'">
                            <form>
                                <ais-hierarchical-menu #filterElement [attributes]="filter.args.attributes">
                                </ais-hierarchical-menu>
                            </form>
                        </div>
                    </ais-panel>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>
