import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Input,
    ChangeDetectorRef,
    EventEmitter,
    Output
} from '@angular/core';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { fuseAnimations } from '@fuse/animations/public-api';
import { Select, Store } from '@ngxs/store';
import { User } from '@jobzmall/models';
import { UserState } from '@jobzmall/user/ngxs/state';
import { Observable, of, combineLatest } from 'rxjs';
import { UserService } from '@jobzmall/user/user.service';
import { cloneDeep } from 'lodash-es';
import {
    UntypedFormGroup,
    UntypedFormControl,
    Validators
} from '@angular/forms';
import { validateAllFormFields } from '@jobzmall/core/util/forms';
import {
    catchError,
    distinctUntilChanged,
    first,
    startWith
} from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryISO } from '@jobzmall/telephone-input/enums/country-iso.enum';
import { SearchCountryField } from '@jobzmall/telephone-input/enums/search-country-field.enum';
import { TooltipLabel } from '@jobzmall/telephone-input/enums/tooltip-label.enum';
import { RefreshUser } from '@jobzmall/user/ngxs/actions';
import { AppState } from '@jobzmall/state/ngxs/state';
import { CaptchaService } from '@jobzmall/core/services/captcha.service';

@Component({
    selector: 'phone-update-form',
    templateUrl: './phone-update-form.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})
export class PhoneUpdateFormComponent
    extends JMBaseComponent
    implements OnInit
{
    @Input() showLogo: boolean = true;
    @Output() onVerificationEmailSend: EventEmitter<any> = new EventEmitter();

    @Select(UserState.user) user$: Observable<User>;
    @Select(AppState.locationDetails) locationDetails$: Observable<any>;
    user: User;

    SearchCountryField = SearchCountryField;
    TooltipLabel = TooltipLabel;
    CountryISO = CountryISO;

    selectedCountryISO = CountryISO.UnitedStates;

    phone: any;
    form = new UntypedFormGroup({
        phone: new UntypedFormControl('', [Validators.required])
    });

    constructor(
        private _captcha: CaptchaService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _store: Store,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _userService: UserService
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.sink = combineLatest([
            this.user$,
            this.locationDetails$
        ]).subscribe(([user, locationDetails]) => {
            this.user = user;
            if (this.user && this.user.phone_last_updated_at){
                this.onVerificationEmailSend.emit({});
                return;
            }
            if (this.user && this.user.phone_country_code) {
                this.selectedCountryISO = <CountryISO>(
                    this.user.phone_country_code.toLowerCase()
                );
                this._changeDetectorRef.markForCheck();
                this._changeDetectorRef.detectChanges();
            } else if (locationDetails) {
                this.selectedCountryISO = <CountryISO>(
                    locationDetails.iso_code.toLowerCase()
                );
            }
        });
    }

    sendVerificationEmail() {
        this._userService.sendVerificationEmail(this.user.slug, () => {
            this.onVerificationEmailSend.emit({})
        }, () => {
            this.onVerificationEmailSend.emit({})
        });
    }

    confirm() {
        let phoneCopy = cloneDeep(this.phone);

        if (this.form.valid && !this.loading) {
            this.loading = true;
            this._userService
                .save(
                    {
                        phone_country_code: phoneCopy.countryCode,
                        phone: phoneCopy.number
                    },
                    this.user.slug
                )
                .pipe(
                    catchError((err: any, caught: any) => {
                        return of(undefined);
                    }),
                    first()
                )
                .subscribe((data: any) => {
                    if (data) {

                        setTimeout(() => {
                            this.subscriptions.sink = this.user$.subscribe(
                                (user: User) => {
                                    if (user.phone == data.phone) {
                                        this.loading = false;

                                        const redirectURL =
                                            this._activatedRoute.snapshot.queryParamMap.get(
                                                'redirectURL'
                                            ) || '/user/settings';
                                        this._router.navigate(
                                            ['/phone/verify'],
                                            {
                                                queryParams: {
                                                    countryCode:
                                                        phoneCopy.countryCode,
                                                    redirectURL: redirectURL
                                                }
                                            }
                                        );
                                    }
                                }
                            );
                            this._store.dispatch(new RefreshUser());
                        });
                    }
                });
        } else {
            validateAllFormFields(this.form);
        }
    }
}
