import { AbstractControl, ValidatorFn } from '@angular/forms';

export function noUrlValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const value = control.value;

    // Regular expression to match URLs
    // This regex looks for common URL patterns while allowing hyphens
    const urlPattern = /[,./:\?]/;

    // Check if the value matches the URL pattern
    const containsUrl = urlPattern.test(value);

    // Return an error object if a URL is found, otherwise return null
    return containsUrl ? { 'containsUrl': { value: control.value } } : null;
  };
}
