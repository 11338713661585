// angular
import { autocomplete } from '@algolia/autocomplete-js';
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { JMBaseComponent } from '@jobzmall/components/base/base.component';
import { ConfigService } from '@jobzmall/config';
import { AuthState } from '@jobzmall/core';
import { TranslocoService } from '@ngneat/transloco';
import { Select } from '@ngxs/store';
import algoliasearch from 'algoliasearch/lite';
import { nanoid } from 'nanoid';
import { Observable, combineLatest, firstValueFrom } from 'rxjs';
import { first } from 'rxjs/operators';
import { SearchService } from '../search.service';

@Component({
    selector: 'omni-search',
    templateUrl: './omni-search.component.html'
})
export class OmniSearchComponent
    extends JMBaseComponent
    implements OnInit, AfterViewInit
{
    @Input() transparent: boolean = false;
    @Input() glass = false;
    @Input() neumorphic = false;
    @Input() large = false;

    searchTerm: string;
    acomplete: any;
    algoliaClient: any;
    indexes: Object;
    suggestionPressed: boolean = false;
    id = nanoid();

    @Select(AuthState.isAuthenticated) isAuthenticated$: Observable<boolean>;

    authenticated: boolean;

    inputControl = new UntypedFormControl();

    @Output() selected: EventEmitter<any> = new EventEmitter();

    constructor(
        private _elementRef: ElementRef,
        private _transloco: TranslocoService,
        private _config: ConfigService,
        private _searchService: SearchService,
        private _router: Router
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.sink = combineLatest([
            this.isAuthenticated$,
            this._transloco.langChanges$
        ]).subscribe(([authenticated, lang]) => {
            this.authenticated = authenticated;
            setTimeout(() => {
                this._transloco
                    .selectTranslation()
                    .pipe(first())
                    .subscribe(() => {
                        try {
                            this.initAutocomplete();
                        } catch (e) {}
                    });
            });
        });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this._transloco
                .selectTranslation()
                .pipe(first())
                .subscribe(() => {
                    try {
                        this.initAutocomplete();
                    } catch (e) {}
                });
        });
    }

    ngOnDestroy() {
        if (this.acomplete) {
            this.acomplete.destroy();
        }

        super.ngOnDestroy();
    }

    initAutocomplete() {
        if (this.acomplete) {
            this.acomplete.destroy();
        }
        const searchClient = algoliasearch(
            this._config.getSettings('data.algolia.app_id'),
            this._config.getSettings('data.algolia.app_key')
        );
        if (!this._elementRef.nativeElement.querySelector(`#omni-${this.id}`)) {
            return;
        }

        this.acomplete = autocomplete({
            placeholder: this._transloco.translate(
                'PUBLIC.HEADER.SEARCH.PLACEHOLDER'
            ),
            debug: false,
            container: `#omni-${this.id}`,
            openOnFocus: true,
            onSubmit: ({ state }: { state: any }) => {
                this._router.navigate(['/search/jobs'], {
                    queryParams: { query: state.query }
                });
            },
            getSources: () => {
                return [
                    {
                        sourceId: 'query',
                        getItems: ({ query }) => {
                            return query
                                ? [
                                      {
                                          query
                                      }
                                  ]
                                : [];
                        },
                        onSelect: ({ item }: { item: any }) => {
                            this._router.navigate(['/search/jobs'], {
                                queryParams: { query: item.query }
                            });
                        },
                        templates: {
                            item({
                                item,
                                createElement
                            }: {
                                item: any;
                                createElement: Function;
                            }) {
                                return createElement('div', {
                                    dangerouslySetInnerHTML: {
                                        __html: `
                                        <div class="py-2 px-3 flex flex-row items-start">
                                            <i class="fa fa-search icon-size-4 mr-3"></i>
                                            <div class="flex flex-col w-full">
                                                <div class="flex flex-row items-center">
                                                    <span class="text-lg"><span class="font-semibold">${item.query}</span> <i>in Jobs</i></span>
                                                </div>
                                            </div>
                                        </div>
                                    `
                                    }
                                });
                            }
                        }
                    },
                    {
                        sourceId: 'links',
                        getItems: ({ query }) => {
                            return this.authenticated
                                ? [
                                      {
                                          label: 'Discover Jobs',
                                          icon: '<svg class="text-purple-600 dark:text-default h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 6C19.2 6 21 7.32394 21 13.5C21 19.6761 19.2 21 12 21C4.8 21 3 19.6082 3 13.5C3 7.39181 4.8 6 12 6Z" class="stroke-current" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.79736 6C7.52146 3.40115 9.76073 3 12 3C14.2393 3 16.4785 3.40115 16.2026 6" class="stroke-current" stroke-width="1.6" stroke-linejoin="round"/><path d="M3 12.0007C3 12.0007 7.50022 13.0002 12.0001 13C16.5 12.9998 21 12 21 12" class="stroke-current" stroke-width="1.6" stroke-linejoin="round"/><path d="M11.2 15C11.2 15.4418 11.5582 15.8 12 15.8C12.4418 15.8 12.8 15.4418 12.8 15H11.2ZM11.2 13V15H12.8V13H11.2Z" class="fill-current"/></svg>',
                                          colorClass: 'text-default',
                                          description:
                                              'Seamlessly browser available jobs hiring now',
                                          url: '/jobs'
                                      },
                                      {
                                          label: 'My Applications',
                                          icon: '<svg class="text-purple-600 dark:text-default h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M3 12C3 5 5.00092 3 12.0005 3C19 3 21 5 21 12C21 19 18.9231 21 12.0005 21C5.07784 21 3 19 3 12Z" class="stroke-current" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" /> <path d="M15 10.2L11 14.2L9 12.2"  class="stroke-current" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" /></svg>',
                                          colorClass: 'text-default',
                                          description:
                                              'From applied to hired, track all of your applications in one spot',
                                          url: '/jobs/applications'
                                      },
                                      {
                                          label: 'Video Resume Studio',
                                          icon: '<svg class="text-purple-600 dark:text-default h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 5.77778 3.71511 4 10.0004 4C16.2857 4 18 5.71429 18 12C18 18.2857 16.2857 20 10.0004 20C3.71511 20 2 18.2222 2 12Z" class="stroke-current" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" /><path d="M18 10C19 8 20.1739 6.30306 21.2174 7.25255C22.2609 8.20204 22.2609 15.798 21.2174 16.7474C20.1739 17.6969 19 16 18 14" class="stroke-current" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" /></svg>',
                                          colorClass: 'text-default',
                                          description:
                                              'Record your video resume and share your story with the world',
                                          url: '/video-resume-studio/questionnaire'
                                      },
                                      {
                                        label: 'My Resumes',
                                        icon: '<svg class="text-purple-600 dark:text-default h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path class="stroke-current fill-current" d="M377.4 45.3L310.6 112 400 201.4l66.7-66.7c12.5-12.5 12.5-32.8 0-45.3L422.6 45.3c-12.5-12.5-32.8-12.5-45.3 0zM279.7 97.7l75.1-75.1c25-25 65.5-25 90.5 0l44.1 44.1c25 25 25 65.5 0 90.5l-75.1 75.1L376.8 370c-7.3 26.6-27.7 47.6-54.2 55.6L47.4 508.1c-12.3 3.7-25.7 .3-34.7-8.8s-12.5-22.4-8.8-34.7L86.5 189.4c7.9-26.4 28.9-46.9 55.6-54.2L279.7 97.7zm3.6 32.2L150.5 166.1c-16 4.4-28.6 16.6-33.3 32.5L42.7 446.7 152.9 336.5c-5.6-9.5-8.9-20.6-8.9-32.5c0-35.3 28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64c-11.9 0-23-3.2-32.5-8.9L65.3 469.3l248.1-74.4c15.9-4.8 28.2-17.4 32.5-33.3l36.2-132.8-98.9-98.9zM208 272a32 32 0 1 0 0 64 32 32 0 1 0 0-64z"/></svg>',
                                        colorClass: 'text-default',
                                        description:
                                            'Manage your resumes and receive feedback from resume experts with a click of a button',
                                        url: '/my-resumes'
                                    },
                                      {
                                          label: 'Career Coaching',
                                          icon: '<svg class="text-purple-600 dark:text-default h-6 w-6" viewBox="0 0 24 24" fill="none"><path d="M5 10V17.5C5 19.433 6.567 21 8.5 21C10.433 21 12 19.433 12 17.5V6.5C12 4.567 13.567 3 15.5 3C17.433 3 19 4.567 19 6.5V13" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" /><path fill-rule="evenodd" clip-rule="evenodd" d="M19 16C20.1047 16 21 16.8953 21 18C21 19.1047 20.1047 20 19 20C17.8953 20 17 19.1047 17 18C17 16.8953 17.8953 16 19 16Z" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" /><path d="M7 6L5 4L3 6" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" /></svg>',
                                          colorClass: 'text-default',
                                          description:
                                              'Find and order services from the top coaches on JobzMall',
                                          url: '/genius'
                                      },
                                      {
                                          label: 'Organizations',
                                          icon: '<svg class="text-purple-600 dark:text-default h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z" /></svg>',
                                          colorClass: 'text-default',
                                          description:
                                              "Expand your horizons and discover the world's most exciting organizations",
                                          url: '/organizations'
                                      }
                                  ].filter(({ label }) =>
                                      label
                                          .toLowerCase()
                                          .includes(query.toLowerCase())
                                  )
                                : [
                                      {
                                          label: 'Discover Jobs',
                                          icon: '<svg class="text-purple-600 dark:text-default h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 6C19.2 6 21 7.32394 21 13.5C21 19.6761 19.2 21 12 21C4.8 21 3 19.6082 3 13.5C3 7.39181 4.8 6 12 6Z" class="stroke-current" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.79736 6C7.52146 3.40115 9.76073 3 12 3C14.2393 3 16.4785 3.40115 16.2026 6" class="stroke-current" stroke-width="1.6" stroke-linejoin="round"/><path d="M3 12.0007C3 12.0007 7.50022 13.0002 12.0001 13C16.5 12.9998 21 12 21 12" class="stroke-current" stroke-width="1.6" stroke-linejoin="round"/><path d="M11.2 15C11.2 15.4418 11.5582 15.8 12 15.8C12.4418 15.8 12.8 15.4418 12.8 15H11.2ZM11.2 13V15H12.8V13H11.2Z" class="fill-current"/></svg>',
                                          colorClass: 'text-default',
                                          description:
                                              'Seamlessly browser available jobs hiring now',
                                          url: '/jobs'
                                      },
                                      {
                                          label: 'Video Resume Studio',
                                          icon: '<svg class="text-purple-600 dark:text-default h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 5.77778 3.71511 4 10.0004 4C16.2857 4 18 5.71429 18 12C18 18.2857 16.2857 20 10.0004 20C3.71511 20 2 18.2222 2 12Z" class="stroke-current" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" /><path d="M18 10C19 8 20.1739 6.30306 21.2174 7.25255C22.2609 8.20204 22.2609 15.798 21.2174 16.7474C20.1739 17.6969 19 16 18 14" class="stroke-current" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" /></svg>',
                                          colorClass: 'text-default',
                                          description:
                                              'Record your video resume and share your story with the world',
                                          url: '/video-resume-studio/questionnaire'
                                      },
                                      {
                                        label: 'Career Coaching',
                                        icon: '<svg class="text-purple-600 dark:text-default h-6 w-6" viewBox="0 0 24 24" fill="none"><path d="M5 10V17.5C5 19.433 6.567 21 8.5 21C10.433 21 12 19.433 12 17.5V6.5C12 4.567 13.567 3 15.5 3C17.433 3 19 4.567 19 6.5V13" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" /><path fill-rule="evenodd" clip-rule="evenodd" d="M19 16C20.1047 16 21 16.8953 21 18C21 19.1047 20.1047 20 19 20C17.8953 20 17 19.1047 17 18C17 16.8953 17.8953 16 19 16Z" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" /><path d="M7 6L5 4L3 6" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" /></svg>',
                                        colorClass: 'text-default',
                                        description:
                                            'Find and order services from the top coaches on JobzMall',
                                        url: '/genius'
                                    },
                                      {
                                          label: 'Organizations',
                                          icon: '<svg class="text-purple-600 dark:text-default h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z" /></svg>',
                                          colorClass: 'text-default',
                                          description:
                                              "Expand your horizons and discover the world's most exciting organizations",
                                          url: '/organizations'
                                      }
                                  ].filter(({ label }) =>
                                      label
                                          .toLowerCase()
                                          .includes(query.toLowerCase())
                                  );
                        },
                        onSelect: ({ item }: { item: any }) => {
                            this._router.navigate([item.url]);
                        },
                        templates: {
                            header({
                                createElement
                            }: {
                                createElement: Function;
                            }) {
                                return createElement('div', {
                                    dangerouslySetInnerHTML: {
                                        __html: `
                                        <h2 class="bg-gray-100 dark:bg-gray-900 dark:text-white py-2.5 px-4 text-md font-semibold text-gray-900">Quick Actions</h2>
                                    `
                                    }
                                });
                            },
                            item({
                                item,
                                createElement
                            }: {
                                item: any;
                                createElement: Function;
                            }) {
                                return createElement('div', {
                                    dangerouslySetInnerHTML: {
                                        __html: `
                                        <div class="py-3 px-2 flex flex-row items-center">
                                             <div
                                                class="flex items-center justify-center flex-shrink-0 w-10 h-10 ${item.colorClass} rounded-md sm:h-12 sm:w-12">
                                                ${item.icon}
                                            </div>

                                            <div class="ml-2 flex flex-col">
                                                <span class="text-lg font-semibold">${item.label}</span>
                                                <span class="mt-2 text-md text-secondary">${item.description}</span>
                                            </div>
                                        </div>
                                    `
                                    }
                                });
                            }
                        }
                    },
                    {
                        sourceId: 'quick-search',
                        onSelect: ({ item }: { item: any }) => {
                            this._router.navigate(['/search/jobs'], {
                                queryParams: item.params
                            });
                        },
                        getItems: ({ query }) => {
                            return query
                                ? [
                                      {
                                          label: 'Full-Time Jobs',
                                          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" /></svg>',
                                          colorClass: 'text-default',
                                          description:
                                              'See the latest open Full-Time positions on JobzMall',
                                          params: {
                                              'refinementList[type][0]':
                                                  'Full-Time'
                                          }
                                      },
                                      {
                                          label: 'Part-Time Jobs',
                                          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" /></svg>',
                                          colorClass: 'text-default',
                                          description:
                                              'See the latest open Part-Time positions on JobzMall',
                                          params: {
                                              'refinementList[type][0]':
                                                  'Part-Time'
                                          }
                                      },
                                      {
                                          label: 'Internships',
                                          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" /></svg>',
                                          colorClass: 'text-default',
                                          description:
                                              'See the latest open Internship positions on JobzMall',
                                          params: {
                                              'refinementList[type][0]':
                                                  'Internship'
                                          }
                                      },
                                      {
                                          label: 'Temp Jobs',
                                          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" /></svg>',
                                          colorClass: 'text-default',
                                          description:
                                              'See the latest open Temp positions on JobzMall',
                                          params: {
                                              'refinementList[type][0]':
                                                  'Temporary'
                                          }
                                      },
                                      {
                                          label: 'Contracts',
                                          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" /></svg>',
                                          colorClass: 'text-default',
                                          description:
                                              'See the latest open Contract positions on JobzMall',
                                          params: {
                                              'refinementList[type][0]':
                                                  'Contract'
                                          }
                                      },
                                      {
                                          label: 'Volunteer Positions',
                                          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" /></svg>',
                                          colorClass: 'text-default',
                                          description:
                                              'Want to give back to the community? See the latest open Volunteer positions on JobzMall',
                                          params: {
                                              'refinementList[type][0]':
                                                  'Contract'
                                          }
                                      },
                                      {
                                          label: 'Apprenticeships',
                                          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" /></svg>',
                                          colorClass: 'text-default',
                                          description:
                                              'Want to learn on the job? See the latest open Apprenticeship positions on JobzMall',
                                          params: {
                                              'refinementList[type][0]':
                                                  'Apprenticeship'
                                          }
                                      },
                                      {
                                          label: 'Work-Based Learning Jobs',
                                          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" /></svg>',
                                          colorClass: 'text-default',
                                          description:
                                              'Want to learn on the job? See the latest open Apprenticeship positions on JobzMall',
                                          params: {
                                              'refinementList[type][0]':
                                                  'Apprenticeship'
                                          }
                                      },
                                      {
                                          label: 'Remote Jobs',
                                          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6.115 5.19l.319 1.913A6 6 0 008.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 002.288-4.042 1.087 1.087 0 00-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 01-.98-.314l-.295-.295a1.125 1.125 0 010-1.591l.13-.132a1.125 1.125 0 011.3-.21l.603.302a.809.809 0 001.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 001.528-1.732l.146-.292M6.115 5.19A9 9 0 1017.18 4.64M6.115 5.19A8.965 8.965 0 0112 3c1.929 0 3.716.607 5.18 1.64" /></svg>',
                                          colorClass: 'text-default',
                                          description:
                                              'Want to work from home? Quickly find Remote Jobs on JobzMall',
                                          params: {
                                              location: 'remote'
                                          }
                                      },
                                      {
                                          label: 'Work From Home Jobs',
                                          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6.115 5.19l.319 1.913A6 6 0 008.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 002.288-4.042 1.087 1.087 0 00-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 01-.98-.314l-.295-.295a1.125 1.125 0 010-1.591l.13-.132a1.125 1.125 0 011.3-.21l.603.302a.809.809 0 001.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 001.528-1.732l.146-.292M6.115 5.19A9 9 0 1017.18 4.64M6.115 5.19A8.965 8.965 0 0112 3c1.929 0 3.716.607 5.18 1.64" /></svg>',
                                          colorClass: 'text-default',
                                          description:
                                              'Want to work from home? Quickly find Remote Jobs on JobzMall',
                                          params: {
                                              location: 'remote'
                                          }
                                      }
                                  ].filter(({ label }) =>
                                      label
                                          .toLowerCase()
                                          .includes(query.toLowerCase())
                                  )
                                : [];
                        },
                        templates: {
                            header({
                                createElement
                            }: {
                                createElement: Function;
                            }) {
                                return createElement('div', {
                                    dangerouslySetInnerHTML: {
                                        __html: `
                                        <h2 class="bg-gray-100 dark:bg-gray-900 dark:text-white py-2.5 px-4 text-md font-semibold text-gray-900">Quick Search</h2>
                                    `
                                    }
                                });
                            },
                            item({
                                item,
                                createElement
                            }: {
                                item: any;
                                createElement: Function;
                            }) {
                                return createElement('div', {
                                    dangerouslySetInnerHTML: {
                                        __html: `
                                        <div class="py-3 px-2 flex flex-row items-center">
                                             <div
                                                class="flex items-center justify-center flex-shrink-0 w-10 h-10 ${item.colorClass} rounded-md sm:h-12 sm:w-12">
                                                ${item.icon}
                                            </div>

                                            <div class="ml-2 flex flex-col">
                                                <span class="text-lg font-semibold">${item.label}</span>
                                                <span class="mt-2 text-md text-secondary">${item.description}</span>
                                            </div>
                                        </div>
                                    `
                                    }
                                });
                            }
                        }
                    },

                    {
                        sourceId: 'job_templates',
                        onSelect: ({ item }: { item: any }) => {
                            this._router.navigate(['/search/jobs'], {
                                queryParams: { query: item.title }
                            });
                        },
                        getItems({ query }) {
                            return query
                                ? getAlgoliaResults({
                                      searchClient,
                                      queries: [
                                          {
                                              indexName: 'job_templates',
                                              query,
                                              params: {
                                                  hitsPerPage: 3
                                              }
                                          }
                                      ]
                                  })
                                : [];
                        },
                        displayKey: 'title',
                        name: 'job_templates',
                        templates: {
                            header({
                                createElement
                            }: {
                                createElement: Function;
                            }) {
                                return createElement('div', {
                                    dangerouslySetInnerHTML: {
                                        __html: `
                                        <h2 class="bg-gray-100 dark:bg-gray-900 dark:text-white py-2.5 px-4 text-md font-semibold text-gray-900">Job Titles</h2>
                                    `
                                    }
                                });
                            },
                            item({
                                item,
                                createElement
                            }: {
                                item: any;
                                createElement: Function;
                            }) {
                                return createElement('div', {
                                    dangerouslySetInnerHTML: {
                                        __html: `
                                        <div class="py-2 px-3 flex flex-row items-start">
                                            <i class="fa fa-search icon-size-4 mr-3"></i>
                                            <div class="flex flex-col w-full">
                                                <div class="flex flex-row items-center">
                                                    <span class="text-lg font-semibold">${item.title}</span>
                                                </div>
                                            </div>
                                        </div>
                                    `
                                    }
                                });
                            }
                        }
                    },
                    {
                        sourceId: 'boards',
                        onSelect: ({ item }: { item: any }) => {
                            this._router.navigate(['/boards/' + item.slug]);
                        },
                        getItems({ query }) {
                            return query
                                ? getAlgoliaResults({
                                      searchClient,
                                      queries: [
                                          {
                                              indexName: 'boards',
                                              query,
                                              params: {
                                                  hitsPerPage: 3
                                              }
                                          }
                                      ]
                                  })
                                : [];
                        },
                        displayKey: 'name',
                        name: 'boards',
                        templates: {
                            header({
                                createElement
                            }: {
                                createElement: Function;
                            }) {
                                return createElement('div', {
                                    dangerouslySetInnerHTML: {
                                        __html: `
                                        <h2 class="bg-gray-100 dark:bg-gray-900 dark:text-white py-2.5 px-4 text-md font-semibold text-gray-900">Job Boards</h2>
                                    `
                                    }
                                });
                            },
                            item({
                                item,
                                createElement
                            }: {
                                item: any;
                                createElement: Function;
                            }) {
                                if (item.image) {
                                    return createElement('div', {
                                        dangerouslySetInnerHTML: {
                                            __html: `
                                            <div class="py-2 px-3 flex flex-row items-center">
                                                <div class="relative flex flex-shrink-0 mr-3 flex-0 w-12 h-12 overflow-hidden rounded-lg bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                                                        <img src="${item.image.full_path}" class="w-full object-cover h-full rounded-lg"/>
                                                    </div>
                                                <div class="flex flex-col w-full">
                                                    <div class="flex flex-row items-center">
                                                     <span class="text-lg font-semibold">${item.name}</span>
                                                    </div>
                                                   <span class="text-secondary text-md">${item.jobs_count} jobs</span>
                                                </div>
                                            </div>
                                        `
                                        }
                                    });
                                } else {
                                    return createElement('div', {
                                        dangerouslySetInnerHTML: {
                                            __html: `
                                            <div class="py-2 px-3 flex flex-row items-center">
                                                    <div class="relative flex-shrink-0 flex mr-3 flex-0 w-12 h-12 overflow-hidden rounded-lg bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                                                            <div class="group-hover:bg-gray-400 dark:group-hover:bg-gray-900 overflow-hidden text-lg
                                                                                text-center flex items-center justify-center w-full
                                                                                h-full rounded-lg font-semibold uppercase bg-gray-200 text-gray-600 dark:bg-gray-700
                                                                                dark:text-gray-200">
                                                                    ${item.name.charAt(
                                                                        0
                                                                    )}
                                                            </div>
                                                    </div>
                                                    <div class="flex flex-col w-full">
                                                        <div class="flex flex-row items-center">
                                                        <span class="text-lg font-semibold">${
                                                            item.name
                                                        }</span>
                                                        </div>
                                                        <span class="text-secondary text-md">${
                                                            item.jobs_count
                                                        } jobs</span>
                                                    </div>
                                            </div>

                        `
                                        }
                                    });
                                }
                            }
                        }
                    },
                    {
                        sourceId: 'lists',
                        onSelect: ({ item }: { item: any }) => {
                            this._router.navigate(['/lists/' + item.slug]);
                        },
                        getItems({ query }) {
                            return query
                                ? getAlgoliaResults({
                                      searchClient,
                                      queries: [
                                          {
                                              indexName: 'store_lists',
                                              query,
                                              params: {
                                                  hitsPerPage: 3
                                              }
                                          }
                                      ]
                                  })
                                : [];
                        },
                        displayKey: 'name',
                        name: 'store_lists',
                        templates: {
                            header({
                                createElement
                            }: {
                                createElement: Function;
                            }) {
                                return createElement('div', {
                                    dangerouslySetInnerHTML: {
                                        __html: `
                                        <h2 class="bg-gray-100 dark:bg-gray-900 dark:text-white py-2.5 px-4 text-md font-semibold text-gray-900">Lists</h2>
                                    `
                                    }
                                });
                            },
                            item({
                                item,
                                createElement
                            }: {
                                item: any;
                                createElement: Function;
                            }) {
                                if (item.image) {
                                    return createElement('div', {
                                        dangerouslySetInnerHTML: {
                                            __html: `
                                            <div class="py-2 px-3 flex flex-row items-center">
                                                <div class="relative flex flex-shrink-0 mr-3 flex-0 w-12 h-12 overflow-hidden rounded-full bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                                                        <img src="${item.image.full_path}" class="w-full object-cover h-full rounded-full"/>
                                                    </div>
                                                <div class="flex flex-col w-full">
                                                    <div class="flex flex-row items-center">
                                                     <span class="text-lg font-semibold">${item.name}</span>
                                                    </div>
                                                   <span class="text-secondary text-md">${item.stores_count} organizations</span>
                                                </div>
                                            </div>
                                        `
                                        }
                                    });
                                } else {
                                    return createElement('div', {
                                        dangerouslySetInnerHTML: {
                                            __html: `
                                            <div class="py-2 px-3 flex flex-row items-center">
                                                    <div class="relative flex-shrink-0 flex mr-3 flex-0 w-12 h-12 overflow-hidden rounded-full bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                                                            <div class="group-hover:bg-gray-400 dark:group-hover:bg-gray-900 overflow-hidden text-lg
                                                                                text-center flex items-center justify-center w-full
                                                                                h-full rounded-full font-semibold uppercase bg-gray-200 text-gray-600 dark:bg-gray-700
                                                                                dark:text-gray-200">
                                                                    ${item.name.charAt(
                                                                        0
                                                                    )}
                                                            </div>
                                                    </div>
                                                    <div class="flex flex-col w-full">
                                                        <div class="flex flex-row items-center">
                                                        <span class="text-lg font-semibold">${
                                                            item.name
                                                        }</span>
                                                        </div>
                                                        <span class="text-secondary text-md">${
                                                            item.stores_count
                                                        } organizations</span>
                                                    </div>
                                            </div>

                        `
                                        }
                                    });
                                }
                            }
                        }
                    },

                    {
                        sourceId: 'stores',
                        onSelect: function ({ item }: { item: any }) {

                            this._router.navigate(['/'.concat(item.slug)]);
                        }.bind(this),
                        getItems({ query }) {
                            return query
                                ? getAlgoliaResults({
                                      searchClient,
                                      queries: [
                                          {
                                              indexName: 'stores_public',
                                              query,
                                              params: {
                                                  hitsPerPage: 3,
                                                  facetFilters: 'visible:true'
                                              }
                                          }
                                      ]
                                  })
                                : [];
                        },
                        displayKey: 'name',
                        name: 'stores',
                        templates: {
                            header({
                                createElement
                            }: {
                                createElement: Function;
                            }) {
                                return createElement('div', {
                                    dangerouslySetInnerHTML: {
                                        __html: `
                                        <h2 class="bg-gray-100 dark:bg-gray-900 dark:text-white py-2.5 px-4 text-md font-semibold text-gray-900">Organizations</h2>
                                    `
                                    }
                                });
                            },
                            item({
                                item,
                                createElement
                            }: {
                                item: any;
                                createElement: Function;
                            }) {
                                if (item.image) {
                                    return createElement('div', {
                                        dangerouslySetInnerHTML: {
                                            __html: `
                                            <div class="py-2 px-3 flex flex-row items-center">
                                                <div class="relative flex flex-shrink-0 mr-3 flex-0 w-12 h-12 overflow-hidden rounded-full bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                                                        <img src="${item.image.full_path}" class="w-full object-scale-down h-full rounded-full"/>
                                                    </div>
                                                <div class="flex flex-col w-full">
                                                    <div class="flex flex-row items-center">
                                                     <span class="text-lg font-semibold">${item.name}</span>
                                                     <span class="ml-1 text-blue-500">
                                                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
  <path fill-rule="evenodd" d="M16.403 12.652a3 3 0 000-5.304 3 3 0 00-3.75-3.751 3 3 0 00-5.305 0 3 3 0 00-3.751 3.75 3 3 0 000 5.305 3 3 0 003.75 3.751 3 3 0 005.305 0 3 3 0 003.751-3.75zm-2.546-4.46a.75.75 0 00-1.214-.883l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
</svg>
</span>

                                                    </div>
                                                   <span class="text-secondary text-md">${item.jobs_count} jobs</span>
                                                </div>
                                            </div>
                                        `
                                        }
                                    });
                                } else {
                                    return createElement('div', {
                                        dangerouslySetInnerHTML: {
                                            __html: `
                                            <div class="py-2 px-3 flex flex-row items-center">
                                                    <div class="relative flex-shrink-0 flex mr-3 flex-0 w-12 h-12 overflow-hidden rounded-full bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                                                            <div class="group-hover:bg-gray-400 dark:group-hover:bg-gray-900 overflow-hidden text-lg
                                                                                text-center flex items-center justify-center w-full
                                                                                h-full rounded-full font-semibold uppercase bg-gray-200 text-gray-600 dark:bg-gray-700
                                                                                dark:text-gray-200">
                                                                    ${item.name.charAt(
                                                                        0
                                                                    )}
                                                            </div>
                                                    </div>
                                                    <div class="flex flex-col w-full">
                                                        <div class="flex flex-row items-center">
                                                        <span class="text-lg font-semibold">${
                                                            item.name
                                                        }</span>
                                                         <span class="ml-1 text-blue-500">
                                                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
  <path fill-rule="evenodd" d="M16.403 12.652a3 3 0 000-5.304 3 3 0 00-3.75-3.751 3 3 0 00-5.305 0 3 3 0 00-3.751 3.75 3 3 0 000 5.305 3 3 0 003.75 3.751 3 3 0 005.305 0 3 3 0 003.751-3.75zm-2.546-4.46a.75.75 0 00-1.214-.883l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
</svg>
</span>

                                                        </div>
                                                        <span class="text-secondary text-md">${
                                                            item.jobs_count
                                                        } jobs</span>
                                                    </div>
                                            </div>

                        `
                                        }
                                    });
                                }
                            }
                        }
                    },
                    {
                        sourceId: 'jobs',
                        onSelect: ({ item }: { item: any }) => {
                            this._router.navigate(['/search/jobs'], {
                                queryParams: { query: item.title }
                            });
                        },
                        getItems({ query }) {
                            return query
                                ? getAlgoliaResults({
                                      searchClient,
                                      queries: [
                                          {
                                              indexName: 'jobs',
                                              query,
                                              params: {
                                                  hitsPerPage: 3,
                                                  facetFilters:
                                                      'store.visible:true,status:active'
                                              }
                                          }
                                      ]
                                  })
                                : [];
                        },
                        displayKey: 'title',
                        name: 'jobs',
                        templates: {
                            header({
                                createElement
                            }: {
                                createElement: Function;
                            }) {
                                return createElement('div', {
                                    dangerouslySetInnerHTML: {
                                        __html: `
                                        <h2 class="bg-gray-100 dark:bg-gray-900 dark:text-white py-2.5 px-4 text-md font-semibold text-gray-900">Popular Jobs</h2>
                                    `
                                    }
                                });
                            },
                            item({
                                item,
                                createElement
                            }: {
                                item: any;
                                createElement: Function;
                            }) {
                                return createElement('div', {
                                    dangerouslySetInnerHTML: {
                                        __html: `
                                         <div class="py-2 px-3 flex flex-row items-start">
                                            <i class="fa fa-search icon-size-4 mr-3"></i>
                                            <div class="flex flex-col w-full">
                                                <div class="flex flex-row items-center">
                                                    <span class="text-lg font-semibold">${item.title}</span>
                                                </div>
                                                <div class="pt-0.5 text-sm flex flex-row items-center">
                                                    <span>by <b>${item.store.name}</b></span>
                                                </div>
                                            </div>
                                        </div>
                                    `
                                    }
                                });
                            }
                        }
                    }
                ];
            }
        });
    }
}
