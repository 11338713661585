<div *ngIf="isAuthenticated$ | async"
    [ngClass]="{'relative': (mobileNavigationRelative$ | async), 'fixed': !(mobileNavigationRelative$ | async)}"
    class="bottom-0 z-20 grid w-full h-16 grid-cols-4 overflow-visible border-t-2 shadow-xl md:hidden bg-card">
    <a [routerLink]="['/home']" routerLinkActive #rlaHome="routerLinkActive"
        class="flex flex-col items-center justify-center " [ngClass]="{'text-purple-800 dark:text-white': rlaHome.isActive,
        'text-gray-600 dark:text-gray-400': !rlaHome.isActive}">
        <button mat-icon-button>
            <svg *ngIf="!rlaHome.isActive" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                    d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
            </svg>
            <svg *ngIf="rlaHome.isActive" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                class="w-6 h-6">
                <path
                    d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
                <path
                    d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
            </svg>

        </button>
        <span class="-mt-1 text-sm">Home</span>
    </a>

    <a [routerLink]="['/jobs']" routerLinkActive #rlaJobs="routerLinkActive"
        class="flex flex-col items-center justify-center " [ngClass]="{'text-purple-800 dark:text-white':
        rlaJobs.isActive,
        'text-gray-600 dark:text-gray-400': !rlaJobs.isActive}">
        <button mat-icon-button>
            <svg *ngIf="!rlaJobs.isActive" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                    d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
            </svg>
            <svg *ngIf="rlaJobs.isActive" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                class="w-6 h-6">
                <path fill-rule="evenodd"
                    d="M7.5 5.25a3 3 0 013-3h3a3 3 0 013 3v.205c.933.085 1.857.197 2.774.334 1.454.218 2.476 1.483 2.476 2.917v3.033c0 1.211-.734 2.352-1.936 2.752A24.726 24.726 0 0112 15.75c-2.73 0-5.357-.442-7.814-1.259-1.202-.4-1.936-1.541-1.936-2.752V8.706c0-1.434 1.022-2.7 2.476-2.917A48.814 48.814 0 017.5 5.455V5.25zm7.5 0v.09a49.488 49.488 0 00-6 0v-.09a1.5 1.5 0 011.5-1.5h3a1.5 1.5 0 011.5 1.5zm-3 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                    clip-rule="evenodd" />
                <path
                    d="M3 18.4v-2.796a4.3 4.3 0 00.713.31A26.226 26.226 0 0012 17.25c2.892 0 5.68-.468 8.287-1.335.252-.084.49-.189.713-.311V18.4c0 1.452-1.047 2.728-2.523 2.923-2.12.282-4.282.427-6.477.427a49.19 49.19 0 01-6.477-.427C4.047 21.128 3 19.852 3 18.4z" />
            </svg>
        </button>
        <span class="-mt-1 text-sm">Jobs</span>
    </a>

    <!-- <div class="flex items-center justify-center flex-shrink-0">
        <button type="button" (click)="openSheet()" matRipple
            class="transition-all w-16 h-16 -mt-6 bg-card overflow-hidden rounded-full shadow-xl border-white dark:border-inherit  border-[3px]">
            <div [style.backgroundImage]="'url(/assets/icons/mobile-nav-icon.jpg)'"
                class="w-full h-full transition-all bg-cover">
            </div>
        </button>
    </div> -->

    <!-- <a [routerLink]="['/messages']" routerLinkActive #rlaMessages="routerLinkActive"
        class="flex flex-col items-center justify-center" [ngClass]="{'text-purple-800 dark:text-white':
        rlaMessages.isActive,
        'text-gray-600 dark:text-gray-400': !rlaMessages.isActive}">
        <button mat-icon-button class="h-[20px]">
            <svg *ngIf="!rlaMessages.isActive" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                    d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
            </svg>
            <svg *ngIf="rlaMessages.isActive" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                class="w-6 h-6">
                <path fill-rule="evenodd"
                    d="M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 01-3.476.383.39.39 0 00-.297.17l-2.755 4.133a.75.75 0 01-1.248 0l-2.755-4.133a.39.39 0 00-.297-.17 48.9 48.9 0 01-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97zM6.75 8.25a.75.75 0 01.75-.75h9a.75.75 0 010 1.5h-9a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H7.5z"
                    clip-rule="evenodd" />
            </svg>
        </button>
        <span class="-mt-1 text-sm">Inbox</span>
    </a> -->
    <div class="flex flex-col items-center justify-center text-gray-600 dark:text-gray-400">
        <notifications></notifications>
        <span class="-mt-1 text-sm">Notifications</span>
    </div>

    <div (click)="openSheet()" class="flex flex-col items-center text-gray-600 dark:text-gray-400 justify-center">
        <button mat-icon-button class="h-[20px]">
            <mat-icon class="icon-size-6" svgIcon="mimicons_outline:grid-prominent"></mat-icon>
        </button>
        <span class="-mt-1 text-sm">More</span>
    </div>

</div>
<div *ngIf="(isAuthenticated$ | async) === false"
    [ngClass]="{'relative': (mobileNavigationRelative$ | async), 'fixed': !(mobileNavigationRelative$ | async)}"
    class="bottom-0 z-20 flex items-center justify-center w-full h-16 space-x-4 bg-gray-800 border-t-2 shadow-xl md:hidden">
    <button [routerLink]="['/login']" class="w-24 shadow-lg shadow-purple-600/50" mat-flat-button
        color="primary">Login</button>

    <button [routerLink]="['/signup']" class="w-24 shadow-lg shadow-purple-600/50" mat-flat-button color="primary">Sign
        Up</button>
</div>
