import { Injectable } from '@angular/core';

import * as dayjs from 'dayjs';

import { Adapter } from './adapter';

export interface IHonor {
    id?: number;
    name?: string;
    description?: string;
    organization?: string;
    date_awarded?: Date;
    updated_at?: Date;
    deleted_at?: Date;
    delete?: boolean;
    logo?: string;
}
export class Honor implements IHonor {
    id?: number;
    name?: string;
    description?: string;
    organization?: string;
    date_awarded?: Date;
    created_at: Date;
    updated_at?: Date;
    deleted_at?: Date;
    delete?: boolean;
    logo?: string;

    constructor(
        id?: number,
        name?: string,
        description?: string,
        organization?: string,
        date_awarded?: Date,
        created_at?: Date,
        updated_at?: Date,
        deleted_at?: Date
    ) {
        this.id = id;
        this.name = name;
        this.organization = organization;
        this.date_awarded = date_awarded;
        this.description = description;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.deleted_at = deleted_at;
    }
}

@Injectable({ providedIn: 'root' })
export class HonorAdapter implements Adapter<Honor> {
    adapt(item: any): Honor {
        return new Honor(
            item.id,
            item.name,
            item.description ?? undefined,
            item.organization,
            item.date_awarded ? dayjs(item.date_awarded).toDate() : null,
            item.created_at ? dayjs(item.expiration_date).toDate() : null,
            item.updated_at ? dayjs(item.updated_at).toDate() : null,
            item.deleted_at ? dayjs(item.deleted_at).toDate() : null
        );
    }
}
